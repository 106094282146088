import { Button, DialogContentText, Slide } from '@mui/material'
import React, { FC, useState } from 'react'

import { Dialog } from '@mui/material'
import { DialogActions } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { baseStyles } from 'utils/constants/baseStyles'

interface ConfirmDialogProps {
	title: string
	message: string
	onConfirm: Function
	onCancel: Function
	visible: boolean
}

export const ConfirmDialog: FC<ConfirmDialogProps> = props => {
	const { onCancel, title, message, onConfirm, visible } = props

	return (
		<div>
			<Dialog
				open={visible}
				keepMounted
				onClose={() => onCancel()}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{ color: baseStyles.color.error.main }}
						onClick={() => onCancel()}
					>
						Non
					</Button>
                    <Button sx={{ color: baseStyles.color.success.main }} onClick={() => onConfirm()}>
						Oui
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
