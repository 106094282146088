import { SxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { baseStyles } from "utils/constants/baseStyles";

export interface TypoProps extends PropsWithChildren {
  color?: "primary-blue" | "primary-blue-60" | string;
  weight?: "light" | "regular" | "medium" | "semibold" | "bold";
  lineHeight?: "140%" | "18px" | string;
  letterSpacing?: "0.5px" | string;
  sx?: SxProps;
}

export const getColor = (color?: TypoProps["color"]) => {
  switch (color) {
    case "primary-blue":
      return baseStyles.color.blue.primary;
    case "primary-blue-60":
      return baseStyles.color.blue.primary60;
    default:
      return color;
  }
};

export const getWeight = (weight?: TypoProps["weight"]) => {
  switch (weight) {
    case "light":
      return "300";
    case "regular":
      return "400";
    case "medium":
      return "500";
    case "semibold":
      return "600";
    case "bold":
      return "700";
    default:
      return "400";
  }
};
