import { BankForm, BankFormState } from "components/BankForm";
import { useContext, useState } from "react";

import { BankContext } from "providers/BankProvider";
import { Box } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import { addBank } from "services/Bank/BankService";
import { useTranslation } from "react-i18next";

type IProps = {
  open: boolean;
  handleClose: any;
};

export const NewBankModal = (props: IProps) => {
  const { t } = useTranslation();
  
  const { open, handleClose } = props;
  const [showBgBlur] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { reloadBank } = useContext(BankContext);

  const handleAddBank = (data: BankFormState) => {
    setLoading(true);
    addBank(data)
      .then((response) => {
        reloadBank();
        handleClose();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomModal
      title={t('addBank')}
      open={open}
      handleClose={handleClose}
      closeButton={true}
      footer={<Box display={"flex"} gap="30px" marginTop={"20px"}></Box>}
      bgBlur={
        showBgBlur && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              boxSizing: "border-box",
              border: "none",
              zIndex: 1000
            }}
          ></Box>
        )
      }
      width={"600px"}
      sx={{ padding: "40px 30px" }}
      headerSx={{ paddingBottom: "30px", marginBottom: "30px" }}
    >
      <BankForm onSubmit={handleAddBank} loading={loading} />
    </CustomModal>
  );
};
