import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { FC, useEffect, useState } from "react";
import {
  cashflowCardStyles,
  cashflowTabContentStyles,
  contentContainerSx,
  labelStyles,
  valueStyles
} from "./styles";
import { formatMoneyFork, getAnalysisResultFormattedData } from "utils";

import { AnalysisResultsTabContentProps } from "./types";
import { Box } from "@mui/material";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import { CalendarSearchIcon } from "assets/icons/CalendarSearchIcon";
import { CardSendIcon } from "assets/icons/CardSendIcon";
import { CardTransferIcon } from "assets/icons/CardTransferIcon";
import { RoundDoubleAltArrowDownIcon } from "assets/icons/RoundDoubleAltArrowDownIcon";
import { SquareDoubleAltArrowDown } from "assets/icons/SquareDoubleAltArrowDown";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface TrendsProps extends AnalysisResultsTabContentProps {}

export const Trends: FC<TrendsProps> = ({ data }) => {
  const { t } = useTranslation();
  
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const formattedData: AnalysisResultCardData[] = [
    {
      label: t('highestMAWOCredit'),
      value: getAnalysisResultFormattedData("selfNumber", data?.highestMAWOCredit),
      description: t('highestMAWOCreditDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('highestMAWODebit'),
      value: getAnalysisResultFormattedData("selfNumber", data?.highestMAWODebit),
      description: t('highestMAWODebitDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('lastDateOfCredit'),
      value: getAnalysisResultFormattedData("date", data?.lastDateOfCredit),
      description: t('lastDateOfCreditDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('lastDateOfDebit'),
      value: getAnalysisResultFormattedData("date", data?.lastDateOfDebit),
      description: t('lastDateOfDebitDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('MAWWZeroBalanceInAccount'),
      value: getAnalysisResultFormattedData("selfNumber", data?.MAWWZeroBalanceInAccount ? data.MAWWZeroBalanceInAccount : 0),
      description: t('MAWWZeroBalanceInAccountDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('mostFrequentBalanceRange'),
      value: formatMoneyFork(
        data?.mostFrequentBalanceRange?.min,
        data?.mostFrequentBalanceRange?.max,
        currentCurrency
      ),
      description: t('mostFrequentBalanceRangeDescription'),
      Icon: () => <RoundDoubleAltArrowDownIcon />
    },
    {
      label: t('mostFrequentTransactionRange'),
      value: formatMoneyFork(
        data?.mostFrequentTransactionRange?.min,
        data?.mostFrequentTransactionRange?.max,
        currentCurrency
      ),
      description: t('mostFrequentTransactionRangeDescription'),
      Icon: () => <SquareDoubleAltArrowDown />
    },
    {
      label: t('NODWBalanceLess5000'),
      value: getAnalysisResultFormattedData("day", data?.NODWBalanceLess5000),
      description: t('NODWBalanceLess5000Description'),
      Icon: () => <CalendarSearchIcon />
    },
    {
      label: t('recurringExpense'),
      value: getAnalysisResultFormattedData("array", data?.recurringExpense),
      description: t('recurringExpenseDescription'),
      Icon: () => <CardSendIcon />
    },
    {
      label: t('transactionsBetween10000And100000'),
      value: getAnalysisResultFormattedData("selfNumber", data?.transactionsBetween10000And100000),
      description: t('transactionsBetween10000And100000Description'),
      Icon: () => <CardTransferIcon />
    },
    {
      label: t('transactionsBetween100000And500000'),
      value: getAnalysisResultFormattedData("selfNumber", data?.transactionsBetween100000And500000),
      description: t('transactionsBetween100000And500000Description'),
      Icon: () => <CardTransferIcon />
    },
    {
      label: t('transactionsGreater500000'),
      value: getAnalysisResultFormattedData("selfNumber", data?.transactionsGreater500000, true),
      description: t('transactionsGreater500000Description'),
      Icon: () => <CardTransferIcon />
    }
  ];

  return (
    <Box sx={{ ...cashflowTabContentStyles }}>
      {formattedData.map(({ label, value, description, Icon }, index) => (
        <AnalysisResultCard
          sx={cashflowCardStyles}
          contentContainerSx={contentContainerSx}
          labelSx={labelStyles}
          valueSx={valueStyles}
          key={`${index}-${label}`}
          label={label}
          value={value}
          description={description}
          Icon={Icon}
        />
      ))}
    </Box>
  );
};
