import { ArticleRounded, CancelRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import {
  addSupportTicketMessage,
  getAllMessagesBySupportTicketId
} from "services/SupportTickets/SupportTicketsService";
import {
  attachementIconContainerStyles,
  btnRemoveFileStyles,
  fileIconContainerStyle,
  filePreviewContainer,
  imgPreviewContainerStyles,
  imgPreviewStyles,
  inputMessageStyles,
  mainContainerStyles,
  otherFilePreviewStyles
} from "./styles";

import { AttachementIcon } from "components/Icons/SvgIcons";
import { HiddenInputFile } from "components/HiddenInputFile";
import { SupportTicket } from "providers/SupportMessageProvider";
import { adaptAnalysisFile } from "types/analysis";
import { baseStyles } from "utils/constants/baseStyles";
import { styles } from "pages/Login/styles";
import { useTranslation } from "react-i18next";

type IProps = {
  supportTicketId: number | string;
};

const MessageInput: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  
  const { supportTicketId } = props;

  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [files, setFiles] = useState<AnalysisFile[]>([]);
  const [sendLoading, setSendLoading] = useState(false);

  const { supportTicketMessages } = useContext(SupportTicket);

  const handleSend = useCallback(() => {
    if (currentMessage || files.length > 0) {
      setSendLoading(true);
      const base64Files = files.map(({ base64data }) => base64data);
      addSupportTicketMessage(supportTicketId, currentMessage, base64Files).then((data: any) => {
        getAllMessagesBySupportTicketId(supportTicketId)
          .then(
            (data) => {
              supportTicketMessages.set(data);
              setCurrentMessage("");
              setFiles([]);
              updateListMessageScroll();
            },
            (error: any) => {
              console.log("Error on fetching messages: ", error);
            }
          )
          .finally(() => {
            setSendLoading(false);
          });
      });
    }
  }, [currentMessage, supportTicketId, files, supportTicketMessages]);

  const updateListMessageScroll = () => {
    setTimeout(() => {
      const messageListContainer = document.getElementById('message-list-container');
      
      if (messageListContainer) {
        messageListContainer.scrollTop = messageListContainer.scrollHeight;
      }
    }, 0);
  };

  const inputChangeHandler = (event: any) => {
    setCurrentMessage(event.target.value);
  };

  const handleChangeInputFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const arrayOfFile = Object.values(e.target.files);
      const adaptedFiles = await Promise.all(
        arrayOfFile.map(async (file) => {
          return adaptAnalysisFile(file);
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...adaptedFiles]);
    }
  };

  const handleRemoveFile = (file: AnalysisFile) => {
    setFiles((prevFiles) => prevFiles.filter(({ base64data }) => base64data !== file.base64data));
  };

  const renderRemoveFileBtn = (file: AnalysisFile) => {
    return (
      <IconButton sx={btnRemoveFileStyles} onClick={() => handleRemoveFile(file)}>
        <CancelRounded />
      </IconButton>
    );
  };

  return (
    <Box sx={mainContainerStyles}>
      <Box style={{ flexGrow: "1" }}>
        {files.length > 0 && (
          <Box sx={imgPreviewContainerStyles}>
            {files.map((file) => (
              <Box sx={filePreviewContainer}>
                {file.type.startsWith("image/") ? (
                  <>
                    <img key={file.name} src={file.base64data} alt="" style={imgPreviewStyles} />
                    {renderRemoveFileBtn(file)}
                  </>
                ) : (
                  <Box sx={otherFilePreviewStyles}>
                    <Box sx={fileIconContainerStyle}>
                      <ArticleRounded color="inherit" fontSize="small" />
                    </Box>
                    <span>{file.name}</span>
                    {renderRemoveFileBtn(file)}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
        <TextField
          sx={inputMessageStyles}
          value={currentMessage}
          onChange={inputChangeHandler}
          fullWidth={true}
          multiline={true}
          placeholder={t('writeMessage')}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <IconButton sx={attachementIconContainerStyles}>
          <HiddenInputFile multiple={true} onChange={handleChangeInputFile} />
          <AttachementIcon color={baseStyles.color.green.primary} />
        </IconButton>
        <Box sx={{ width: "1px", backgroundColor: "lightgray", height: "26px" }} />
        <Button
          variant="contained"
          disableElevation
          sx={{ ...styles.loginButton, margin: "0" }}
          onClick={!sendLoading ? handleSend : undefined}
        >
          {t('send')}
          {sendLoading && <CircularProgress color="inherit" size={20} sx={{ marginLeft: "5px" }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default MessageInput;
