import { Avatar, Box, Skeleton, Stack } from "@mui/material";

import { MessageIcon } from "components/Icons/SvgIcons";
import { Typo12 } from "components/Typography/Typo12";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { formatDateTime } from "utils";
import { baseStyles } from "utils/constants/baseStyles";

type MessageItemProps = {
  user: Issuer;
  date: string;
  subject: string;
  active: boolean;
  loading: boolean;
  messagesCount: number;
  onClick?: Function;
};

export const ListMessageItem = (props: MessageItemProps) => {
  const {
    active = false,
    subject,
    user,
    loading = true,
    onClick = () => {},
    date = "",
    messagesCount
  } = props;
  let styles = {
    color: baseStyles.color.blue.primary,
    bgColor: "#FFF",
    bgColorHover: "#EBEBEB"
  };

  if (active) {
    styles.color = "#FFF";
    styles.bgColor = baseStyles.color.green.primary;
    styles.bgColorHover = baseStyles.color.green.dark;
  }

  return (
    <Box
      key={user.id}
      onClick={(e) => {
        onClick();
      }}
      sx={{
        borderBottom: "2px solid rgba(1, 29, 123, 0.10)",
        minHeight: "140px",
        backgroundColor: styles.bgColor,
        padding: "20px",
        cursor: "pointer",
        ":hover": {
          backgroundColor: styles.bgColorHover
        }
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
        <Box>
          {/* Image avatar */}
          {loading ? (
            <Skeleton variant="circular">
              <Avatar
                sx={{ width: "48px", height: "48px" }}
                alt={""}
                src="/static/images/avatar/1.jpg"
              />
            </Skeleton>
          ) : (
            <UserAvatar user={user} size={48} fontSize={22} />
          )}
        </Box>
        <Stack spacing={2} flexGrow={"1"}>
          <Box>
            {loading ? (
              <Stack spacing={1}>
                <Skeleton width={"100%"} height={"40px"} />
              </Stack>
            ) : (
              <Stack spacing={1}>
                <Box>
                  <Typo14Medium color={styles.color}>{user.fullname}</Typo14Medium>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "fit-content",
                    padding: "10px 15px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(1, 29, 123, 0.10)"
                  }}
                >
                  <Typo14Medium color={styles.color}>{user.bank?.name || "no bank"}</Typo14Medium>
                </Box>
              </Stack>
            )}
          </Box>
          {loading ? (
            <>
              <Skeleton></Skeleton>
              <Skeleton width={"60%"}></Skeleton>
            </>
          ) : (
            <>
              <Box>
                <Typo14Medium color={styles.color}>{subject}</Typo14Medium>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typo12 color={styles.color}>{formatDateTime(date)}</Typo12>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                  <Typo12 color={styles.color}>{messagesCount}</Typo12>
                  <Box sx={{ color: styles.color, width: "18px", height: "18px" }}>
                    <MessageIcon height="18" width="18" />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
