import { adaptUserInfo } from "types/user";

import axios from "services/AuthorizedAxiosInstance";
import unauthorizedAxios from "services/AxiosInstance";

const returnedError = {
  status: 400,
  message: "Erreur bad request",
  reason: undefined
};

export const getCurrentUser = () => {
  return axios.get("api/users/me");
};

type ForgotPasswordPayload = {
  email: string;
};

export const forgotPassword = (data: ForgotPasswordPayload) => {
  return unauthorizedAxios.post("api/users/forgotPassword", data);
};

type ResetPasswordPayload = {
  newPassword: string;
  code: string;
};

export const resetPassword = (data: ResetPasswordPayload) => {
  return unauthorizedAxios.post("api/users/resetPassword", data);
};

export type UpdateCurrentUserPayload = {
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  actualPassword?: string;
  newPassword?: string;
};

export const updateCurrentUser = (userId: string | number, data: UpdateCurrentUserPayload) => {
  return new Promise<UserInfo>((resolve, reject) => {
    axios
      .put("api/users/" + userId, data)
      .then(
        (result) => {
          resolve(adaptUserInfo(result.data));
        },
        (error) => {
          console.log(error);
          throw error;
        }
      )
      .catch((reason: any) => {
        returnedError.reason = reason;
        reject(returnedError);
      });
  });
};

export const isAdmin = (roles: String[]) => {
  return roles.includes("ADMIN");
};

export const isChargeClientele = (roles: String[]) => {
  return roles.includes("CHARGE_CLIENTELE");
};
