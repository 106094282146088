import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { analysisApi } from "store/api";
import { Analysis } from "types/analysis/analysis";
import { RootState } from "../index";

export interface AnalysisState {
  analyzes: Analysis[];
  currentAnalysis: Analysis | null;
}

const initialState: AnalysisState = {
  analyzes: [],
  currentAnalysis: null
};

export const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    setAnalyzes: (state, action: PayloadAction<Analysis[]>) => {
      state.analyzes = action.payload;
    },
    setCurrentAnalysis: (state, action: PayloadAction<Analysis | null>) => {
      state.currentAnalysis = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      analysisApi.endpoints.getManyAnalyzes.matchFulfilled,
      (state, { payload }) => {
        state.analyzes = payload;
      }
    );
  }
});

export const { setAnalyzes, setCurrentAnalysis } = analysisSlice.actions;

export const selectAnalysisState = (state: RootState) => state.analysis as AnalysisState;

export const analysisReducer = analysisSlice.reducer;
