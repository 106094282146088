import { ListMessageItem } from "./ListMessageItem";

type ListMessageProps = {
  messageList: Array<SupportTicket> | null;
  currentSupportTicketId: string | number | undefined;
  loading: boolean;
  handleClick: Function;
};

export const ListMessage = (props: ListMessageProps) => {
  const { messageList, currentSupportTicketId, loading = true, handleClick } = props;
  console.log("message list", messageList);
  console.log(currentSupportTicketId);

  const suspense = () => {
    const supportList = Array.from({ length: 4 }, (_, k) => ({
      user: {
        id: k
      },
      date: "28 juillet 2023, 13: 51",
      subject: "Problème sur l’analyse d’un compte mobile money"
    }));

    return supportList.map((value, i) => {
      return (
        <ListMessageItem
          key={i}
          user={{} as Issuer}
          date={"21-10-2023"}
          subject={""}
          active={false}
          loading={true}
          messagesCount={3}
        />
      );
    });
  };

  return (
    <>
      {loading ? (
        <>{suspense()}</>
      ) : messageList ? (
        messageList.map((support, i: number) => (
          <ListMessageItem
            key={support.id}
            user={support.issuer}
            date={support.date}
            subject={support.subject}
            active={support.id === currentSupportTicketId}
            loading={false}
            messagesCount={support.messagesCount || 0}
            onClick={() => {
              handleClick(support.id);
            }}
          />
        ))
      ) : (
        <>No item</>
      )}
    </>
  );
};
