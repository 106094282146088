import { SxProps } from "@mui/material";
import { HEADER_HEIGHT } from "constants/dom";
import { flexColumn } from "utils/constants/baseStyles";

export const bankListContainerStyles: SxProps = {
  ...flexColumn,
  position: "relative",
  gap: "30px",
  width: "100%",
  height: `calc(100vh - ${HEADER_HEIGHT})`,
  overflowY: "auto",
  overflowX: "hidden",
  padding: "40px 0px"
};
