import { CloseRounded, InfoOutlined } from "@mui/icons-material";
import { Box, Collapse, SxProps } from "@mui/material";
import { CheckIcon, OutlinedForbiddenIcon } from "components/Icons/SvgIcons";
import { ReactNode } from "react";
import { styles } from "./styles";

export type CustomAlertProps = {
  title?: ReactNode | string;
  body: ReactNode | string;
  icon?: ReactNode;
  severity?: "error" | "warning" | "info" | "success";
  variant?: "filled" | "outlined" | "normal";
  closable?: boolean;
  opened?: boolean;
  endOrnement?: any;
  sx?: SxProps;
  collapseSx?: SxProps;
  titleSx?: SxProps;
  bodySx?: SxProps;
  onclose?: (e: any) => any;
};

export const CustomAlert = (props: CustomAlertProps) => {
  const {
    title,
    body,
    icon,
    severity = "success",
    variant = "normal",
    onclose = () => {},
    closable = false,
    opened = true,
    sx = {},
    collapseSx = {},
    titleSx = {},
    bodySx = {},
    endOrnement
  } = props;

  const alertStyle = {
    container: {
      ...styles.alertContainer,
      ...styles[`alert-variant-${variant}-${severity}`]
    },
    content: {
      ...styles.alertContent
    }
  };

  const getIcon = (severity: any) => {
    if (icon) {
      return icon;
    }
    switch (severity) {
      case "error":
        return <OutlinedForbiddenIcon color="white" />;
      case "success":
        return <CheckIcon color="white" />;
      default:
        return <InfoOutlined></InfoOutlined>;
    }
  };

  return (
    <Collapse in={opened} sx={collapseSx}>
      <Box sx={{ ...sx, ...alertStyle.container }}>
        {getIcon(severity)}
        <Box sx={alertStyle.content}>
          {title && <Box sx={{ ...styles.alertContentTitle, ...(titleSx as any) }}>{title}</Box>}
          {body && <Box sx={{ ...styles.alertContentBody, ...(bodySx as any) }}>{body}</Box>}
        </Box>
        {endOrnement || ""}
        {closable && (
          <CloseRounded
            sx={{ cursor: "pointer" }}
            onClick={(e: any) => {
              onclose(e);
            }}
          />
        )}
      </Box>
    </Collapse>
  );
};
