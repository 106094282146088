import { Box, IconButton, Popover, Stack, SvgIcon } from "@mui/material";
import { FineTune, LogoutIcon2 } from "components/Icons/SvgIcons";
import { isAdmin, isChargeClientele } from "services/User/UserService";
import { useContext, useState } from "react";

import { AltArrowDownIcon } from "assets/icons/AltArrowDownIcon";
import { AuthContext } from "providers/AuthProvider";
import { Typo14 } from "components/Typography/Typo14";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { UserContext } from "providers/UserProvider";
import { baseStyles } from "utils/constants/baseStyles";
import { getRole } from "types/user";
import i18next from 'i18next';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const UserPanel = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const performLogout = () => {
    console.log("Log out of the application");
    logout();
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigateToMyAccount = () => {
    const groups = currentUser?.groups;

    if (!groups) {
      return;
    }

    if (isChargeClientele(groups)) {
      navigate("/my-account");
    } else if (isAdmin(groups)) {
      navigate("/admin/my-account");
    }
  };

  i18next.on('languageChanged', (lng) => {
    console.log('language changed to ', lng);
    if (currentUser?.groups) {
      setCurrentUser({
        ...currentUser,
        role: getRole(currentUser?.groups)
      });
    }
  });
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
          height: "100%",
          alignItems: "center"
        }}
      >
        <UserAvatar user={currentUser} size={45} fontSize={20} />
        <Stack gap="5px">
          <Typo14Medium>{currentUser?.fullname}</Typo14Medium>
          <Typo14
            sx={{
              color: baseStyles.color.blue.primary60,
              lineHeight: "18px",
              letterSpacing: "0.5px"
            }}
          >
            {currentUser?.role}
          </Typo14>
        </Stack>
        <IconButton onClick={(e) => handleClick(e)} size="small">
          <AltArrowDownIcon stroke={baseStyles.color.blue.primary} />
        </IconButton>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        sx={{
          "& .MuiPopover-paper": {
            marginTop: "15px",
            borderRadius: "20px",
            overflow: "visible"
          }
        }}
      >
        <Box
          sx={{
            padding: "20px",
            borderRadius: "20px",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.08)"
          }}
        >
          <SvgIcon
            sx={{
              position: "absolute",
              left: "110px",
              top: "-16px"
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="16"
              viewBox="0 0 36 16"
              fill="none"
            >
              <path d="M18 0L35.3205 15.75H0.679491L18 0Z" fill="white" />
            </svg>
          </SvgIcon>
          <Stack>
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#e4e4e485"
                }
              }}
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              padding={"10px 0"}
              borderBottom={"1px dashed rgba(1, 29, 123, 0.15);"}
              onClick={navigateToMyAccount}
            >
              <FineTune color={baseStyles.color.blue.primary} />
              <Typo14Medium>{t('accountParameters')}</Typo14Medium>
            </Box>
            <Box
              onClick={performLogout}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#e4e4e485"
                }
              }}
              display={"flex"}
              padding={"10px 0"}
              alignItems={"center"}
              gap={"10px"}
            >
              <LogoutIcon2 color={baseStyles.color.blue.primary} />
              <Typo14Medium>{t('logout')}</Typo14Medium>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
