import { FC, useContext, useEffect } from "react";
import { bankStatsContainerStyles, lineContainerStyles } from "./styles";
import { flexCenter, titleCommonStyles } from "utils/constants/baseStyles";

import { BankContext } from "providers/BankProvider";
import { BankGlobalStats } from "./BankGlobalStats/BankGlobalStats";
import { Box } from "@mui/material";
import { ClienteleListStats } from "./ClienteleListStats/ClienteleListStats";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { getAnalysesStats } from "services/Analysis/AnalysisService";
import { selectStatsState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

interface IProps {}

export const BankStats: FC<IProps> = (props: IProps) => {
  const { selectedBank } = useContext(BankContext);
  const bank = selectedBank.get();
  const { filters } = useAppSelector(selectStatsState);
  const { t } = useTranslation();
  
  useEffect(() => {
    getAnalysesStats({ ...filters })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedBank, filters]);

  return (
    <Box sx={bankStatsContainerStyles}>
      {selectedBank.get() ? (
        <>
          <Box sx={lineContainerStyles(true)}>
            <Typo24Bold sx={titleCommonStyles}>{bank?.name}</Typo24Bold>
            <BankGlobalStats></BankGlobalStats>
          </Box>
          <Box sx={lineContainerStyles()}>
            <ClienteleListStats></ClienteleListStats>
          </Box>
        </>
      ) : (
        <Box sx={{ ...flexCenter, width: "100%", padding: "40px" }}>
          <Typo16 weight="medium">{t('noSelectedBank')}</Typo16>
        </Box>
      )}
    </Box>
  );
};
