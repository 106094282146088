import { Box, Grid, InputAdornment, Stack } from "@mui/material";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { baseStyles, typoCommonStyles } from "utils/constants/baseStyles";
import { inputErrorStyles, inputLabelStyles, inputPasswordStyles } from "./styles";

import { BorderContainer } from "components/BorderContainer/BorderContainer";
import { CustomAlertError } from "components/Alert/CustomAlertError";
import { CustomInput } from "components/InputField/CustomInput";
import { LockIcon } from "components/Icons/SvgIcons";
import { Typo14 } from "components/Typography/Typo14";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { useTranslation } from "react-i18next";

type UpdatePasswordType = {
  onChange: any;
  incorrectPassword?: boolean;
};

type PasswordDataType = {
  actualPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const UpdatePassword: FC<UpdatePasswordType> = ({ onChange, incorrectPassword = false }) => {
  const { t } = useTranslation();
  
  const [state, setState] = useState<PasswordDataType>({
    actualPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const [errorMessage, setErrorMessage] = useState<null | { title: string; body: string }>(null);

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*[\W_])(.{8,})$/;
    return password === "" || regex.test(password);
  };

  const isPasswordValid = useCallback(() => {
    return validatePassword(state.newPassword);
  }, [state.newPassword]);

  const isNotSame = useCallback(() => {
    return (
      state.newPassword && state.confirmPassword && state.newPassword !== state.confirmPassword
    );
  }, [state.confirmPassword, state.newPassword]);

  const notifyChange = (e: ChangeEvent<HTMLInputElement>, key: keyof PasswordDataType) => {
    setState((oldState) => {
      return {
        ...oldState,
        [key]: e.target.value
      };
    });
    return;
  };

  React.useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  React.useEffect(() => {
    if (incorrectPassword) {
      setErrorMessage({
        title: t('incorrectOldPassword'),
        body: t('incorrectOldPasswordText')
      });
    } else if (!isPasswordValid()) {
      setErrorMessage({
        title: t('newPasswordTooWeak'),
        body: t('newPasswordTooWeakText')
      });
    } else if (isNotSame()) {
      setErrorMessage({
        title: t('passwordsNotTheSame'),
        body: t('passwordsNotTheSameText')
      });
    } else {
      setErrorMessage(null);
    }
  }, [incorrectPassword, isNotSame, isPasswordValid]);

  const isValidNewPassword = validatePassword(state.newPassword);
  const isValidConfirmPassword = validatePassword(state.confirmPassword);

  return (
    <BorderContainer sx={{ gap: "0px" }}>
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "40px" }}>
          <Typo24Bold sx={typoCommonStyles}>{t('resetPassword.modifyPassword')}</Typo24Bold>
        </Box>
        <CustomAlertError
          collapseSx={{ marginBottom: !!errorMessage ? "40px" : "0px" }}
          title={errorMessage?.title}
          body={errorMessage?.body}
          opened={!!errorMessage}
          onclose={() => setErrorMessage(null)}
        />

        <Box>
          <Box>
            <Grid container>
              <Grid item xs={12} paddingBottom="40px">
                <Stack gap={"20px"}>
                  <Typo14 sx={inputLabelStyles}>{t('actualPassword')} : </Typo14>
                  <CustomInput
                    sx={
                      incorrectPassword
                        ? { ...inputPasswordStyles, ...(inputErrorStyles as any) }
                        : { ...inputPasswordStyles }
                    }
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <LockIcon
                          color={incorrectPassword ? baseStyles.color.error.main40 : undefined}
                        />
                      </InputAdornment>
                    }
                    isValid={!incorrectPassword}
                    onchange={(e) => {
                      notifyChange(e, "actualPassword");
                    }}
                    type="password"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack gap={"20px"}>
                  <Typo14 sx={inputLabelStyles}>{t('newPassword')} : </Typo14>
                  <CustomInput
                    sx={
                      !isValidNewPassword
                        ? { ...inputPasswordStyles, ...(inputErrorStyles as any) }
                        : { ...inputPasswordStyles }
                    }
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <LockIcon
                          color={!isValidNewPassword ? baseStyles.color.error.main40 : undefined}
                        />
                      </InputAdornment>
                    }
                    isValid={isValidNewPassword}
                    type="password"
                    onchange={(e) => {
                      notifyChange(e, "newPassword");
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6} paddingLeft="30px">
                <Stack gap={"20px"}>
                  <Typo14 sx={inputLabelStyles}>{t('confirm')} :</Typo14>
                  <CustomInput
                    sx={
                      !isValidConfirmPassword
                        ? { ...inputPasswordStyles, ...(inputErrorStyles as any) }
                        : { ...inputPasswordStyles }
                    }
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <LockIcon
                          color={
                            !isValidConfirmPassword ? baseStyles.color.error.main40 : undefined
                          }
                        />
                      </InputAdornment>
                    }
                    isValid={isValidConfirmPassword}
                    type="password"
                    onchange={(e) => {
                      notifyChange(e, "confirmPassword");
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    </BorderContainer>
  );
};
