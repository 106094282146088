import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery
} from "@reduxjs/toolkit/query";

import { Mutex } from "async-mutex";
import { Storage } from "utils/storage";

// Create a new mutex
const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACK_URL,
  prepareHeaders: async (headers) => {
    const accessToken = Storage.getItem("token");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  }
});

export const customFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = Storage.getItem("refreshToken");

        const refreshResult: any = await baseQuery(
          { url: "/api/auth/token/refresh/", method: "POST", body: { refresh: refreshToken } },
          api,
          extraOptions
        );

        if (refreshResult?.data?.access) {
          // Set new token
          Storage.setItem("token", refreshResult.data.access);

          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          Storage.clear();
          window.location.href = "/login";
        }
      } catch (error) {
        console.log("Error during refresh token", error);
        Storage.clear();
        window.location.href = "/login";
      } finally {
        // Release must be called once the mutex should be released again.
        release();
      }
    } else {
      // Wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
