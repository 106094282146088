import { CreateNewAnalysisPayload, ExportAnalysisPayload, UpdateAnalysisPayload } from "services/Analysis/AnalysisService";

import { Analysis } from "types/analysis/analysis";
import { AnalysisSummary } from "types/analysis/summary";
import { addUrlParams } from "utils/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "./customFetchBaseQuery";

export interface GetAnalysisSummaryQueryParams {
  startDate?: string;
  endDate?: string;
  responsibleId?: number | string;
}

export interface GetManyAnalysisQueryParams {
  ref?: string;
  statusCode?: string;
  analysisTypeCode?: string;
  clientId?: string;
  clientRef?: string;
  clientName?: string;
  date?: string;
  responsibleId?: number | string;
  startDate?: string;
  endDate?: string;
}

export const analysisApi = createApi({
  reducerPath: "analysisApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Analyzes", "AnalyzesSummary"],
  endpoints: (builder) => ({
    getManyAnalyzes: builder.query<Analysis[], GetManyAnalysisQueryParams>({
      query: ({ ref, statusCode, analysisTypeCode, clientId, clientRef, clientName, date, responsibleId, startDate, endDate }) => {
        let url = `/api/analyses`;
        if (ref) url = addUrlParams(url, "ref", ref);
        if (statusCode) url = addUrlParams(url, "statusCode", statusCode);
        if (analysisTypeCode) url = addUrlParams(url, "analysisTypeCode", analysisTypeCode);
        if (clientId) url = addUrlParams(url, "clientId", clientId);
        if (clientRef) url = addUrlParams(url, "clientRef", clientRef);
        if (clientName) url = addUrlParams(url, "clientName", clientName);
        if (date) url = addUrlParams(url, "startDate", date);
        if (date) url = addUrlParams(url, "endDate", date);
        if (responsibleId) url = addUrlParams(url, "responsibleId", responsibleId.toString());
        if (startDate) url = addUrlParams(url, "startDate", startDate);
        if (endDate) url = addUrlParams(url, "endDate", endDate);
        
        return { url };
      },
      providesTags: (result, error, arg) => (error ? [] : ["Analyzes"])
    }),
    getAnalysis: builder.query<Analysis, number>({
      query: (id) => {
        const url = `/api/analyses/${id}`;
        return { url };
      },
      providesTags: (result) => (result ? [{ type: "Analyzes", id: result.id }] : [])
    }),
    getAnalyzesSummary: builder.query<AnalysisSummary, GetAnalysisSummaryQueryParams>({
      query: ({ startDate, endDate, responsibleId }) => {
        let url = `/api/analyses/stats`;
        if (startDate) url = addUrlParams(url, "startDate", startDate);
        if (endDate) url = addUrlParams(url, "endDate", endDate);
        if (responsibleId) url = addUrlParams(url, "responsibleId", responsibleId.toString());
        return url;
      },
      providesTags: (result, error, arg) => (error ? [] : ["AnalyzesSummary"])
    }),
    createAnalysis: builder.mutation<Analysis, CreateNewAnalysisPayload>({
      query(body) {
        return { url: "/api/analyses/", method: "POST", body };
      },
      invalidatesTags: (result, error, id) => (error ? [] : ["Analyzes", "AnalyzesSummary"])
    }),
    updateAnalysis: builder.mutation<Analysis, UpdateAnalysisPayload>({
      query({ id, ...body }) {
        return { url: `/api/analyses/${id}`, method: "PUT", body };
      },
      invalidatesTags: (result, error, id) => (error ? [] : ["Analyzes", "AnalyzesSummary"])
    }),
    exportAnalysis: builder.query<any, ExportAnalysisPayload>({
      query: ({id, language}) => {
        let url = `/api/analyses/export/${id}`;
        if (language) url = addUrlParams(url, "language", language);
        
        return {
          url,
          responseHandler: async (response) => {
            return window.open(window.URL.createObjectURL(await response.blob()), "_blank");
          }
        };
      }
    })
  })
});

export const {
  useGetAnalysisQuery,
  useLazyGetAnalysisQuery,
  useGetManyAnalyzesQuery,
  useLazyGetManyAnalyzesQuery,
  useGetAnalyzesSummaryQuery,
  useLazyGetAnalyzesSummaryQuery,
  useCreateAnalysisMutation,
  useUpdateAnalysisMutation,
  useExportAnalysisQuery,
  useLazyExportAnalysisQuery
} = analysisApi;
