import { Box, Skeleton } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

export const ListSupportItemLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "10px",
        padding: "30px",
        borderBottom: `1px solid ${baseStyles.color.blue.primary10}`
      }}
    >
      <Skeleton
        width={48}
        height={48}
        variant="circular"
        animation="pulse"
        sx={{ minWidth: "48px" }}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px"
          }}
        >
          <Skeleton width="85%" height={20} variant="rounded" animation="wave" />
          <Skeleton width="15%" height={20} variant="rounded" animation="wave" />
        </Box>
        <Skeleton width={"100%"} height={40} variant="rounded" animation="wave" />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px"
          }}
        >
          <Skeleton width="70%" height={18} variant="rounded" animation="wave" />
          <Skeleton width="30%" height={18} variant="rounded" animation="wave" />
        </Box>
      </Box>
    </Box>
  );
};
