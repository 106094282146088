import { baseStyles, flexCenter, fontMontserrat } from "utils/constants/baseStyles";

import { Box } from "@mui/material";
import { Typo12 } from "components/Typography/Typo12";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type NavbarItemProps = {
  icon?: ReactNode;
  text: string;
  to?: string;
  active?: boolean;
  count?: number;
  click?: Function;
};

export const NavbarItem = (props: NavbarItemProps) => {
  const { icon, text, to = "#", active = false, click, count } = props;
  const navigate = useNavigate();

  let activeStyle = {
    background: "transparent",
    borderLeft: "2px solid transparent"
  };

  let color = {
    color: "rgba(255, 255, 255, 0.40)"
  };

  if (active) {
    activeStyle = {
      background: "rgba(255, 255, 255, 0.10)",
      borderLeft: "2px solid #FFF"
    };
    color = {
      color: "white !important"
    };
  }

  return (
    <Box
      onClick={() => {
        if (click) {
          click();
        } else {
          navigate(to);
        }
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        height: "60px",
        padding: "0px 40px",
        cursor: "pointer",
        ...activeStyle,
        "&:hover": {
          background: "rgba(255, 255, 255, 0.10)"
        }
      }}
    >
      {icon}
      <Box
        component="span"
        sx={{
          ...color,
          ...fontMontserrat,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          letterSpacing: "0.5px",
          lineHeight: "18px"
        }}
      >
        {text}
      </Box>
      {count ? (
        <Typo12
          sx={{
            ...flexCenter,
            backgroundColor: baseStyles.color.error.color,
            color: baseStyles.color.white,
            width: "25px",
            minWidth: "25px",
            height: "25px",
            borderRadius: "50%",
            marginLeft: "auto",
            fontWeight: "600",
            lineHeight: "18px"
          }}
        >
          {count}
        </Typo12>
      ) : (
        <></>
      )}
    </Box>
  );
};
