import { BankForm, BankFormState, bankFormDefaultValues } from "components/BankForm";
import React, { useCallback, useContext, useState } from "react";

import { BankContext } from "providers/BankProvider";
import { Box } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import { updateBank } from "services/Bank/BankService";
import { useTranslation } from "react-i18next";

type IProps = {
  open: boolean;
  handleClose: any;
};

export const UpdateBankModal = (props: IProps) => {
  const { t } = useTranslation();
  
  const { open, handleClose } = props;
  const { reloadBank, selectedBank } = useContext(BankContext);
  const [showBgBlur] = useState<boolean>(false);
  const [formDefaultValues, setFormDefaultValues] = useState<BankFormState>(bankFormDefaultValues);
  const [loading, setLoading] = useState(false);

  const currentBank = selectedBank.get();

  const handleUpdateBank = useCallback(
    (data: BankFormState) => {
      if (currentBank) {
        setLoading(true);
        updateBank(currentBank.id, { ...data, countryCode: data.countryId })
          .then((response) => {
            reloadBank();
            selectedBank.set(response.data);
            handleClose();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [currentBank, handleClose, reloadBank, selectedBank]
  );

  React.useEffect(() => {
    if (currentBank) {
      setFormDefaultValues({
        name: currentBank?.name || "",
        address: currentBank?.address || "",
        phone: currentBank?.phone || "",
        city: currentBank?.city || "",
        countryId: currentBank?.country?.code || ""
      });
    }
  }, [currentBank]);

  return (
    <CustomModal
      title={t('bankModification')}
      open={open}
      handleClose={handleClose}
      closeButton={true}
      footer={<Box display={"flex"} gap="30px" marginTop={"20px"}></Box>}
      bgBlur={
        showBgBlur && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              boxSizing: "border-box",
              border: "none",
              zIndex: 1000
            }}
          />
        )
      }
      width={"600px"}
      sx={{ padding: "40px 30px" }}
      headerSx={{ paddingBottom: "30px", marginBottom: "30px" }}
    >
      <BankForm
        onSubmit={handleUpdateBank}
        loading={loading}
        defaultValues={formDefaultValues}
        isEdit={true}
      />
    </CustomModal>
  );
};
