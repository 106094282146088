import { ADMIN_PERMISSION, CLIENT_PERMISSION } from "services/Mocks/fakePermission";
import { ANALYSIS_RESULTS_PAGE_ID, HEADER_HEIGHT } from "constants/dom";
import { Box, Grid } from "@mui/material";
import React, { FC, ReactNode, useContext, useState } from "react";
import { getCurrentUser, isAdmin, isChargeClientele } from "services/User/UserService";

import { LeftArrowIcon } from "components/Icons/SvgIcons";
import Navbar from "components/Navbar/Navbar";
import { ROUTES } from "routes/routes";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { Typo16 } from "components/Typography/Typo16";
import { UserContext } from "providers/UserProvider";
import { UserPanel } from "./UserPanel";
import { adaptUserInfo } from "types/user";
import dayjs from "dayjs";
import i18next from "i18next";
import { setCurrentAnalysis } from "store/slices";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface IProps {
  children: any;
  ongletTitle: any;
  backButton?: boolean;
  topBarChildren?: ReactNode;
  currentPage?: string;
}

const DashboardWrapper: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  
  const { children, ongletTitle, backButton = false, topBarChildren, currentPage } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const { setCurrentUser } = useContext(UserContext);
  const dispatch = useAppDispatch();

  const { pathname } = window.location;

  const handleClickBack = () => {
    if (currentPage === ANALYSIS_RESULTS_PAGE_ID) {
      dispatch(setCurrentAnalysis(null));
      navigate("/");
    } else if (pathname === "/my-account") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  React.useEffect(() => {
    getCurrentUser()
      .then((response) => {
        setCurrentUser((currentUser: any) => {
          return adaptUserInfo({
            ...currentUser,
            ...response.data,
            permissions: isAdmin(response.data.groups)
              ? ADMIN_PERMISSION
              : isChargeClientele(response.data.groups)
              ? CLIENT_PERMISSION
              : []
          });
        });
        setLoading(false);
        if (isAdmin(response.data.groups)) {
          navigate(ROUTES.adminHome);
        }
      })
      .catch((e) => {
        navigate(ROUTES.login);
      });
  }, []);

  i18next.on('languageChanged', (lng) => {
    dayjs.locale(lng);
  });
  
  return loading ? (
    <Box></Box>
  ) : (
    <Grid container sx={{ height: "100vh", flexWrap: "nowrap" }}>
      <Box sx={{ width: "30%", maxWidth: "350px", height: "100%", flexShrink: "0" }}>
        <Navbar />
      </Box>
      <Box sx={{ width: "70%", flexGrow: "1", maxHeight: "100%" }}>
        <Grid container direction={"column"} sx={{ height: "100%", flexWrap: "nowrap" }}>
          <Grid item>
            {/* Top bar */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0rem 2.5rem",
                height: HEADER_HEIGHT,
                borderBottom: "1px solid rgba(1, 29, 123, 0.10)"
              }}
            >
              {/* Left top bar */}
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                {backButton && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "40px",
                      height: " 40px",
                      flexShrink: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      background: "rgba(1, 29, 123, 0.10)",
                      cursor: "pointer"
                    }}
                    onClick={handleClickBack}
                  >
                    <LeftArrowIcon />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "fit-content",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "2px solid var(--bleu-marine, #011D7B)",
                    marginBottom: "-2px"
                  }}
                >
                  <Typo16 weight="medium" sx={{ letterSpacing: "0.5px", lineHeight: "18px" }}>
                    {t(ongletTitle)}
                  </Typo16>
                </Box>
                {topBarChildren}
              </Box>

              {/* Right top bar */}
              <Box sx={{ minWidth: "fit-content", height: "100%", display: "flex", flexDirection: "row"}}>
                <SwitchLanguage></SwitchLanguage>    
                <UserPanel />
              </Box>
            </Box>
          </Grid>
          <Grid item sx={{ flexGrow: "1", overflowY: "auto" }}>
            <Box sx={{ height: "100%", overflowY: "auto" }}>{children}</Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default DashboardWrapper;
