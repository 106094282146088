import { SxProps } from "@mui/material";
import { styles as globalStatsStyles } from "pages/Home/GlobalStats/styles";
import { Styles } from "types";
import { baseStyles } from "utils/constants/baseStyles";

const commonContainerStyles: SxProps = {
  width: "100%",
  maxWidth: "364px",
  height: "100%",
  minHeight: "128px",
  borderRadius: "20px",
  backgroundColor: baseStyles.color.white,
  gap: "15px",
  display: "flex",
  alignItems: "center"
};

export const styles: Styles = {
  bankGlobalStatsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(auto, 364px))",
    gap: "20px"
  },
  container: {
    ...commonContainerStyles,
    padding: "30px",
    flexGrow: 1,
    height: "100%"
  },
  total: {
    backgroundColor: baseStyles.color.blue.primary
  },
  statsContainer: {
    ...commonContainerStyles,
    padding: "16px 30px",
    boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.07)"
  },
  statsNumber: {
    ...globalStatsStyles.analysisStatNumber,
    color: baseStyles.color.blue.primary,
    marginBottom: "0px"
  }
};
