import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const TrashBinIcon18: FC<Pick<SvgIconProps, "fill" | "fillOpacity">> = ({
  fill = baseStyles.color.blue.primary,
  fillOpacity = 0.6
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M2.0625 5.12501C2.0625 4.77983 2.32154 4.50001 2.64107 4.50001L4.63925 4.49968C5.03627 4.48881 5.38651 4.21611 5.52161 3.81268C5.52516 3.80208 5.52924 3.78899 5.54389 3.74152L5.62999 3.46245C5.68268 3.29134 5.72858 3.14227 5.79281 3.00902C6.04657 2.48262 6.51606 2.11708 7.0586 2.02349C7.19593 1.9998 7.34136 1.9999 7.5083 2.00002H10.1168C10.2838 1.9999 10.4292 1.9998 10.5665 2.02349C11.1091 2.11708 11.5786 2.48262 11.8323 3.00902C11.8966 3.14227 11.9425 3.29134 11.9951 3.46245L12.0812 3.74152C12.0959 3.78899 12.1 3.80208 12.1035 3.81268C12.2386 4.21611 12.6583 4.48914 13.0554 4.50001H14.9839C15.3035 4.50001 15.5625 4.77983 15.5625 5.12501C15.5625 5.47018 15.3035 5.75 14.9839 5.75H2.64107C2.32154 5.75 2.0625 5.47018 2.0625 5.12501Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
      <path
        d="M8.70508 16.9999H9.29527C11.3259 16.9999 12.3412 16.9999 13.0013 16.3525C13.6615 15.7051 13.729 14.6431 13.8641 12.5192L14.0587 9.45878C14.132 8.30636 14.1687 7.73015 13.8375 7.36502C13.5063 6.99988 12.9471 6.99988 11.8286 6.99988H6.17179C5.05328 6.99988 4.49403 6.99988 4.16286 7.36502C3.83168 7.73015 3.86833 8.30636 3.94162 9.45878L4.13625 12.5192C4.27133 14.6431 4.33886 15.7051 4.999 16.3525C5.65914 16.9999 6.67446 16.9999 8.70508 16.9999Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};
