import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const SettingsMinimalisticIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4277 2.5C11.3139 2.5 10.2995 3.1007 8.27081 4.30211L7.58466 4.70846C5.55594 5.90987 4.54158 6.51057 3.98466 7.5C3.42773 8.48943 3.42773 9.69084 3.42773 12.0937V12.9063C3.42773 15.3092 3.42773 16.5106 3.98466 17.5C4.54158 18.4894 5.55594 19.0901 7.58466 20.2915L8.27081 20.6979C10.2995 21.8993 11.3139 22.5 12.4277 22.5C13.5416 22.5 14.5559 21.8993 16.5847 20.6979L17.2708 20.2915C19.2995 19.0901 20.3139 18.4894 20.8708 17.5C21.4277 16.5106 21.4277 15.3092 21.4277 12.9063V12.0937C21.4277 9.69084 21.4277 8.48943 20.8708 7.5C20.3139 6.51057 19.2995 5.90987 17.2708 4.70846L16.5847 4.30211C14.5559 3.1007 13.5416 2.5 12.4277 2.5ZM8.67773 12.5C8.67773 10.4289 10.3567 8.75 12.4277 8.75C14.4988 8.75 16.1777 10.4289 16.1777 12.5C16.1777 14.5711 14.4988 16.25 12.4277 16.25C10.3567 16.25 8.67773 14.5711 8.67773 12.5Z"
        fill={fill}
      />
    </svg>
  );
};
