import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const styles = {
  containerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  supportsList: {
    flexGrow: "1"
  },
  messagesView: {
    flexGrow: "2"
  },
  newMessage: {
    flexGrow: "2"
  }
};

export const msgListContainerStyles: SxProps = {
  width: "60%",
  overflow: "auto",
  backgroundColor: baseStyles.color.blue.primary5
};

export const newSupportMessageContainerStyles: SxProps = {
  ...flexCenter,
  flexDirection: "column",
  width: "20%",
  borderLeft: `1px solid ${baseStyles.color.blue.primary10}`
};

export const logoMsgStyles: CSSProperties = {
  width: "100%",
  maxWidth: "250px",
  maxHeight: "250px"
};

export const newSupportMessageButtonStyles: SxProps = {
  ...flexCenter,
  flexDirection: "column",
  gap: "20px",
  textAlign: "center",
  cursor: "pointer"
};

export const penNewSquareIconContainerStyles: SxProps = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: baseStyles.color.green.primary,
  ":hover": {
    backgroundColor: baseStyles.color.green.primary
  }
};
