import { Provider } from "providers";
import { RouterProvider } from "react-router-dom";
import routers from "./routes/routes";

function App() {
  return (
    <Provider>
      <RouterProvider router={routers} />
    </Provider>
  );
}

export default App;
