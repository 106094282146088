import { SxProps } from "@mui/material";
import {
  baseStyles,
  flexCenter,
  fontMontserrat,
  typoCommonStyles
} from "utils/constants/baseStyles";

const textStyles: SxProps = {
  ...typoCommonStyles,
  ...(fontMontserrat as any),
  color: baseStyles.color.blue.primary,
  fontWeight: "400",
  fontSize: "14px"
};

export const formControlContainerStyles: SxProps = {
  ...flexCenter,
  borderTop: `1px solid ${baseStyles.color.blue.primary10}`,
  borderBottom: `1px solid ${baseStyles.color.blue.primary10}`,
  height: "100px",
  padding: "0px 30px"
};

export const formControlStyles: SxProps = {
  "& > .MuiInputBase-root": {
    ...(textStyles as any),
    borderRadius: "10px",
    border: 0,
    "& fieldset, & fieldset:focus-visible, & .MuiOutlinedInput-notchedOutline": {
      border: `2px solid`,
      borderColor: `${baseStyles.color.blue.primary10} !important`,
      outline: "none"
    },
    "&:hover": {
      "& fieldset": {
        border: `2px solid`,
        borderColor: `${baseStyles.color.blue.primary10} !important`,
        padding: 0
      }
    }
  }
};

export const inputSelectStyles: SxProps = {
  "& .MuiSelect-select": {
    padding: "13.5px 15px",
    border: 0
  }
};

export const inputSelectMenuItemStyles: SxProps = {
  ...textStyles,
  padding: "10px 15px"
};
