import React, { useState } from "react";

export const useWidth = (elementRef: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState("auto");

  React.useEffect(() => {
    const listener = () => {
      if (elementRef.current) {
        setWidth(`${elementRef.current.clientWidth}px`);
      }
    };

    if (elementRef.current) {
      setWidth(`${elementRef.current.clientWidth}px`);
    }

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [elementRef]);

  return { width };
};
