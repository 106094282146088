import axios from "services/AuthorizedAxiosInstance";

export type FilePayload = {
  analysisTypeCode?: string,
  fileTypeCode?: string,
  mobileMoneyCompanyId?: number,
  bankId?: number,
  base64data?: string;
  accessCode?: string;
};

export type CreateNewAnalysisPayload = {
  institutionId?: number;
  clientRef?: string;
  clientName?: string;
  currencyCode?: string;
  files: Array<FilePayload>;
  applicationId?: string;
  countryCode?: string;
  bvnMonoCode?: string;
};

export type UpdateAnalysisPayload = {
  id: string | number;
  statusCode?: string;
};

export type ExportAnalysisPayload = {
  id: string | number;
  language?: string;
}

export const createNewAnalysis = (payload: CreateNewAnalysisPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/analyses/", payload)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject({
            message: "error serveur",
            status: "error",
            error: error
          });
        }
      )
      .catch(reject);
  });
};

export const getAnalysesStats = (params: any = {}) => {
  return axios.get("/api/analyses/stats", { params: params });
};
