import { btnAddBank, btnAddBankContainer } from "./styles";

import { AddCircleIcon41 } from "assets/icons/AddCircleIcon";
import { Box } from "@mui/material";
import { NewBankModal } from "../BankList/NewBankModal/NewBankModal";
import { Typo14 } from "components/Typography/Typo14";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const AddBank = () => {
  const [modalAddBankOpen, setModalAddBankOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  
  const openModalAddBank = () => {
    setModalAddBankOpen(true);
  };

  const handleCloseModalAddBank = () => {
    setModalAddBankOpen(false);
  };

  return (
    <>
      <Box sx={btnAddBankContainer} onClick={openModalAddBank}>
        <Box sx={btnAddBank}>
          <AddCircleIcon41 />
          <Typo14 weight="medium" color="white">
            {t('addBank')}
          </Typo14>
        </Box>
      </Box>
      <NewBankModal open={modalAddBankOpen} handleClose={handleCloseModalAddBank} />
    </>
  );
};
