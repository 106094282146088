import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const AltArrowLeftIcon14: FC<Pick<SvgIconProps, "stroke" | "strokeOpacity">> = ({
  stroke = baseStyles.color.blue.primary,
  strokeOpacity = 1
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M8.75 2.91667L5.25 7L8.75 11.0833"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AltArrowLeftIcon24: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.blue.primary,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15 5L9 12L15 19"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
