import { FC, createContext, useState } from "react"

// Context
interface BankContextProps {
    banks: {
        loading: boolean,
        get: () => Bank[],
        set: (banks: Bank[]) => void
    },
    selectedBank: {
        get: () => Bank | undefined,
        set: (bank?: Bank) => void
    },
    reset: number,
    reloadBank: () => void
}

export const BankContext = createContext<BankContextProps>({
    banks: {
        loading: true,
        get: () => [],
        set: (banks: Bank[]) => {}
    },
    selectedBank: {
        get: () => undefined,
        set: (bank?: Bank) => {}
    },
    reset: 1,
    reloadBank: () => {}
})

// Provider
interface BankProviderProps {
    children: any
}
export const BankProvider: FC<BankProviderProps> = (props: BankProviderProps) => {
    const { children } = props;
    
    const [banksState, setBanksState] = useState<Bank[]>([]);
    const [selectedBankState, setSelectedBankState] = useState<Bank|undefined>();
    const [resetState, setResetState] = useState<number>(0);
    const providerValue = {
        banks: {
            loading: true,
            get: () => banksState,
            set: (banks: Bank[]) => setBanksState(banks)
        },
        selectedBank: {
            get: () => selectedBankState,
            set: (bank?: Bank) => {
                setSelectedBankState(bank);
                console.log('selectedBankState', selectedBankState);
            }
        },
        reset: resetState,
        reloadBank: () => {setResetState(resetState+1)}
    };
    
    return (
        <BankContext.Provider value={providerValue}>
            {children}
        </BankContext.Provider>
    )
}