import { Box } from "@mui/material";
import MessageItem from "components/MessageItem/MessageItem";

type ListMessageContentProps = {
  supportTicketMessages: Array<SupportTicketMessage> | null;
  loading: boolean;
};

export const ListMessageContent = (props: ListMessageContentProps) => {
  const { supportTicketMessages, loading = true } = props;

  // console.log({supportTicketMessages})
  const suspense = () => {
    return Array.from({ length: 2 }).map((value, i) => {
      return (
        <MessageItem key={i} sender={{} as Issuer} id={i} date={""} content={""} loading={true} />
      );
    });
  };

  return (
    <>
      {loading ? (
        <>{suspense()}</>
      ) : supportTicketMessages && supportTicketMessages.length > 0 ? (
        supportTicketMessages.map((message, i) => (
          <MessageItem
            key={i}
            sender={message.sender as Issuer}
            id={message.id}
            date={message.date}
            content={message.text}
            loading={false}
            files={message.files}
          />
        ))
      ) : (
        <Box>Aucun message</Box>
      )}
    </>
  );
};
