import { IssuerAdapter } from "types/user";

export const adapterSupportTicket = (data: any) => {
  return {
    id: data["id"],
    subject: data["subject"],
    issuer: IssuerAdapter(data["issuer"]),
    date: data["date"],
    messagesCount: data["messagesCount"]
  } as SupportTicket;
};

export const adapterSupportTicketMessage = (data: any) => {
  return {
    id: data["id"],
    date: data["date"],
    sender: IssuerAdapter(data["sender"]),
    text: data["text"],
    supportTicket: adapterSupportTicket(data["supportTicket"]),
    files: data["files"]
  } as SupportTicketMessage;
};
