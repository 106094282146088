import { SxProps } from "@mui/material";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const containerStyles: SxProps = {
  ...flexCenter,
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "5px",
  width: "120px",
  minWidth: "120px",
  height: "150px",
  boxSizing: "border-box",
  borderRadius: "30px",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0px 1px 24px 0px rgba(0, 0, 0, 0.08)",
  cursor: "pointer",
  color: baseStyles.color.blue.primary60,
  backgroundColor: baseStyles.color.white
};

export const containerActiveStyles: SxProps = {
  color: baseStyles.color.blue.primary,
  backgroundColor: baseStyles.color.green.primary
};

export const nameContainerStyles: SxProps = {
  overflowX: "hidden"
};

export const nameStyles: SxProps = {
  color: baseStyles.color.blue.primary60
};

export const nameActiveStyles: SxProps = {
  color: baseStyles.color.blue.primary,
  fontWeight: "600"
};
