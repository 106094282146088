import { baseStyles, flexCenter } from "utils/constants/baseStyles";

import { Styles } from "types";

export const styles: Styles = {
  itemContainer: {
    ...flexCenter,
    position: "relative",
    textAlign: "center",
    border: `1px solid ${baseStyles.color.blue.primary10}`,
    height: "auto",
    minHeight: "100px",
    borderRadius: "10px",
    cursor: "pointer",
    padding: "10px 20px",

    "&.active": {
      backgroundColor: baseStyles.color.green.primary,
      color: baseStyles.color.white,
      border: 0,
      "&::after": {
        position: "absolute",
        content: "url('images/selected-bank-arrow.svg')",
        top: "50%",
        transform: "translateY(-50%)",
        right: "-27px",
        width: "27px",
        height: "17px"
      }
    }
  }
};
