import { Analysis } from "types/analysis/analysis";

export const FAKE_ANALYSIS: Analysis = {
  id: "",
  ref: "A001",
  date: "2023-08-10",
  type: {
    id: 1,
    name: "Relevé bancaire",
    code: "BANK"
  },
  status: {
    id: 2,
    name: "Echouée",
    code: "FAILED"
  },
  responsible: {
    id: 1,
    email: "admin_dev-fumiwo@yopmail.com",
    firstname: "Admin",
    lastname: "Admin",
    permissions: [],
    groups: ["ADMIN"],
    role: ""
  },
  client: {
    id: 1,
    firstname: "Raclient",
    lastname: "Client",
    ref: "",
    currency: {
      id: 1,
      name: "Ariary",
      code: "MGA"
    }
  },
  currency: { id: "", name: "", code: "" },
  fileType: { id: "", code: "", name: "" },
  result: {
    accountActivity: 0.5176470588235295,
    averageBalance: null,
    averageCredits: 19031.624784853702,
    averageDebits: 7090.085034013606,
    closingBalance: null,
    firstDay: "2023-01-02",
    lastDay: "2023-12-08",
    monthPeriod: 12,
    noOfTransactingMonths: 12,
    totalCreditTurnover: 11057374,
    totalDebitTurnover: 2084485,
    yearInStatement: 2023,
    netAverageMonthlyEarnings: -173707.08333333334,
    airtime: 75.83333333333333,
    totalExpenses: 173707.08333333334,
    webSpend: 7441.666666666667,
    bundleSpend: 5465.833333333333,
    entertainment: 41.666666666666664,
    inflowOutflowRate: "Positive Cash Flow",
    topIncomingTransferAccount: "ftossou",
    topTransferRecipientAccount: "22996725171@buydataforself.sp",
    lastDateOfCredit: "2023-12-08",
    lastDateOfDebit: "2023-12-08",
    averageOtherIncome: 0,
    averageSalary: 0,
    MaxMonthlyRepayment: 299096.3,
    averageMonthlyCredit: 921447.8333333334,
    totalMonthlyCredit: 11057374,
    noOfTransactingDays: 176,
    dayPeriode: 340,
    confidenceIntervalOnSalaryDetection: null,
    expectedSalaryDay: null,
    lastSalaryDate: null,
    medianIncome: null,
    numberOtherIncomePayments: 0,
    numberOfSalaryPayments: 0,
    salaryEarner: false,
    salaryFrequency: null,
    averageRecurringExpense: 31372.666666666668,
    bankCharges: 2076.9166666666665,
    bills: 23097.666666666668,
    gambling: 0,
    dab: 416.6666666666667,
    hasRecurringExpense: false,
    internationalTransactionsSpend: null,
    posSpend: 6497.916666666667,
    spendOnTransfers: 41.666666666666664,
    ussdTransactions: 0,
    accountSweep: null,
    gamblingRate: null,
    loanAmount: null,
    loanInflowRate: null,
    loanRepaymentInflowRate: null,
    loanRepayments: null,
    highestMAWOCredit: 3,
    highestMAWODebit: 3,
    MAWWZeroBalanceInAccount: null,
    mostFrequentBalanceRange: {
      min: null,
      max: null,
      count: null
    },
    mostFrequentTransactionRange: {
      min: 499,
      max: 500,
      count: 113
    },
    NODWBalanceLess5000: 0,
    transactionsBetween100000And500000: 37,
    transactionsBetween10000And100000: 222,
    transactionsGreater500000: 0
  }
};

export enum AnalysisEntityCode {
  FIRST_CENTRAL = 'FIRST_CENTRAL'
}
