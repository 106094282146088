import { FC, useContext, useEffect } from "react";
import { flexColumn, titleCommonStyles } from "utils/constants/baseStyles";
import { getBanks, getClientBanks } from "services/Bank/BankService";

import { AddBank } from "../AddBank";
import { BankContext } from "providers/BankProvider";
import { BankListItem } from "./BankListItem/BankListItem";
import { Box } from "@mui/material";
import { ClienteleContext } from "providers/ClienteleProvider";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { bankListContainerStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface BankListProps {
  title?: string;
  withAddBank?: boolean;
}

export const BankList: FC<BankListProps> = ({ title = '', withAddBank = true }) => {
  const { t } = useTranslation();
  title = t('banks');
  
  const { banks, selectedBank, reset } = useContext(BankContext);
  const { selectedClientele } = useContext(ClienteleContext);

  let b: any = null;

  useEffect(() => {
    getClientBanks().then((data) => {
      banks.set(data);
    });
  }, [reset]);

  useEffect(() => {
    b = selectedBank.get();
  }, [selectedBank.get()]);

  const handleClickBankListItem = (id: number) => {
    const filteredBanks = banks.get().filter((b) => b.id === id);
    console.log("filtered banks", filteredBanks);
    if (filteredBanks.length > 0) {
      selectedBank.set(filteredBanks[0]);
    }
    selectedClientele.set(undefined);
  };

  /**
   * * The className here is to identify this component in the navigator inspector
   * * PLEASE DONT REMOVE IT
   */
  return (
    <Box
      className="BankList"
      sx={{
        ...bankListContainerStyles,
        paddingBottom: withAddBank ? "calc(160px + 15px)" : "40px"
      }}
    >
      <Typo24Bold sx={{ ...titleCommonStyles, padding: "0px 30px" }}>{title}</Typo24Bold>
      <Box
        sx={{
          ...flexColumn,
          gap: "15px",
          padding: "0px 30px"
        }}
      >
        {banks.get().map((bank: any) => (
          <BankListItem
            bank={bank}
            key={bank.id}
            onClick={() => handleClickBankListItem(bank.id)}
            selected={selectedBank.get()?.id === bank.id}
          />
        ))}
      </Box>
      {withAddBank && (
        <Box sx={{ position: "fixed", bottom: 0 }}>
          <AddBank />
        </Box>
      )}
    </Box>
  );
};
