import { Box, SxProps } from "@mui/material";
import { ReactElement } from "react";
import { baseStyles } from "utils/constants/baseStyles";

type IProps = {
  sx?: SxProps;
  children: ReactElement;
  gap?: string;
};

export const BorderContainer = (props: IProps) => {
  const { children, gap = "40px", sx } = props;

  return (
    <Box
      sx={{
        padding: "40px",
        borderRadius: "20px",
        border: `2px solid ${baseStyles.color.blue.primary10}`,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: gap,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
