export const styles = {
    statementLineContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '14px',
        borderBottom: '2px dashed black'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonRemoveContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 60px'
    },
    removeStatementLineButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    iconButton: {
        '&.MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}