import { Box, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router";

import { AuthContext } from "providers/AuthProvider";
import { ChatIcon } from "components/Icons/SvgIcons";
import { HomeAngleIcon } from "assets/icons/HomeAngleIcon";
import { LogoTextSmallSize } from "components/Logo/LogoTextSmallSize";
import { LogoutIcon } from "assets/icons/LogoutIcon";
import { NavbarItem } from "./NavbarItem";
import { PieChartIcon } from "assets/icons/PieChartIcon";
import Restricted from "components/Permission/Restricted";
import { SettingsFineTuningWidgetIcon } from "assets/icons/SettingsFineTuningWidgetIcon";
import { SettingsMinimalisticIcon } from "assets/icons/SettingsMinimalisticIcon";
import { SupportConversationIcon } from "assets/icons/SupportConversationIcon";
import { SupportTicket } from "providers/SupportMessageProvider";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { UsersGroupTwoRoundedIcon } from "assets/icons/UsersGroupTwoRoundedIcon";
import { WebsocketContext } from "providers/WebsocketProvider";
import { changeLanguage } from "i18next";
import { getUnseenMessagescount } from "services/SupportTickets/SupportTicketsService";
import { useTranslation } from "react-i18next";

const Navbar: FC = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const performLogout = () => {
    console.log("Log out of the application");
    logout();
    navigate("/login");
  };

  const { unseenMessagesCount, setUnseenMessagesCount, resetUnseenMessagesCount } =
    useContext(SupportTicket);

  const { subscribe, unsubscribe } = useContext(WebsocketContext);

  useEffect(() => {
    updateUnseenMessagesCount();

    subscribe("new_support_message", "Navbar", (data: any) => {
      updateUnseenMessagesCount();
    });
  }, []);

  useEffect(() => {
    updateUnseenMessagesCount();
  }, [resetUnseenMessagesCount]);

  const updateUnseenMessagesCount = () => {
    getUnseenMessagescount()
      .then((response) => {
        console.log("unseen count", response.data.count);
        setUnseenMessagesCount(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  const { t } = useTranslation();
  
  return (
    <Box sx={{ backgroundColor: "#011D7B", height: "100%", width: "100%" }}>
      <Grid
        container
        direction={"column"}
        justifyContent={"space-between"}
        sx={{ height: "100%", padding: "40px 0", flexWrap: "nowrap", color: "white", gap: "10px" }}
      >
        {/* Logo home page */}
        <Grid item>
          <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
            <LogoTextSmallSize />
          </Box>
        </Grid>
        {/* Links */}
        <Grid item container direction={"column"} gap={"10px"} sx={{ height: "450px" }}>
          <Restricted to="view_statistique">
            <NavbarItem
              text={t('statistics')}
              icon={<PieChartIcon />}
              to="/admin"
              active={Boolean(useMatch("/admin")).valueOf()}
            />
          </Restricted>
          <Restricted to="view_home_clientele">
            <NavbarItem
              text={t('home')}
              icon={<HomeAngleIcon />}
              to="/"
              active={Boolean(useMatch("/")).valueOf()}
            />
          </Restricted>
          <Restricted to="view_managing_account">
            <NavbarItem
              text={t('accountManagement')}
              icon={<UsersGroupTwoRoundedIcon />}
              to="/admin/manage-accounts"
              active={Boolean(useMatch("/admin/manage-accounts")).valueOf()}
            />
          </Restricted>
          <Restricted to="view_setting_account_client">
            <NavbarItem
              text={t('accountParameters')}
              icon={<SettingsMinimalisticIcon />}
              to="/my-account"
              active={Boolean(useMatch("/my-account")).valueOf()}
            />
          </Restricted>
          <Restricted to="view_setting_account">
            <NavbarItem
              text={t('accountParameters')}
              icon={<SettingsFineTuningWidgetIcon />}
              to="/admin/my-account"
              active={Boolean(useMatch("/admin/my-account")).valueOf()}
            />
          </Restricted>
          <Restricted to="view_messagerie">
            <NavbarItem
              text={t('messaging')}
              icon={<ChatIcon />}
              to="/admin/messageries"
              active={Boolean(useMatch("/admin/messageries")).valueOf()}
              count={unseenMessagesCount}
            />
          </Restricted>
          <Restricted to="view_support">
            <NavbarItem
              text={"Support"}
              icon={<SupportConversationIcon />}
              to="/supports"
              active={Boolean(useMatch("/supports")).valueOf()}
              count={unseenMessagesCount}
            />
          </Restricted>
        </Grid>

        {/* Log out */}
        <Grid item>
          <NavbarItem click={performLogout} text={t('logout')} icon={<LogoutIcon />} to="#" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
