import { CustomTabs, CustomTabsData } from "components/CustomTabs";
import React, { FC } from "react";
import { selectAnalysisState, setCurrentAnalysis } from "store/slices";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useGetAnalysisQuery, useLazyExportAnalysisQuery } from "store/api";
import { useMatch, useNavigate } from "react-router";

import { AccountOverview } from "./AccountOverview";
import { Alert } from "@mui/material";
import { AnalysisInfo } from "./AnalysisInfo";
import { AnalysisResultsWrapper } from "./AnalysisResultsWrapper";
import { AnalysisSubHead } from "components/AnalysisSubHead";
import { Behavior } from "./Behavior";
import { Cashflow } from "./Cashflow";
import { CustomAlertError } from "components/Alert/CustomAlertError";
import { CustomError } from "components/CustomError";
import { CustomMenuItem } from "components/CustomMenu";
import { DisketteIcon } from "assets/icons/DisketteIcon";
import { Expenses } from "./Expenses";
import { GLOBAL_ERROR_MSG_GET_DATA } from "../../constants/error";
import { Income } from "./Income";
import { Trends } from "./Trends";
import i18n from "services/translation/i18n";
import i18next from "i18next";
import { isValidAnalysis } from "utils/analysis";
import { useTranslation } from "react-i18next";

const AnalysisResults: FC = () => {
  const { t } = useTranslation();
  
  const route = useMatch("analysis/:id/:activeTab");
  const analysisId = route?.params.id;
  const activeTab = route?.params.activeTab;
  const navigate = useNavigate();
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const dispatch = useAppDispatch();

  const { data: analysis, error, isLoading, isFetching } = useGetAnalysisQuery(Number(analysisId));
  const [exportAnalysis, { isLoading: exportIsLoading, isFetching: exportIsFetching }] =
    useLazyExportAnalysisQuery();

  const loading = isLoading || isFetching;
  const exportLoading = exportIsLoading || exportIsFetching;

  const analysisSubHeadMenus: CustomMenuItem[] = [
    { label: `JSON (${t('soon')})`, onClick: () => {}, disabled: true },
    { label: `CSV (${t('soon')})`, onClick: () => {}, disabled: true },
    {
      label: "PDF",
      onClick: () => {
        if (analysis && !exportLoading) {
          handleExportAnalysis();
        }
      }
    }
  ];

  const handleExportAnalysis = () => {
    console.log('i18n.language', i18n.language);
    exportAnalysis({
      id: Number(analysisId),
      language: i18n.language
    });
  };

  const renderHeader = () => {
    return (
      <AnalysisSubHead
        title={t('analysisResults')}
        subTitle={t('analysisResultText')}
        btnText={t('exportResults')}
        btnIcon={<DisketteIcon />}
        btnLoading={exportLoading}
        disabledBtn={!analysis}
        menus={analysisSubHeadMenus}
        btnWidth={309}
      />
    );
  };

  React.useEffect(() => {
    console.log('current analysis in analysis results', currentAnalysis);
    if (analysis && !currentAnalysis) {
      dispatch(setCurrentAnalysis(analysis));
    }
  }, [analysis, currentAnalysis, dispatch]);

  if (analysis && !isValidAnalysis(analysis.status.code)) {
    return (
      <AnalysisResultsWrapper>
        <CustomAlertError
          titleSx={{ fontSize: "16px" }}
          bodySx={{ fontSize: "14px" }}
          title="Analyse non disponible"
          titleWithPrefix={false}
          body="Cette analyse a été annulée ou echouée"
          opened={true}
          closable={false}
          severity="warning"
        />
      </AnalysisResultsWrapper>
    );
  }

  if (error) {
    return (
      <AnalysisResultsWrapper>
        {renderHeader()}
        <CustomError error={GLOBAL_ERROR_MSG_GET_DATA} />
      </AnalysisResultsWrapper>
    );
  }

  if (!analysis && !loading) {
    return (
      <AnalysisResultsWrapper>
        {renderHeader()}
        <Alert severity="info">Aucune donnée disponible</Alert>
      </AnalysisResultsWrapper>
    );
  }

  const analysisResult = analysis?.result;

  const analysisResultsTabsData: CustomTabsData[] = [
    {
      label: t('accountOverview'),
      value: "account-overview",
      Content: () => <AccountOverview data={analysisResult} loading={loading} />
    },
    { label: "Cashflow", value: "cashflow", Content: () => <Cashflow data={analysisResult} /> },
    { label: t('income'), value: "income", Content: () => <Income data={analysisResult} /> },
    { label: t('spend'), value: "expenses", Content: () => <Expenses data={analysisResult} /> },
    { label: t('behaviour'), value: "behavior", Content: () => <Behavior data={analysisResult} /> },
    {
      label: t('trends'),
      value: "trends",
      Content: () => <Trends data={analysisResult} />
    }
  ];

  return (
    <AnalysisResultsWrapper>
      {renderHeader()}
      {activeTab === analysisResultsTabsData[0].value && (
        <AnalysisInfo analysis={analysis} loading={loading} />
      )}
      <CustomTabs
        data={analysisResultsTabsData}
        sx={{ minWidth: "936px", maxWidth: "1298px" }}
        defaultValue={activeTab || analysisResultsTabsData[0].value}
        onClickTab={(value) => {
          navigate(`/analysis/${analysisId}/${value}`);
        }}
      />
    </AnalysisResultsWrapper>
  );
};

export default AnalysisResults;
