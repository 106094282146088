import { buildFullname } from "utils/user";
import { formatDate } from "utils";
import i18n from "services/translation/i18n";

export const IssuerAdapter = (data: any) => {
  return {
    id: data["id"],
    email: data["email"],
    firstname: data["firstname"],
    lastname: data["lastname"],
    phone: data["phone"] || "",
    bank: data["bank"] || {},
    groups: data["groups"],
    role: getRole(data["groups"]),
    fullname: buildFullname(data["firstname"], data["lastname"]),
    profilePicture: data["profilePicture"]
  } as Issuer;
};

export const adaptUserInfo = (data: any) => {
  return {
    id: data["id"],
    email: data["email"],
    firstname: data["firstname"],
    lastname: data["lastname"],
    photo: data["photo"] || "",
    groups: data["groups"],
    role: getRole(data["groups"]),
    permissions: data["permissions"],
    phone: data["phone"] || "",
    bank: data["bank"] || {},
    fullname: buildFullname(data["firstname"], data["lastname"]),
    profilePicture: data["profilePicture"]
  } as UserInfo;
};

export const adaptUser = (data: any) => {
  return {
    id: data["id"],
    email: data["email"],
    firstname: data["firstname"],
    lastname: data["lastname"],
    photo: data["photo"] || "",
    groups: data["groups"],
    role: getRole(data["groups"]),
    permissions: data["permissions"],
    phone: data["phone"] || "",
    bank: data["bank"] || {},
    fullname:
      data["firstname"] && data["lastname"] ? data["firstname"] + " " + data["lastname"] : "",
    status: data["status"] ? data["status"]["code"] : "WAITING",
    profilePicture: data["profilePicture"],
    lastLogin: data["lastLogin"]
  } as User;
};

export const getRole = (roles: String[]) => {
  if (roles.includes("ADMIN")) return "Admin";
  else if (roles.includes("CHARGE_CLIENTELE")) return i18n.t('accountManager');
  else return "";
};
