import { FC, useContext } from "react";
import { isAdmin, isChargeClientele } from "services/User/UserService";

import { Box } from "@mui/material";
import { CustomTabs } from "components/CustomTabs";
import { PersonalInfo } from "./PersonalInfo";
import { UserContext } from "providers/UserProvider";
import { useTranslation } from "react-i18next";

export const MyAccount: FC = () => {
  const { t } = useTranslation();
  
  const { currentUser } = useContext(UserContext);

  const isUserChargeClientele = currentUser?.groups && isChargeClientele(currentUser.groups);
  const isUserAdmin = currentUser?.groups && isAdmin(currentUser.groups);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: isUserAdmin ? `40px` : `50px 40px`
      }}
    >
      {/* for admin */}
      {isUserAdmin && <PersonalInfo />}

      {/* for clientele */}
      {isUserChargeClientele && (
        <CustomTabs
          data={[
            { label: t('profileInfos'), value: "profile-infos", Content: () => <PersonalInfo /> }
          ]}
          defaultValue="profile-infos"
          sx={{ minWidth: "100%" }}
          tabsListSx={{ justifyContent: "flex-start" }}
          tabSx={{ paddingX: "50px", width: "fit-content" }}
          tabPanelSx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        />
      )}
    </Box>
  );
};
