import { SxProps } from "@mui/material";

export const customModalStyles: SxProps = {
  padding: "30px 30px 0px",
  left: "unset",
  top: "0px",
  transform: "none",
  right: 0,
  bottom: 0,
  borderRadius: 0,
  height: "calc(100vh)",
  width: "500px",
  boxSizing: "border-box",
  overflowY: "scroll"
};

export const styles = {
  filename: {
    overflowX: "hidden",
    maxWidth: "290px"
  }
}
