import AuthProvider from "./AuthProvider";
import { FC } from "react";
import PermissionProvider from "./PermissionProvider";
import { ReduxProvider } from "./ReduxProvider";
import SupportTicketProvider from "./SupportMessageProvider";
import UserProvider from "./UserProvider";
import { WebsocketProvider } from "./WebsocketProvider";

interface IProps {
  children: any;
}

export const Provider: FC<IProps> = (props: IProps) => {
  const { children } = props;

  return (
    <ReduxProvider>
      <UserProvider>
        <PermissionProvider>
          <AuthProvider>
            <WebsocketProvider>
              <SupportTicketProvider>
                {children}
              </SupportTicketProvider>
            </WebsocketProvider>
          </AuthProvider>
        </PermissionProvider>
      </UserProvider>
    </ReduxProvider>
  );
};
