import {
  AnalysisInfoCard,
  AnalysisInfoCardData,
  AnalysisInfoCardLoading
} from "components/AnalysisInfoCard";
import { Box, SxProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { baseStyles, breakpoints, flexCenter } from "utils/constants/baseStyles";
import { getAnalysisResultFormattedData, getArrayOfNumber } from "utils";

import { Analysis } from "types/analysis/analysis";
import FileIcon from "../../assets/images/icons/File.svg";
import UserIdIcon from "../../assets/images/icons/UserId.svg";
import UserRoundedIcon from "../../assets/images/icons/UserRounded.svg";
import WalletIcon from "../../assets/images/icons/Wallet.svg";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

const styles: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: "30px",
  flexWrap: "wrap",
  [breakpoints("laptop").min]: {
    flexWrap: "nowrap"
  }
};

export interface AnalysisInfoProps {
  analysis?: Analysis;
  loading?: boolean;
}

interface IconRendererProps {
  src: string;
  bgColor: string;
}

const IconRenderer: FC<IconRendererProps> = ({ src, bgColor }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        ...flexCenter,
        backgroundColor: bgColor,
        width: "100%",
        height: "100%",
        borderRadius: "inherit"
      }}
    >
      <img src={src} alt="" />
    </Box>
  );
};

export const AnalysisInfo: FC<AnalysisInfoProps> = ({ analysis, loading }) => {
  const { t } = useTranslation();
  
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const translateStatementTypeCode = (code: string|undefined) => {
    switch(code) {
      case "MOBILE_MONEY":
        return t('mobileMoneyStatement');
      case "BANK":
        return t('bankStatement');
    }
  };
  
  const formattedData: AnalysisInfoCardData[] = [
    {
      label: t('clientId'),
      value: analysis?.client?.ref || "",
      bgColor: baseStyles.color.green.primary80,
      bgImageTop: baseStyles.color.white30,
      bgImageBottom: baseStyles.color.success.bg,
      Icon: () => <IconRenderer src={UserIdIcon} bgColor={baseStyles.color.green.primary} />
    },
    {
      label: t('clientName'),
      value: analysis ? `${analysis?.client?.firstname ?? ''} ${analysis?.client?.lastname ?? ''}` : "",
      bgColor: baseStyles.color.warning.main80,
      bgImageTop: baseStyles.color.white30,
      bgImageBottom: "#F9B6221A",
      Icon: () => <IconRenderer src={UserRoundedIcon} bgColor={baseStyles.color.warning.main} />
    },
    {
      label: t('natureOfStatement'),
      value: translateStatementTypeCode(analysis?.type?.code) || "",
      bgColor: "#DCF2F9B3",
      bgImageTop: baseStyles.color.white30,
      bgImageBottom: "#47B2D41A",
      Icon: () => <IconRenderer src={FileIcon} bgColor="#2F9DC0" />
    },
    {
      label: t('averageNetEarningsReducedBy40'),
      value: getAnalysisResultFormattedData("money", analysis?.result?.MaxMonthlyRepayment, undefined, currentCurrency),
      bgColor: baseStyles.color.error.bg,
      bgImageTop: baseStyles.color.white30,
      bgImageBottom: baseStyles.color.error.bg,
      Icon: () => <IconRenderer src={WalletIcon} bgColor={baseStyles.color.error.main} />
    }
  ];

  const loadingData = getArrayOfNumber(4);

  return (
    <Box sx={styles}>
      {loading
        ? loadingData.map((i) => <AnalysisInfoCardLoading key={i} />)
        : formattedData.map((data) => <AnalysisInfoCard key={data.label} data={data} />)}
    </Box>
  );
};
