import { Box, IconButton, InputAdornment } from "@mui/material";
import { FC, useState } from "react";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

import { AnalysisFiltersModal } from "./AnalysisFiltersModal";
import { CustomInput } from "components/InputField/CustomInput";
import { FilterIcon } from "assets/icons/FilterIcon";
import { GetManyAnalysisQueryParams } from "store/api";
import { SearchOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export interface AnalysisFiltersProps {
  filters: GetManyAnalysisQueryParams;
  resultsCount: number;
  loading?: boolean;
  onChangeFilters: (name: keyof GetManyAnalysisQueryParams, value: string) => void;
}

export const AnalysisFilters: FC<AnalysisFiltersProps> = ({
  filters,
  loading,
  resultsCount,
  onChangeFilters
}) => {
  const { t } = useTranslation();
  
  const { ref } = filters;

  const { responsibleId, ...resFilters } = filters;

  const isHasFilters = Object.values(resFilters).some((value) => value !== "");

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <CustomInput
        value={ref || ""}
        onchange={(e) => onChangeFilters("ref", e.target.value)}
        type="text"
        placeholder={t('searchAnalysis') + '...'}
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlined style={{ color: baseStyles.color.blue.primary40 }} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" sx={{ position: "relative" }}>
            <Box
              sx={{
                ...flexCenter,
                width: "60px",
                height: "50px",
                position: "absolute",
                left: "-60px",
                borderLeft: `2px solid ${baseStyles.color.blue.primary10}`
              }}
            >
              <IconButton onClick={handleOpenModal}>
                {isHasFilters ? (
                  <FilterIcon strokeOpacity={1} fill={baseStyles.color.blue.primary} />
                ) : (
                  <FilterIcon />
                )}
              </IconButton>
            </Box>
          </InputAdornment>
        }
        fullWidth={true}
        sx={{
          color: baseStyles.color.blue.primary40,
          maxWidth: "612px",
          height: "50px",
          margin: "0px",
          paddingLeft: "15px",
          paddingRight: "0px"
        }}
      />

      <AnalysisFiltersModal
        open={isOpenModal}
        filters={filters}
        loading={loading}
        resultsCount={resultsCount}
        onClose={handleCloseModal}
        onChangeFilters={onChangeFilters}
      />
    </>
  );
};
