import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  InputAdornment,
  Link as MuiLink,
  Paper,
  Typography
} from "@mui/material";
import { ChangeEvent, FC, useContext, useState } from "react";
import { baseStyles, typoCommonStyles } from "utils/constants/baseStyles";

import { AuthContext } from "providers/AuthProvider";
import { CustomAlert } from "components/Alert/CustomAlert";
import { CustomInput } from "components/InputField/CustomInput";
import { LeftPageLogin } from "./LeftPageLogin";
import { LettreIcon24 } from "assets/icons/LetterIcon";
import { LockIcon24 } from "assets/icons/LockIcon";
import { LogoMediumSize } from "components/Logo/LogoMediumSize";
import { ROUTES } from "routes/routes";
import { ShieldIcon } from "components/Icons/SvgIcons";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { Typo14 } from "components/Typography/Typo14";
import { Typo16 } from "components/Typography/Typo16";
import { postLogin } from "services/Login/LoginService";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login: FC = () => {
  const [values, setValues] = useState({
    email: "",
    password: ""
  });
  const [error, setError] = useState({
    incorrectCredential: false,
    serverError: false
  });

  const [loading, setLoading] = useState(false);

  const isInputError = error.incorrectCredential;
  const inputAdornmentStroke = isInputError ? baseStyles.color.error.main : undefined;

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);
  const { t } = useTranslation();

  const onEmailChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, email: e.target.value });
  };

  const onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, password: e.target.value });
  };

  const performLogin = () => {
    setLoading(true);
    setError((state) => ({ serverError: false, incorrectCredential: false }));
    postLogin(values.email, values.password)
      .then((response) => {
        login(response.data.access, response.data.refresh);
        navigate(ROUTES.home);
        console.log("Navigate to home");
      })
      .catch((e) => {
        console.log("error ", e);
        if (e.response?.status === 401 || e.response?.status === 400) {
          setError((state) => ({ ...state, incorrectCredential: true }));
        } else {
          console.log("Erreur serveur");
          setError((state) => ({ ...state, serverError: true }));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid className="Login" container component="main" sx={styles.mainContainer}>
      <CssBaseline />
      <LeftPageLogin />
      <Grid item xs={12} sm={6} md={6} component={Paper} sx={styles.rightPart}>
        <Box sx={styles.rightPartContainer}>
          <Box sx={styles.switchLanguageContainer}>
            <SwitchLanguage></SwitchLanguage>
          </Box>
          <Box sx={styles.logoContainer}>
            <LogoMediumSize />
          </Box>
          <Typography variant="h1" sx={styles.welcome}>
            {t("login.welcome")}
          </Typography>
          <Box sx={styles.welcomeTextAndErrorContainer}>
            <Box sx={styles.welcomeText}>{t("login.info")}</Box>
            {/* Input error */}
            <CustomAlert
              sx={styles.alertStyles}
              title={t("alert.alert") + " : "}
              body={t("alert.incorrectPassword")}
              icon={<ShieldIcon />}
              severity="error"
              closable={true}
              opened={error.incorrectCredential}
              onclose={() => {
                setError((state) => ({ ...state, incorrectCredential: false }));
              }}
            />
            <CustomAlert
              sx={styles.alertStyles}
              title={t("alert.alert") + " : "}
              body={t("alert.serverError")}
              icon={<ShieldIcon />}
              severity="error"
              closable={true}
              opened={error.serverError}
              onclose={() => {
                setError((state) => ({ ...state, serverError: false }));
              }}
            />
          </Box>
          <CustomInput
            sx={styles.inputStyles}
            endAdornment={
              <InputAdornment position="end">
                <LettreIcon24 stroke={inputAdornmentStroke} />
              </InputAdornment>
            }
            placeholder={t("login.email")}
            type="text"
            onchange={onEmailChanged}
            isValid={!isInputError}
          />
          <CustomInput
            sx={styles.inputStyles}
            endAdornment={
              <InputAdornment position="end">
                <LockIcon24 stroke={inputAdornmentStroke} />
              </InputAdornment>
            }
            placeholder={t("login.password")}
            type="password"
            onchange={onPasswordChanged}
            isValid={!isInputError}
          />
          <Box sx={styles.inputBottom}>
            <MuiLink
              sx={styles.forgotPasswordLink}
              component="span"
              onClick={() => navigate(ROUTES.forgetPassword)}
            >
              <Typo14 sx={typoCommonStyles}>{t("login.forgotenPassword")} . </Typo14>
            </MuiLink>
          </Box>
          <Button
            variant="contained"
            disableElevation
            sx={styles.loginButton}
            onClick={loading ? undefined : performLogin}
          >
            <Typo16 weight="medium" sx={styles.loginButtonText}>
              {t("login.connect")}
              {loading && <CircularProgress color="inherit" size={22} sx={styles.loadingStyles} />}
            </Typo16>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
