import { createApi } from "@reduxjs/toolkit/query/react";
import { adaptUser } from "types/user";
import { customFetchBaseQuery } from "./customFetchBaseQuery";

export interface UpdateUserPayload {
  id: number | string;
  profilePicture?: string | null;
  statusCode?: string | null;
}

export interface GetUsersParams {
  bankId?: number | null;
}

export interface InviteBankUsersPayload {
  bankId: number;
  emails: string[];
}

export const userApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    updateUser: builder.mutation<User, UpdateUserPayload>({
      query({ id, ...body }) {
        return { url: `/api/users/${id}`, method: "PUT", body };
      },
      transformResponse: (user: User) => adaptUser(user),
      invalidatesTags: (result, error, id) => (error ? [] : ["Users"])
    }),
    getUsers: builder.query<User[], GetUsersParams>({
      query: (params) => {
        let url = `/api/users`;
        return { url, params };
      },
      providesTags: (result, error, arg) => (error ? [] : ["Users"]),
      transformResponse: (response: User[]) => {
        return response.map((user) => adaptUser(user));
      }
    }),
    deleteUser: builder.mutation<any, number | string>({
      query: (userId) => {
        let url = `api/users/deleteUser/${userId}`;
        return { url, method: "DELETE" };
      },
      invalidatesTags: (result, error, id) => (error ? [] : ["Users"])
    }),
    inviteBankUsers: builder.mutation<any, InviteBankUsersPayload>({
      query: (body) => {
        let url = `api/users/inviteBankUsers`;
        return { url, method: "POST", body };
      },
      invalidatesTags: (result, error, id) => (error ? [] : ["Users"])
    })
  })
});

export const {
  useUpdateUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useDeleteUserMutation,
  useInviteBankUsersMutation
} = userApi;
