import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const AltArrowDownIcon: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.white,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 9L12 15L5 9"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AltArrowDownIcon18: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.white,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M14.25 7.25L9 11.75L3.75 7.25"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
