import { SxProps } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

export const menuItemStyles: SxProps = {
  padding: "0px 30px",
  height: "50px",
  ":hover": {
    backgroundColor: baseStyles.color.blue.primary10
  },
  ".Mui-disabled": {
    color: baseStyles.color.blue.primary60
  }
};
