import { GLOBAL_ERROR_MSG } from "constants/error";

export enum ErrorCode {
    MAIL_ERROR = 'MAIL_ERROR',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS'
}

export const getErrorMessage = (code: string) => {
    switch (code) {
        case ErrorCode.MAIL_ERROR:
            return "Erreur sur l'envoi d'email";
        case ErrorCode.USER_ALREADY_EXISTS:
            return "L'utilisateur que vous essayer d'inviter existe déjà";
        default:
            return GLOBAL_ERROR_MSG;
    }    
}
