export const adapterBank = (data: any) => {
    return {
        id: data['id'],
        name: data['name'],
        address: data['address'],
        phone: data['phone'],
        city: data['city'],
        country: data['country'] ? adapterCountry(data['country']) : {'id': null, 'name': ''}
    } as Bank;
}

export const adapterCountry = (data: any) => {
    return {
        id: data['id'],
        name: data['name'],
        code: data['code']
    } as Country;
}

export const adapterMobileMoneyCompany = (data: any) => {
    return {
        id: data['id'],
        name: data['name'],
        code: data['code'],
        country: data['country'] ? adapterCountry(data['country']) : {'id': null, 'name': ''}
    } as MobileMoneyCompany;
}