import { Analysis, AnalysisStatus } from "types/analysis/analysis";
import { firstTdStyles, lastTdStyles, rowTrStyles, styles, tdStyles, trStyles } from "./styles";

import { AnalysisEntityCode } from "constants/analysis";
import { AnalysisTableLoading } from "./AnalysisTableLoading";
import { CustomError } from "components/CustomError";
import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import dayjs from "dayjs";
import { getAnalysisResultFormattedData } from "utils";
import i18next from "i18next";
import { isValidAnalysis } from "utils/analysis";
import { setCurrentAnalysis } from "store/slices";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export interface AnalysisTableProps {
  data: Analysis[];
  loading?: boolean;
  error?: string;
}

export const AnalysisTable: FC<AnalysisTableProps> = ({ loading, error, data }) => {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickRow = (row: Analysis) => {
    dispatch(setCurrentAnalysis(row));

    if (row.entity?.code === AnalysisEntityCode.FIRST_CENTRAL) {
      navigate(`/analysis/${row.id}/first-central-results`)
    } else {
      navigate(`/analysis/${row.id}/account-overview`);
    }
  };

  const getHeader = () => {
    return (
      <tr
        style={{
          ...trStyles,
          height: "60px",
          backgroundColor: baseStyles.color.blue.primary5,
          color: baseStyles.color.blue.primary60
        }}
      >
        <th
          style={{
            ...styles.header,
            paddingLeft: "0px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px"
          }}
        >
          {t('analysisReference')}
        </th>
        <th style={{ ...styles.header }}>{t('clientId')}</th>
        <th style={styles.header}>{t('firstnameAndLastname')}</th>
        <th style={styles.header}>Date</th>
        <th style={styles.header}>{t('natureOfStatement')}</th>
        <th style={styles.header}>
          {t('repaymentCapacity')}
        </th>
        <th
          style={{
            ...styles.header,
            paddingRight: "0px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            width: "98px"
          }}
        >
          {t('status')}
        </th>
      </tr>
    );
  };

  const getStatusText = (status: AnalysisStatus) => {
    switch (status.code) {
      case "WAITING":
        return t('waiting');
      case "DONE":
        return t('done');
      case "FAILED":
        return t('failed');
      case "CANCELED":
        return t('canceled');
      default:
        return '';
    }
  };

  const getStatus = (status: AnalysisStatus) => {
    const style = styles.badge[status.code as keyof typeof styles.badge];
    return (
      <span
        style={{
          height: "40px",
          padding: "0 15px",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "500",
          width: "fit-content",
          minWidth: "98px",
          ...style
        }}
      >
        {getStatusText(status)}
      </span>
    );
  };

  // i18next.on('languageChanged', (lng) => {
  //   dayjs.locale(lng);
  // });
  const translateStatementTypeCode = (code: string) => {
    switch(code) {
      case "MOBILE_MONEY":
        return t('mobileMoneyStatement');
      case "BANK":
        return t('bankStatement');
    }
  };

  const getRows = (row: Analysis, index: number) => {
    const isValid = isValidAnalysis(row.status.code);
    return (
      <tr
        key={row.ref}
        style={{ ...trStyles, ...rowTrStyles(index) }}
        onClick={() => (isValid ? handleClickRow(row) : undefined)}
      >
        <td style={{ ...tdStyles(isValid), ...firstTdStyles }}>{row.ref}</td>
        <td style={tdStyles(isValid)}>{row.client?.ref}</td>
        <td style={tdStyles(isValid)}>{`${row.client?.lastname ?? ''} ${row.client?.firstname ?? ''}`}</td>
        <td style={tdStyles(isValid)}>
          {row.date ? dayjs(row.date).format("DD MMMM YYYY") : "- -"}
        </td>
        <td style={tdStyles(isValid)}>{translateStatementTypeCode(row.type?.code)}</td>
        <td style={tdStyles(isValid)}>{`${
          row?.result?.MaxMonthlyRepayment
            ? getAnalysisResultFormattedData("money", row.result.MaxMonthlyRepayment, undefined, row.currency?.code)
            : 0
        } / ${t('month')}`}</td>
        <td style={{ ...tdStyles(isValid), ...lastTdStyles }}>{getStatus(row.status)}</td>
      </tr>
    );
  };

  
  const renderContent = () => {
    if (error) {
      return <CustomError error={error} />;
    }

    return (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse"
        }}
      >
        <thead>{getHeader()}</thead>
        <tbody>{loading ? <AnalysisTableLoading /> : data.map((row, i) => getRows(row, i))}</tbody>
      </table>
    );
  };

  return renderContent();
};
