import { SxProps } from "@mui/material";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const styles: SxProps = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "30px"
};

export const titleContainerStyles: SxProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px"
};

export const scrollBtnsContainerStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: "10px"
};

export const scrollBtnStyles: SxProps = {
  ...flexCenter,
  width: "30px",
  height: "30px",
  borderRadius: "10px",
  boxSizing: "border-box",
  color: baseStyles.color.blue.primary,
  backgroundColor: baseStyles.color.blue.primary5
};

export const scrollBtnDisabledStyles: SxProps = {
  color: baseStyles.color.blue.primary20,
  backgroundColor: `${baseStyles.color.blue.primary5} !important`
};
