import { Box } from "@mui/material";
import { FC, useContext } from "react";

import { Typo12 } from "components/Typography/Typo12";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { ClienteleContext } from "providers/ClienteleProvider";
import { flexCenter } from "utils/constants/baseStyles";
import {
  containerActiveStyles,
  containerStyles,
  nameActiveStyles,
  nameContainerStyles,
  nameStyles
} from "./styles";

interface IProps {
  user: User;
  selected?: boolean;
}

export const ClienteleCard: FC<IProps> = (props: IProps) => {
  const { user, selected } = props;

  const { selectedClientele } = useContext(ClienteleContext);

  const handleClienteleSelected = () => {
    selectedClientele.set(user);
  };

  return (
    <Box
      sx={
        selected
          ? { ...containerStyles, ...(containerActiveStyles as any) }
          : { ...containerStyles }
      }
      onClick={handleClienteleSelected}
    >
      <Box sx={{ ...flexCenter }}>
        <UserAvatar size={58} fontSize={22} user={user as any} />
      </Box>
      <Box sx={nameContainerStyles}>
        <Typo12 sx={selected ? { ...nameActiveStyles } : { ...nameStyles }}>
          {user.firstname} <br />
          {user.lastname}
        </Typo12>
      </Box>
    </Box>
  );
};
