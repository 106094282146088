import { Box, IconButton } from "@mui/material";
import React, { FC, PropsWithChildren, useState } from "react";
import {
  scrollBtnDisabledStyles,
  scrollBtnStyles,
  scrollBtnsContainerStyles,
  styles,
  titleContainerStyles
} from "./styles";

import { AltArrowLeftIcon14 } from "assets/icons/AltArrowLeftIcon";
import { AltArrowRightIcon14 } from "assets/icons/AltArrowRightIcon";
import { CLIENTELE_LIST_ID } from "constants/dom";
import { ClienteleList } from "./ClienteleList/ClienteleList";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { useTranslation } from "react-i18next";

interface ScrollBtnProps extends Required<PropsWithChildren> {
  disabled?: boolean;
  onClick: () => void;
}

const ScrollBtn: FC<ScrollBtnProps> = ({ children, disabled, onClick }) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      sx={
        disabled
          ? { ...scrollBtnStyles, ...(scrollBtnDisabledStyles as any) }
          : { ...scrollBtnStyles }
      }
    >
      {children}
    </IconButton>
  );
};

export const ClienteleListStats: FC = () => {
  const [disabledScrollBtns, setDisabledScrollBtns] = useState({ left: true, right: true });
  const { t } = useTranslation();
  
  const handleClickScrollBtnLeft = () => {
    const clienteleListEl = document.getElementById(CLIENTELE_LIST_ID);
    if (clienteleListEl && clienteleListEl.scrollLeft > 0) {
      clienteleListEl.scrollLeft -= 50;
    }
  };

  const handleClickScrollBtnRight = () => {
    const clienteleListEl = document.getElementById(CLIENTELE_LIST_ID);
    if (
      clienteleListEl &&
      clienteleListEl.scrollLeft < clienteleListEl.scrollWidth - clienteleListEl.clientWidth
    ) {
      clienteleListEl.scrollLeft += 50;
    }
  };

  React.useEffect(() => {
    const clienteleListEl = document.getElementById(CLIENTELE_LIST_ID);

    const scrollListener = (event: any) => {
      const element = event.target;

      if (element.scrollLeft <= 0) {
        setDisabledScrollBtns((prev) => ({ ...prev, left: true }));
      } else {
        setDisabledScrollBtns((prev) => ({ ...prev, left: false }));
      }

      if (element.scrollLeft >= element.scrollWidth - element.clientWidth) {
        setDisabledScrollBtns((prev) => ({ ...prev, right: true }));
      } else {
        setDisabledScrollBtns((prev) => ({ ...prev, right: false }));
      }
    };

    if (clienteleListEl) {
      if (clienteleListEl.scrollLeft > 0) {
        setDisabledScrollBtns((prev) => ({ ...prev, left: false }));
      }

      if (clienteleListEl.scrollWidth > clienteleListEl.clientWidth) {
        setDisabledScrollBtns((prev) => ({ ...prev, right: false }));
      }

      clienteleListEl.addEventListener("scroll", scrollListener);
    }

    return () => {
      clienteleListEl?.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <Box sx={styles}>
      <Box sx={titleContainerStyles}>
        <Typo24Bold>{t('accountManagers')}</Typo24Bold>
        <Box sx={scrollBtnsContainerStyles}>
          <ScrollBtn onClick={handleClickScrollBtnLeft} disabled={disabledScrollBtns.left}>
            <AltArrowLeftIcon14 stroke="currentColor" />
          </ScrollBtn>
          <ScrollBtn onClick={handleClickScrollBtnRight} disabled={disabledScrollBtns.right}>
            <AltArrowRightIcon14 stroke="currentColor" />
          </ScrollBtn>
        </Box>
      </Box>
      <ClienteleList />
    </Box>
  );
};
