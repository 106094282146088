import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { CustomMenu, CustomMenuItem } from "components/CustomMenu";
import { FC, MouseEvent, useState } from "react";
import { baseStyles, typoCommonStyles } from "utils/constants/baseStyles";
import { tableCellRowStyles, tableCellStyles, userNameContainerStyles } from "./styles";

import { BankUsersListLoading } from "./BankUsersListLoading";
import { StatusLabel } from "components/Bank/StatusLabel/StatusLabel";
import { ThreePointLoading } from "components/ThreePointLoading";
import { TrashBinIcon18 } from "assets/icons/TrashBinIcon";
import { Typo12 } from "components/Typography/Typo12";
import { Typo14 } from "components/Typography/Typo14";
import { USER_STATUS } from "constants/user";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { customFormatDatetime } from "utils";
import { useTranslation } from "react-i18next";

interface BankUsersListProps {
  users: User[];
  selectedUserId: string | number | null;
  loading?: boolean;
  deleteLoading?: boolean;
  updateLoading?: boolean;
  error?: any;
  onDeleteUser: (userId: number | string) => void;
  onClickStatus: (userId: number | string | null) => void;
  onUpdateStatus: (statusCode: string) => void;
}

export const BankUsersList: FC<BankUsersListProps> = ({
  users,
  selectedUserId,
  loading,
  deleteLoading,
  updateLoading,
  error,
  onDeleteUser,
  onClickStatus,
  onUpdateStatus
}) => {
  const { t } = useTranslation();
  
  const [btnStatusAnchorEl, setBtnStatusAnchorEl] = useState<null | HTMLElement>(null);

  const statusMenuItems: CustomMenuItem[] = USER_STATUS.map(({ code, label }) => ({
    label,
    onClick: () => onUpdateStatus(code)
  }));

  const handleStatusClick = (event: MouseEvent<HTMLButtonElement>, userId: number | string) => {
    onClickStatus(userId);
    setBtnStatusAnchorEl(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setBtnStatusAnchorEl(null);
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[t('nameAndEmail'), t('status'), t('lastActivity')].map((element, index) => (
                <TableCell key={element} sx={{ ...tableCellStyles(index === 0, false) }}>
                  <Typo14 sx={{ ...typoCommonStyles, color: baseStyles.color.blue.primary60 }}>
                    {element}
                  </Typo14>
                </TableCell>
              ))}
              <TableCell sx={{ ...tableCellStyles(false, true) }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <BankUsersListLoading />
            ) : (
              users.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell sx={{ ...tableCellRowStyles(true) }}>
                    <Box sx={userNameContainerStyles}>
                      <UserAvatar size={63} user={user as UserInfo} />
                      <Typo14 weight="medium">
                        {user.fullname ? user.fullname : "--"}
                        <Typo12 weight="medium" color={baseStyles.color.blue.primary60}>
                          {user.email}
                        </Typo12>
                      </Typo14>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ ...tableCellRowStyles() }}>
                    {updateLoading && selectedUserId === user.id ? (
                      <ThreePointLoading />
                    ) : (
                      <>
                        <StatusLabel
                          status={user.status}
                          onClick={(e) => handleStatusClick(e, user.id)}
                        />
                        <CustomMenu
                          items={statusMenuItems}
                          anchorEl={btnStatusAnchorEl}
                          width={158}
                          onClose={handleCloseStatusMenu}
                        />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={{ ...tableCellRowStyles() }}>
                    <Typo14 weight="medium" color={baseStyles.color.blue.primary60}>
                      {customFormatDatetime(user.lastLogin, "DD/MM/YYYY - HH:mm")}
                    </Typo14>
                  </TableCell>
                  <TableCell sx={{ ...tableCellRowStyles(false, true) }}>
                    {deleteLoading && user.id === selectedUserId ? (
                      <ThreePointLoading />
                    ) : (
                      <IconButton size="large" onClick={() => onDeleteUser(user.id)}>
                        <TrashBinIcon18 />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
