import { Box, IconButton, LinearProgress, Stack, linearProgressClasses } from "@mui/material";
import { ReloadIcon, ShieldIcon } from "components/Icons/SvgIcons";

import CloseIcon from "@mui/icons-material/Close";
import { CustomAlert } from "components/Alert/CustomAlert";
import { CustomButton } from "components/Button/CustomButton";
import { Status } from "./NewAnalysisModal";
import { Typo14 } from "components/Typography/Typo14";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

type LoadingAnalysisProps = {
  status: "PENDING" | "DONE" | "FAILED" | "ERROR_FILE";
  progression?: number;
  onRetry?: any;
  onCancel?: any;
  onClose?: any;
  message?: string;
};

const LoadingAnalysis = (props: LoadingAnalysisProps) => {
  const { t } = useTranslation();
  const { status, progression = 0, onRetry, onCancel, onClose, message } = props;

  const getContent = (status: Status) => {
    switch (status) {
      case "PENDING":
        return (
          <>
            <Box
              sx={{
                marginTop: "30%",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                borderRadius: "10px",
                backgroundColor: "#FFF",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.08)"
              }}
            >
              <Typo14 color={baseStyles.color.blue.primary} weight="bold">
                {t('analysisInProgress')} ...
              </Typo14>
              <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                <Box
                  sx={{
                    flex: "1 0 0"
                  }}
                >
                  <LinearProgress
                    sx={{
                      height: 12,
                      borderRadius: "20px",
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: baseStyles.color.success.bg
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: "20px",
                        backgroundColor: baseStyles.color.success.main
                      }
                    }}
                  />
                </Box>
                {/* <Typo14 color={baseStyles.color.green.primary}>{progression} %</Typo14> */}
              </Box>
            </Box>
            {/* </Box> */}
            <CustomButton
              onClick={onCancel}
              color={baseStyles.color.error.main}
              sx={{ marginTop: "auto" }}
            >
              {t('cancelAnalysis')}
            </CustomButton>
          </>
        );
      case "DONE":
        console.log("Done");
        return (
          <CustomAlert
            sx={{
              marginTop: "30%"
            }}
            variant="filled"
            body={t('analysisDoneSuccessfully')}
          ></CustomAlert>
        );
      case "FAILED":
        return (
          <CustomAlert
            sx={{
              marginTop: "30%"
            }}
            variant="filled"
            severity="warning"
            icon={<ShieldIcon />}
            body={t('analysisFailed') + ' (' + message + ')'}
            endOrnement={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={onRetry}
              >
                <ReloadIcon color="#FFFFFFB2" />
                <Typo14 sx={{ marginLeft: "5px" }} color={"#FFFFFFB2"}>
                  {t('retry')} ?
                </Typo14>
              </Box>
            }
          ></CustomAlert>
        );
      case "ERROR_FILE":
        return (
          <>
            <CustomAlert
              sx={{
                marginTop: "30%"
              }}
              variant="filled"
              severity="error"
              body={"Le document chargé n'est pas au bon format"}
            ></CustomAlert>
            <Box marginTop={"30px"}>
              <Typo14>{t('pleaseUseValidFormatAndRetry')}</Typo14>
            </Box>
          </>
        );
      default:
        break;
    }
  };
  return (
    <Stack height="100%" boxSizing={"border-box"} padding={"80px"}>
      {status !== "PENDING" && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            marginTop: "-40px",
            marginRight: "-40px",
            marginLeft: "auto",
            color: baseStyles.color.blue.primary,
            width: "40px",
            height: "40px",
            "&.MuiIconButton-root": {
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)"
            }
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      )}
      {getContent(status)}
    </Stack>
  );
};

export default LoadingAnalysis;
