import "react-datepicker/dist/react-datepicker.css";

import { FC, forwardRef } from "react";
import { IconButton, styled } from "@mui/material";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
  registerLocale
} from "react-datepicker";
import { baseStyles, breakpoints } from "utils/constants/baseStyles";

import { AltArrowLeftIcon24 } from "assets/icons/AltArrowLeftIcon";
import { AltArrowRightIcon24 } from "assets/icons/AltArrowRightIcon";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import fr from "date-fns/locale/fr";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

registerLocale("fr-FR", fr);

const NexPrevBtn: FC<any> = ({ children, ...restProps }) => {
  return (
    <IconButton
      {...restProps}
      sx={{
        backgroundColor: baseStyles.color.blue.primary5,
        width: "35px",
        height: "35px",
        borderRadius: "80px",
        [breakpoints("datepicker").min]: {
          width: "40px",
          height: "40px"
        }
      }}
    >
      {children}
    </IconButton>
  );
};

const ReactDateRangePickerContainer = styled(`div`)`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  & > div.react-datepicker-wrapper {
    position: relative;
    width: 100%;
    max-width: 503px;
  }
`;

const ReactDatePickerContainer = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "popperWidth"
})<Pick<CustomReactDatePickerProps, "popperWidth">>(
  ({ popperWidth = "auto" }) => `
  width: 100%;
  display: flex;
  align-items: center;

  & > div.react-datepicker-wrapper {
    width: 100%;
  }

  & > div.react-datepicker__tab-loop {
    position: absolute;

    & > div.react-datepicker-popper {
      width: ${popperWidth};
      border-radius: 10px;
      background: #f2f3f8;
      padding: 10px;

      ${breakpoints("datepicker").min} {
        padding: 30px;
      }

      & .react-datepicker {
        border: 0;
        display: flex;
        background-color: transparent;
        gap: 10px;

        ${breakpoints("datepicker").min} {
          gap: 30px;
        }

        & > div.react-datepicker__triangle {
          display: none;
        }

        & > div.react-datepicker__month-container {
          max-width: 469px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          background-color: ${baseStyles.color.white};
          border-radius: 10px;
          padding: 10px;

          ${breakpoints("datepicker").min} {
            padding: 20px;
          }

          & * {
            color: ${baseStyles.color.blue.primary};
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.5px;

            ${breakpoints("datepicker").min} {
              font-size: 14px;
            }
          }

          & > div.react-datepicker__header {
            background-color: unset;
            padding: 0;
            border: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > div.react-datepicker__day-names {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2px;

              ${breakpoints("datepicker").min} {
                gap: 10px;
              }

              & > div.react-datepicker__day-name {
                width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
              }
            }
          }

          & > div.react-datepicker__month {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > div.react-datepicker__week {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              gap: 2px;
              ${breakpoints("datepicker").min} {
                gap: 10px;
              }

              & > div.react-datepicker__day {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0;
                width: 28px;
                min-width: 28px;
                height: 28px;

                ${breakpoints("datepicker").min} {
                  margin: 0 8px;
                  width: 30px;
                  min-width: 30px;
                  height: 30px;
                }
              }

              & > div.react-datepicker__day--outside-month {
                color: ${baseStyles.color.blue.primary40};
              }

              & > div.react-datepicker__day--today {
                background-color: unset;
                border: 1px solid ${baseStyles.color.blue.primary};
              }

              & > div.react-datepicker__day--in-range,
              & > div.react-datepicker__day--in-selecting-range,
              & > div.react-datepicker__day--selected,
              & > div.react-datepicker__day--keyboard-selected {
                font-weight: 500;
                border: none;
              }

              & > div.react-datepicker__day--in-range,
              & > div.react-datepicker__day--in-selecting-range {
                background-color: ${baseStyles.color.green.primary80};
              }

              & > div.react-datepicker__day--selected,
              & > div.react-datepicker__day--keyboard-selected,
              & > div.react-datepicker__day--range-start,
              & > div.react-datepicker__day--range-end {
                background-color: ${baseStyles.color.green.primary};
                color: ${baseStyles.color.white};
              }

              & > div:empty {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
`
);

const CustomInputContainer = styled(`div`)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  padding: 0;
  padding-left: 15px;
  border: 2px solid ${baseStyles.color.blue.primary10};
  background-color: ${baseStyles.color.blue.primary3};

  & * {
    color: ${baseStyles.color.blue.primary};
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  & > svg {
    width: 24px;
    min-width: 24px;
    margin-right: 15px;
  }
`;

const ValuePrefix = styled("span")`
  display: flex;
  min-width: fit-content;
  align-items: center;
`;

const CustomInputStyled = styled(`input`)`
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  padding-left: 7px;
  background-color: transparent;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${baseStyles.color.blue.primary40};
  }
`;

const CustomHeaderStyled = styled(`div`)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  & > div.react-datepicker__current-month {
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 14px !important;

    ${breakpoints("datepicker").min} {
      font-size: 16px !important;
    }
  }
`;

interface CustomReactDatePickerProps
  extends Pick<
    ReactDatePickerProps,
    | "locale"
    | "dateFormat"
    | "placeholderText"
    | "value"
    | "startDate"
    | "endDate"
    | "monthsShown"
    | "formatWeekDay"
    | "renderCustomHeader"
    | "className"
  > {
  selectsRange?: boolean;
  valuePrefix?: string;
  startDatePrefix?: string;
  endDatePrefix?: string;
  withIcon?: boolean;
  popperWidth?: string;
  onChange?: (date: Date | null) => void;
  onChangeStartDate?: (date: Date | null) => void;
  onChangeEndDate?: (date: Date | null) => void;
}

interface CustomInputProps
  extends Pick<CustomReactDatePickerProps, "value" | "valuePrefix" | "withIcon"> {
  placeholder?: string;
  className?: string;
  onClick?: () => void;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, valuePrefix, placeholder, withIcon, onClick }, ref) => {
    return (
      <CustomInputContainer>
        {withIcon && <CalendarIcon />}
        {valuePrefix && <ValuePrefix>{valuePrefix}</ValuePrefix>}
        <CustomInputStyled
          type="text"
          ref={ref}
          onClick={onClick}
          value={value}
          placeholder={placeholder}
        />
      </CustomInputContainer>
    );
  }
);

const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  monthDate,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  increaseMonth
}) => {
  return (
    <CustomHeaderStyled>
      <NexPrevBtn onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <AltArrowLeftIcon24 />
      </NexPrevBtn>
      <div className="react-datepicker__current-month">
        {monthDate.toLocaleString(i18next.language, {
          month: "long",
          year: "numeric"
        })}
      </div>

      <NexPrevBtn onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <AltArrowRightIcon24 />
      </NexPrevBtn>
    </CustomHeaderStyled>
  );
};

const getLanguageWithCountry = (lng: string) => {
  switch (lng) {
    case "fr":
      return "fr-FR";
    case "en":
      return "en-US";
  }
}

export const CustomReactDatePicker: FC<CustomReactDatePickerProps> = ({
  locale = getLanguageWithCountry(i18next.language),
  dateFormat = "DD/MM/YYYY",
  placeholderText = "DD/MM/AAAA",
  startDate,
  endDate,
  startDatePrefix,
  endDatePrefix,
  monthsShown = 1,
  className,
  selectsRange,
  valuePrefix,
  withIcon = true,
  popperWidth,
  onChange,
  onChangeStartDate,
  onChangeEndDate,
  formatWeekDay = (day) => day.substring(0, 3),
  ...props
}) => {
  const commonProps: CustomReactDatePickerProps = {
    locale,
    dateFormat,
    placeholderText,
    monthsShown,
    startDate,
    endDate,
    formatWeekDay,
    renderCustomHeader: (props) => <CustomHeader {...props} />
  };

  const renderCustomInput = (valuePrefix?: string) => {
    return (
      <CustomInput withIcon={withIcon} valuePrefix={valuePrefix} placeholder={placeholderText} />
    );
  };

  return selectsRange ? (
    <ReactDateRangePickerContainer className={className}>
      <ReactDatePickerContainer popperWidth={popperWidth}>
        <ReactDatePicker
          {...props}
          {...commonProps}
          selected={startDate}
          // selectsStart={true}
          onChange={(date) => (onChangeStartDate ? onChangeStartDate(date as any) : {})}
          customInput={renderCustomInput(startDatePrefix)}
        />
      </ReactDatePickerContainer>
      <ReactDatePickerContainer popperWidth={popperWidth}>
        <ReactDatePicker
          {...props}
          {...commonProps}
          selected={endDate}
          // selectsEnd={true}
          minDate={startDate}
          onChange={(date) => (onChangeEndDate ? onChangeEndDate(date as any) : {})}
          customInput={renderCustomInput(endDatePrefix)}
          popperPlacement="bottom-end"
        />
      </ReactDatePickerContainer>
    </ReactDateRangePickerContainer>
  ) : (
    <ReactDatePickerContainer className={className} popperWidth={popperWidth}>
      <ReactDatePicker
        {...props}
        {...commonProps}
        onChange={(date) => (onChange ? onChange(date as any) : {})}
        customInput={renderCustomInput(valuePrefix)}
      />
    </ReactDatePickerContainer>
  );
};

const StyledCustomReactDateRangePicker = styled(CustomReactDatePicker)`
  & div.react-datepicker__tab-loop {
    & > div.react-datepicker-popper {
      margin-top: 30px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
`;

export const CustomReactDateRangePicker: FC<
  Pick<
    CustomReactDatePickerProps,
    "onChangeStartDate" | "onChangeEndDate" | "startDate" | "endDate" | "className" | "popperWidth"
  >
  > = ({ startDate, endDate, className, popperWidth, onChangeStartDate, onChangeEndDate }) => {
    const { t } = useTranslation();
  return (
    <StyledCustomReactDateRangePicker
      className={className}
      startDate={startDate ? new Date(startDate) : null}
      endDate={endDate ? new Date(endDate) : null}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
      startDatePrefix={t('from') + ': '}
      endDatePrefix={t('to') + ': '}
      dateFormat="dd MMMM yyyy"
      placeholderText={t('placeholderDate')}
      monthsShown={2}
      selectsRange={true}
      popperWidth={popperWidth}
    />
  );
};
