import { Box, Stack } from "@mui/material";
import { ChangeEvent, DragEvent, FC, ReactElement, useEffect, useRef, useState } from "react";

import { UploadIcon } from "components/Icons/SvgIcons";
import { Typo14 } from "components/Typography/Typo14";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { adaptAnalysisFile } from "types/analysis";
import { baseStyles } from "utils/constants/baseStyles";

type DragableFileInputProps = {
  onUploaded?: (file?: AnalysisFile) => any;
  message: ReactElement;
};
const DragableFileInput: FC<DragableFileInputProps> = (props: DragableFileInputProps) => {
  const { onUploaded, message = "" } = props;
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault
    const file = e.target.files && e.target.files[0];
    if (e.target.files && e.target.files[0]) {
      // at least one file has been dropped so do something
      const file = e.target.files[0];
      const analysisFile = await adaptAnalysisFile(file);
      onUploaded && onUploaded(analysisFile);
    }
  };

  // triggers when file is dropped
  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      const file = e.dataTransfer.files[0];
      const analysisFile = await adaptAnalysisFile(file);
      console.log({ analysisFile });
      onUploaded && onUploaded(analysisFile);
    }
  };

  useEffect(() => {
    console.log({ dragActive });
  }, [dragActive]);

  return (
    <Stack
      gap={"20px"}
      alignItems={"center"}
      sx={{
        position: "relative",
        borderRadius: "10px",
        padding: "40px 30px",
        border: "1px dashed ",
        borderColor: dragActive ? "red" : baseStyles.color.blue.primary40
      }}
      onDragEnter={handleDrag}
    >
      <label htmlFor="inputfile">
        <Box
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: baseStyles.color.blue.primary,
            cursor: "pointer"
          }}
        >
          <UploadIcon color="white" />
          <input
            style={{ display: "none" }}
            type="file"
            name="file"
            id="inputfile"
            onChange={handleUploadFile}
          />
        </Box>
      </label>
      <Box>
        <Typo14
          weight="medium"
          sx={{
            textAlign: "center"
          }}
        >
          {message}
        </Typo14>
      </Box>
      {dragActive && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: " blur(2px)",
            backgroundBlendMode: ""
          }}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              color: "white"
            }}
          >
            <Typo24Bold color="white">Drop your file here</Typo24Bold>
          </Box>
        </div>
      )}
    </Stack>
  );
};

export default DragableFileInput;
