import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { FC, useEffect, useState } from "react";
import {
  cashflowCardStyles,
  cashflowTabContentStyles,
  contentContainerSx,
  labelStyles,
  valueStyles
} from "./styles";

import { AnalysisResultsTabContentProps } from "./types";
import { BillListIcon } from "assets/icons/BillListIcon";
import { Box } from "@mui/material";
import { CardTransferIcon } from "assets/icons/CardTransferIcon";
import { PlusMinusIcon } from "assets/icons/PlusMinusIcon";
import { SafeCircleIcon } from "assets/icons/SafeCircleIcon";
import { UserHandsIcon } from "assets/icons/UserHandsIcon";
import { WalletIcon } from "assets/icons/WalletIcon";
import { getAnalysisResultFormattedData } from "utils";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface BehaviorProps extends AnalysisResultsTabContentProps {}

export const Behavior: FC<BehaviorProps> = ({ data }) => {
  const { t } = useTranslation();

  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>("");

  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);

  const formattedData: AnalysisResultCardData[] = [
    {
      label: t("accountSweep"),
      value: getAnalysisResultFormattedData("boolean", data?.accountSweep),
      description: t("accountSweepDescription"),
      Icon: () => <CardTransferIcon />
    },
    {
      label: t("gamblingRate"),
      value: getAnalysisResultFormattedData("percentage", data?.gamblingRate ? data.gamblingRate : 0, true),
      description: t("gamblingRateDescription"),
      Icon: () => <SafeCircleIcon />
    },
    {
      label: t("creditDebit"),
      value: getAnalysisResultFormattedData("self", data?.inflowOutflowRate),
      description: t("creditDebitDescription"),
      Icon: () => <WalletIcon />
    },
    {
      label: t("loanAmount"),
      value: getAnalysisResultFormattedData("money", data?.loanAmount, true, currentCurrency),
      description: t("loanAmountDescription"),
      Icon: () => <BillListIcon />
    },
    {
      label: t("loansCredit"),
      value: getAnalysisResultFormattedData('percentage', data?.loanRepaymentInflowRate ? data.loanRepaymentInflowRate * 100 : 0, true),
      description: t("loansCreditDescription"),
      Icon: () => <BillListIcon />
    },
    {
      label: t("loanInflowRate"),
      value: getAnalysisResultFormattedData("percentage", data?.loanInflowRate, true),
      description: t("loanInflowRateDescription"),
      Icon: () => <PlusMinusIcon />
    },
    {
      label: t("loanRepayments"),
      value: getAnalysisResultFormattedData("money", data?.loanRepayments, true, currentCurrency),
      description: t("loanRepaymentsDescription"),
      Icon: () => <PlusMinusIcon />
    },
    {
      label: t("topIncomingTransferAccount"),
      value: getAnalysisResultFormattedData("self", data?.topIncomingTransferAccount),
      description: t("topIncomingTransferAccountDescription"),
      Icon: () => <UserHandsIcon />
    },
    {
      label: t("topTransferRecipientAccount"),
      value: getAnalysisResultFormattedData("self", data?.topTransferRecipientAccount),
      description: t("topTransferRecipientAccountDescription"),
      Icon: () => <UserHandsIcon />
    }
  ];

  return (
    <Box sx={{ ...cashflowTabContentStyles }}>
      {formattedData.map(({ label, value, description, Icon }) => (
        <AnalysisResultCard
          sx={cashflowCardStyles}
          contentContainerSx={contentContainerSx}
          labelSx={labelStyles}
          valueSx={valueStyles}
          key={`${label}-${value}`}
          label={label}
          value={value}
          description={description}
          Icon={Icon}
        />
      ))}
    </Box>
  );
};
