import { FC, createContext, useState } from "react"

interface ClienteleContextProps {
    clienteles: {
        loading: boolean,
        get: () => User[],
        set: (users: User[]) => void
    },
    selectedClientele: {
        get: () => User | undefined,
        set: (user?: User) => void
    }
}


export const ClienteleContext = createContext<ClienteleContextProps>({
    clienteles: {
        loading: true,
        get: () => [],
        set: (users: User[]) => {}
    },
    selectedClientele: {
        get: () => undefined,
        set: (user?: User) => {}
    }
})

interface ClienteleProviderProps {
    children: any
}
export const ClienteleProvider: FC<ClienteleProviderProps> = (props: ClienteleProviderProps) => {
    const { children } = props;
    
    const [clientelesState, setClientelesState] = useState<User[]>([]);
    const [selectedClienteleState, setSelectedClienteleState] = useState<User|undefined>();
    
    const providerValue = {
        clienteles: {
            loading: true,
            get: () => clientelesState,
            set: (users: User[]) => setClientelesState(users)
        },
        selectedClientele: {
            get: () => selectedClienteleState,
            set: (user?: User) => {
                setSelectedClienteleState(user);
            }
        }
    };
    
    return (
        <ClienteleContext.Provider value={providerValue}>
            {children}
        </ClienteleContext.Provider>
    )
}