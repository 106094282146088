import { Box, Grid, Stack } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { FirstCentralAnalysisType, getReport, searchAccounts } from "services/Analysis/FirstCentralAnalysisService";
import { FirstCentralBusinessAccount, FirstCentralConsumerAccount } from "types/analysis/analysis";

import { CustomButton } from "components/Button/CustomButton";
import { CustomInput } from "components/InputField/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import CustomSelect from "components/InputField/CustomSelect";
import LoadingAnalysis from "./LoadingAnalysis";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

type FirstCentralAnalysisModalProps = {
    open: boolean;
    handleClose: any;
};

export type Status = "PENDING" | "DONE" | "FAILED" | "ERROR_FILE";

interface AnalysisFormProps {
    type: FirstCentralAnalysisType;
    bvn: string;
    consumerAccountId?: string;
    businessAccountId?: string;
    enquiryID?: string;
    consumerID?: string;
    subscriberEnquiryEngineID?: string;
};


export const FirstCentralAnalysisModal = (props: FirstCentralAnalysisModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { open, handleClose } = props;
    const [showBgBlur, setShowBgBlur] = useState<Boolean>(false);
    const [status, setStatus] = useState<Status>("PENDING");
    const [analysisMessage, setAnalysisMessage] = useState<string>('');
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loadingLaunchAnalysis, setLoadingLaunchAnalysis] = useState<boolean>(false);
    
    const [analysisForm, setAnalysisForm] = useState<AnalysisFormProps>({
        type: FirstCentralAnalysisType.CONSUMER,
        bvn: ""
    });
    
    const analysisTypes = [
        {
            'value': 'CONSUMER', 
            'element': 'Consumer'
        },
        {
            'value': 'COMMERCIAL',
            'element': 'Commercial'
        }
    ];

    const [consumerAccounts, setConsumerAccounts] = useState<FirstCentralConsumerAccount[]>([]);
    const [businessAccounts, setBusinessAccounts] = useState<FirstCentralBusinessAccount[]>([]);
    
    const close = () => {
        console.log("close");
        setShowBgBlur(false);
    };
    
    const updateAnalysisForm = (key: string, value: any) => {
        setAnalysisForm((oldState) => ({
            ...oldState,
            [key]: value
        }));
    };

    const notifyChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
        const value = e.target.value;
        updateAnalysisForm(key, value);
    };

    const onChangeCustomerAccount = (e: ChangeEvent<HTMLInputElement>) => {
        const account = consumerAccounts.find((a: FirstCentralConsumerAccount) => a.MatchingEngineID === e.target.value);

        if (account) {
            updateAnalysisForm('enquiryID', account.EnquiryID);
        }
        
        updateAnalysisForm('subscriberEnquiryEngineID', e.target.value);
        updateAnalysisForm('consumerID', account?.ConsumerID);
    };

    const launchSearchAccounts = () => {
        setLoadingSearch(true);
        searchAccounts(analysisForm.bvn, analysisForm.type).then(accounts => {
            setAccounts(accounts, analysisForm.type);
            setLoadingSearch(false);
        }).catch(e => {
            console.error(e);
            setLoadingSearch(false);
        });
    };
    
    const setAccounts = (accounts: FirstCentralBusinessAccount[] | FirstCentralConsumerAccount[], type: FirstCentralAnalysisType) => {
        if (type === FirstCentralAnalysisType.CONSUMER) {
            setConsumerAccounts(accounts);
        } else if (type === FirstCentralAnalysisType.COMMERCIAL) {
            setBusinessAccounts(accounts);
        }
    };

    const launchAnalysis = () => {
        console.log('analysis form', analysisForm);
        setLoadingLaunchAnalysis(true);
        getReport(analysisForm).then(response => {
            console.log(response.data);
            const analysisId = response.data['id'];
            console.log(analysisId);
            setLoadingLaunchAnalysis(false);
            navigate(`/analysis/${analysisId}/first-central-results`);
        }).catch(error => {
            setLoadingLaunchAnalysis(false);
            console.error(error);
        })
    };
    
    return (
        <Box sx={{ position: "relative" }}>
            <CustomModal
                title={t('firstCentralAnalysis')}
                open={open}
                handleClose={handleClose}
                closeButton={true}
                footer={
                <Box display={"flex"} gap="30px" marginTop={"20px"} height={'auto'}>
                    <CustomButton sx={{ flexGrow: 1 }} color="secondary" onClick={handleClose}>
                    {t('cancel')}
                    </CustomButton>
                    <CustomButton
                    sx={{ flexGrow: 2 }}
                    color="primary"
                    onClick={launchAnalysis}
                    disabled={loadingLaunchAnalysis}
                    >
                    {t('launchAnalysis')}
                    </CustomButton>
                </Box>
                }
                bgBlur={
                showBgBlur && (
                    <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "20px",
                        boxSizing: "border-box",
                        border: "none",
                        zIndex: 1000
                    }}
                    >
                            <LoadingAnalysis status={status} message={analysisMessage} onRetry={() => { }} onCancel={() => {}} onClose={close} />
                    </Box>
                )
                }
                width={"70vw"}
                height={"80vh"}
            >
                <Stack gap={"30px"}>
                    <Grid container spacing={"10px"}>
                        <Grid item xs={12}>
                            <Stack gap={"20px"}>
                                <Typo14Medium>{t('analysisType')}: </Typo14Medium>
                                <CustomSelect
                                    options={analysisTypes}
                                    onChange={(e: any) => { notifyChange(e, "type")}}
                                    value={analysisForm.type}
                                ></CustomSelect>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack gap={"10px"}>
                                <Typo14Medium>BVN: </Typo14Medium>
                                    <CustomInput
                                        sx={{ marginBottom: 0 }}
                                        fullWidth
                                        isValid={true}
                                        onchange={(e: any) => {
                                            notifyChange(e, "bvn");
                                        }}
                                        type="text"
                                        value={analysisForm.bvn}
                                    />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomButton onClick={launchSearchAccounts} disabled={loadingSearch}>{t('search')}</CustomButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={"10px"}>
                        {
                            consumerAccounts.length > 0 && 
                                <Grid item xs={12}>
                                    <Stack gap={"20px"}>
                                        <Typo14Medium>{t('customerAccounts')}: </Typo14Medium>
                                        <CustomSelect
                                            options={
                                                consumerAccounts.map((account: FirstCentralConsumerAccount) =>
                                                {
                                                    return {
                                                        "value": account.MatchingEngineID,
                                                        "element": `${account.FirstName ?? '-'} (reference: ${account.Reference})`
                                                    }
                                                })
                                            }
                                            onChange={(e: any) => onChangeCustomerAccount(e)}
                                            value={analysisForm.subscriberEnquiryEngineID}
                                        ></CustomSelect>
                                    </Stack>
                                </Grid>
                            
                        }
                        
                    </Grid>
                </Stack>
            </CustomModal>
            </Box>
    );
}

