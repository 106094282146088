import { CSSProperties } from "react";
import { baseStyles } from "utils/constants/baseStyles";

export const trStyles: CSSProperties = {
  fontSize: "14px",
  padding: "0 20px",
  lineHeight: "18px",
  letterSpacing: "0.5px"
};

export const rowTrStyles = (index: number): CSSProperties => ({
  height: "70px",
  color: baseStyles.color.blue.primary,
  backgroundColor: index % 2 === 0 ? baseStyles.color.white : baseStyles.color.blue.primary3
});

export const tdStyles = (isValid: boolean): CSSProperties => ({
  color: baseStyles.color.blue.primary,
  textAlign: "center" as const,
  paddingLeft: "5px",
  paddingRight: "5px",
  cursor: isValid ? "pointer" : "default"
});

export const firstTdStyles: CSSProperties = {
  paddingLeft: "20px",
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px"
};

export const lastTdStyles: CSSProperties = {
  paddingRight: "20px",
  borderTopRightRadius: "10px",
  borderBottomRightRadius: "10px"
};

export const styles = {
  header: {
    fontWeight: "500",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  badge: {
    DONE: {
      backgroundColor: "#0CD97B1A",
      color: "#0CD97B"
    },
    FAILED: {
      backgroundColor: baseStyles.color.warning.bg,
      color: baseStyles.color.warning.color
    },
    CANCELED: {
      backgroundColor: baseStyles.color.error.bg,
      color: baseStyles.color.error.color
    },
    WAITING: {
      backgroundColor: baseStyles.color.info.bg,
      color: baseStyles.color.info.color
    }
  }
};
