import { Box, Button, SxProps } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

type ButtonProps = {
  onClick?: any;
  children: any;
  variant?: any;
  color?: "primary" | "secondary" | string;
  icon?: any;
  disabled?: boolean;
  id?: string;
  sx?: SxProps;
};

export const CustomButton = (props: ButtonProps) => {
  const {
    onClick,
    children,
    color = "primary",
    variant = "contained",
    sx,
    icon,
    disabled = false,
    id
  } = props;

  const styles = {
    backgroundColor:
      variant === "contained" && color === "primary"
        ? baseStyles.color.green.primary
        : variant === "contained" && color === "secondary"
        ? baseStyles.color.gray.primary
        : color,
    color: baseStyles.color.white,
    borderRadius: "10px",
    borderColor:
      variant === "outlined" && color === "primary"
        ? baseStyles.color.green.primary
        : variant === "outlined" && color === "secondary"
        ? baseStyles.color.gray.primary
        : "transparent",
    textTransform: "none",
    paddingTop: 1,
    paddingBottom: 1,
    ":hover": {
      backgroundColor:
        variant === "contained" && color === "primary"
          ? baseStyles.color.green.dark
          : variant === "contained" && color === "secondary"
          ? baseStyles.color.gray.primary
          : color
    }
  };

  return (
    <Button
      variant={variant}
      disabled={disabled}
      disableElevation
      sx={{ ...styles, ...sx }}
      onClick={onClick}
      id={id}
    >
      {icon && (
        <Box paddingRight={"10px"} display={"inline-flex"} alignItems={"center"}>
          {icon}
        </Box>
      )}
      {children}
    </Button>
  );
};
