import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const PopoverArrowIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="12" viewBox="0 0 23 12" fill="none">
      <path d="M11.5 0L22.3253 12H0.674683L11.5 0Z" fill={fill} />
    </svg>
  );
};
