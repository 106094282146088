import { SxProps, lighten } from "@mui/material";
import { CSSProperties } from "react";
import { baseStyles, flexCenter, fontMontserrat } from "utils/constants/baseStyles";

export const mainContainerStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: "20px",
  padding: "20px",
  borderRadius: "20px",
  minHeight: "40px",
  backgroundColor: "white",
  "& *": {
    ...(fontMontserrat as any)
  }
};

export const attachementIconContainerStyles: SxProps = {
  position: "relative"
};

export const inputMessageStyles: SxProps = {
  maxHeight: "100px",
  overflowY: "auto",
  "& .MuiOutlinedInput-root": {
    padding: "0px"
  },
  "& fieldset": {
    border: "0"
  }
};

export const imgPreviewContainerStyles: SxProps = {
  display: "flex",
  flexWrap: "wrap",
  gap: "30px",
  alignItems: "center",
  paddingBottom: "12px"
};

export const filePreviewContainer: SxProps = {
  position: "relative",
  maxWidth: "128px",
  height: "48px",
  borderRadius: "10px"
};

export const imgPreviewStyles: CSSProperties = {
  width: "48px",
  height: "48px",
  borderRadius: "10px"
};

export const otherFilePreviewStyles: SxProps = {
  width: "100%",
  height: "48px",
  backgroundColor: lighten(baseStyles.color.gray.background, 0.5),
  borderRadius: "10px",
  padding: "0px 8px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  color: baseStyles.color.blue.primary,
  "& > span": {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }
};

export const btnRemoveFileStyles: SxProps = {
  position: "absolute",
  padding: 0,
  top: "-12px",
  right: "-12px"
};

export const fileIconContainerStyle: SxProps = {
  ...flexCenter,
  width: "32px",
  minWidth: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: baseStyles.color.white,
  marginRight: "5px"
};
