import {FC, useContext} from 'react';

import { PermissionContext } from 'providers/PermissionProvider';

type Props = {
    to: string;
    children: any;
};

const Restricted: FC<Props> = ({ to, children }) => {
    const {isAllowedTo} = useContext(PermissionContext);

    if(isAllowedTo(to)){
        return <>{children}</>;
    }

    return null;
};

export default Restricted;