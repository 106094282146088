import { SxProps, styled } from "@mui/material";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const styles: SxProps = {
  padding: "30px",
  width: "100%",
  maxWidth: "302px",
  height: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "5px",
  borderRadius: "10px",
  border: `1px solid ${baseStyles.color.blue.primary10}`,
  boxSizing: "border-box"
};

export const iconContainerStyles: SxProps = {
  ...flexCenter,
  width: "40px",
  height: "40px",
  borderRadius: "10px",
  backgroundColor: baseStyles.color.blue.primary10
};

export const headerStyles: SxProps = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%"
};

export const contentContainerStyles: SxProps = {
  ...flexCenter,
  width: "100%",
  height: "100%",
  flexDirection: "column",
  gap: "10px"
};

export const IconInfoContainer = styled(`span`)`
  display: flex;
  cursor: pointer;
`;
