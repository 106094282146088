import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const AirTimeIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.blue.primary }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.80145V11.8676C16 15.7013 16 17.6181 14.8284 18.809C13.6569 20 11.7712 20 8 20C4.22876 20 2.34315 20 1.17157 18.809C0 17.6181 0 15.7013 0 11.8676V7.80145C0 3.96782 0 2.051 1.17157 0.860047C1.54739 0.47801 1.99669 0.218523 2.55813 0.0422751C2.91142 -0.06863 3.28557 0.0893285 3.5 0.392649C3.55391 0.474846 3.60506 0.553039 3.65377 0.627507C4.28829 1.59749 4.50937 1.93544 5.02215 2.25936C5.13195 2.32871 5.24604 2.39078 5.36371 2.44518C5.95547 2.71873 6.63698 2.71873 8 2.71873C9.36302 2.71873 10.0445 2.71873 10.6363 2.44518C10.754 2.39078 10.8681 2.32871 10.9778 2.25936C11.4906 1.93544 11.7117 1.59749 12.3462 0.627519C12.3949 0.553048 12.4461 0.474851 12.5 0.392649C12.6992 0.0883535 13.0639 -0.0735604 13.4105 0.0325624C13.9866 0.208948 14.4456 0.47093 14.8284 0.860047C16 2.051 16 3.96782 16 7.80145ZM5 16.2294C4.58579 16.2294 4.25 16.567 4.25 16.9835C4.25 17.4 4.58579 17.7376 5 17.7376H11C11.4142 17.7376 11.75 17.4 11.75 16.9835C11.75 16.567 11.4142 16.2294 11 16.2294H5Z"
        fill={fill}
      />
    </svg>
  );
};
