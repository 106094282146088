import { Box, Grid } from "@mui/material";
import { UpdateCurrentUserPayload, updateCurrentUser } from "services/User/UserService";
import { editBtnStyles, infoPersoLineStyles, inputLabelStyles, inputStyles } from "./styles";
import { flexRowCenter, typoCommonStyles } from "utils/constants/baseStyles";
import { useContext, useState } from "react";

import { BorderContainer } from "components/BorderContainer/BorderContainer";
import { CustomButton } from "components/Button/CustomButton";
import { CustomInput } from "components/InputField/CustomInput";
import { PenIcon18 } from "assets/icons/PenIcon18";
import { Typo14 } from "components/Typography/Typo14";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { UserContext } from "providers/UserProvider";
import { adaptUserInfo } from "types/user";
import { useTranslation } from "react-i18next";

type UpdatePersonalInfoType = {
  id: string | number;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  setState: any;
  onUpdate: any;
};

export const UpdatePersonalInfo = (props: UpdatePersonalInfoType) => {
  const { t } = useTranslation();
  
  const { id, firstname = "", lastname = "", email = "", phone = "", setState, onUpdate } = props;
  const { setCurrentUser } = useContext(UserContext);
  const [isEdit, setIsEdit] = useState<Boolean>(false);

  const infos = [
    { label: t('lastname'), name: "lastname", value: lastname },
    { label: t('firstname'), name: "firstname", value: firstname },
    { label: "Email", name: "email", value: email },
    { label: t('phone'), name: "phone", value: phone }
  ];

  const update = () => {
    const payload: UpdateCurrentUserPayload = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone
    };

    updateCurrentUser(id, payload)
      .then(
        (updatedCurrentUser) => {
          setCurrentUser((oldState: any) => {
            return adaptUserInfo({
              ...oldState,
              firstname: updatedCurrentUser.firstname,
              lastname: updatedCurrentUser.lastname,
              email: updatedCurrentUser.email,
              phone: updatedCurrentUser.phone
            });
          });
          // show toast confirm that update is successfull
        },
        (error) => {
          console.log(error);
          // show toast error that update failed
        }
      )
      .finally(() => {
        // In every case, remove loading
      });
  };

  return (
    <BorderContainer>
      <>
        <Box
          sx={{
            ...flexRowCenter,
            justifyContent: "space-between",
            gap: "10px"
          }}
        >
          {/* Header */}
          <Typo24Bold sx={typoCommonStyles}>{t('personalInformations')}</Typo24Bold>
          <Box>
            {/* Button modifier */}
            <CustomButton
              color="secondary"
              variant="outlined"
              sx={editBtnStyles}
              onClick={() => {
                if (isEdit) {
                  update();
                  onUpdate();
                }
                setIsEdit((oldstate) => !oldstate);
              }}
            >
              <Typo14Medium>{isEdit ? t('save') : t('change')}</Typo14Medium>
              <PenIcon18 />
            </CustomButton>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={"30px"}>
            {infos.map(({ label, value, name }) => (
              <Grid item xs={6} sx={infoPersoLineStyles} key={name}>
                <Typo14 sx={inputLabelStyles}>{label}</Typo14>
                {isEdit ? (
                  <CustomInput
                    fullWidth
                    value={value}
                    onchange={(e) => {
                      setState((oldState: any) => {
                        return { ...oldState, [name]: e.target.value };
                      });
                    }}
                    sx={inputStyles}
                  />
                ) : (
                  <Typo16 weight="medium" sx={typoCommonStyles}>
                    {value || "--"}
                  </Typo16>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    </BorderContainer>
  );
};
