import { SxProps } from "@mui/material";
import { baseStyles, breakpoints, flexColumn } from "utils/constants/baseStyles";

export const bankStatsContainerStyles: SxProps = {
  ...flexColumn,
  width: "100%",
  overflowY: "auto"
};

export const lineContainerStyles = (hasBorderBottom = false): SxProps => ({
  ...flexColumn,
  gap: "40px",
  borderBottom: hasBorderBottom ? `1px solid ${baseStyles.color.blue.primary10}` : `none`,
  padding: "40px 20px",
  [breakpoints("xl").min]: {
    padding: "40px"
  }
});
