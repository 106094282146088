import { Tab, tabClasses } from "@mui/base/Tab";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { SxProps } from "@mui/material";
import { styled } from "@mui/system";
import { CSSProperties, FC } from "react";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export interface CustomTabsData {
  label: string;
  value?: string | number;
  Content: () => JSX.Element | null;
}

export interface CustomTabsProps {
  data: CustomTabsData[];
  defaultValue?: CustomTabsData["value"];
  sx?: SxProps;
  tabsListSx?: SxProps;
  tabSx?: SxProps;
  tabSpanSx?: CSSProperties;
  tabPanelSx?: SxProps;
  onClickTab?: (value: CustomTabsData["value"]) => void;
}

const StyledTabs = styled(Tabs)`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 50px;
  & * {
    font-family: Montserrat, sans-serif;
  }
`;

const StyledTabsList = styled(TabsList)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding: 10px;
  background-color: ${baseStyles.color.blue.primary5};
  border-radius: 10px;
`;

const StyledTab = styled(Tab)`
  position: relative;
  height: 50px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  color: ${baseStyles.color.blue.primary60};
  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  &.${tabClasses.selected} {
    background-color: ${baseStyles.color.blue.primary};
    color: ${baseStyles.color.white};
  }
`;

const styledTabSpanSx: CSSProperties = {
  ...(flexCenter as any)
};

const StyledTabPanel = styled(TabPanel)``;

export const CustomTabs: FC<CustomTabsProps> = ({
  data,
  defaultValue = 0,
  sx = {},
  tabsListSx = {},
  tabSx = {},
  tabSpanSx = {},
  tabPanelSx = {},
  onClickTab
}) => {
  return (
    <StyledTabs defaultValue={defaultValue} sx={sx}>
      <StyledTabsList sx={tabsListSx}>
        {data.map(({ label, value }, index) => (
          <StyledTab key={`StyledTab-${label}`} sx={tabSx} value={value || index}>
            <span
              style={{ ...styledTabSpanSx, ...tabSpanSx }}
              onClick={() => {
                if (onClickTab) onClickTab(value);
              }}
            >
              {label}
            </span>
          </StyledTab>
        ))}
      </StyledTabsList>
      {data.map(({ label, value, Content }, index) => (
        <StyledTabPanel key={`StyledTabPanel-${label}`} sx={tabPanelSx} value={value || index}>
          <Content />
        </StyledTabPanel>
      ))}
    </StyledTabs>
  );
};
