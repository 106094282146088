import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { FC, useEffect, useState } from "react";
import {
  cashflowCardStyles,
  cashflowTabContentStyles,
  contentContainerSx,
  labelStyles,
  valueStyles
} from "./styles";

import { AnalysisResultsTabContentProps } from "./types";
import { Box } from "@mui/material";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import { CardIcon } from "assets/icons/CardIcon";
import { CardReciveIcon } from "assets/icons/CardReciveIcon";
import { CardSendIcon } from "assets/icons/CardSendIcon";
import { SaleSquareIcon } from "assets/icons/SaleSquareIcon";
import { WalletIcon } from "assets/icons/WalletIcon";
import { getAnalysisResultFormattedData } from "utils";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface CashflowProps extends AnalysisResultsTabContentProps {}

export const Cashflow: FC<CashflowProps> = ({ data }) => {
  const { t } = useTranslation();
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const formattedData: AnalysisResultCardData[] = [
    {
      label: t('firstDay'),
      value: getAnalysisResultFormattedData("date", data?.firstDay),
      description: t('firstDayDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('lastDay'),
      value: getAnalysisResultFormattedData("date", data?.lastDay),
      description: t('lastDayDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('monthPeriod'),
      value: getAnalysisResultFormattedData("month", data?.monthPeriod),
      description: t('monthPeriodDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('nbMonthActivity'),
      value: getAnalysisResultFormattedData("selfNumber", data?.nb_month_activity),
      description: t('nbMonthActivity'),
      Icon: () => <CardIcon />
    },
    {
      label: t('accountActivity'),
      value: getAnalysisResultFormattedData(
        "percentage",
        data?.accountActivity ? data.accountActivity * 100 : 0
      ),
      description: t('accountActivity'),
      Icon: () => <SaleSquareIcon />
    },
    {
      label: t('initialBalance'),
      value: getAnalysisResultFormattedData("money", data?.initialBalance, undefined, currentCurrency),
      description: t('initialBalance'),
      Icon: () => <WalletIcon />
    },
    {
      label: t('closingBalance'),
      value: getAnalysisResultFormattedData("money", data?.closingBalance, undefined, currentCurrency),
      description: t('closingBalanceDescription'),
      Icon: () => <WalletIcon />
    },
    {
      label: t('averageBalance'),
      value: getAnalysisResultFormattedData("money", data?.averageBalance, undefined, currentCurrency),
      description: t('averageBalanceDescription'),
      Icon: () => <WalletIcon />
    },
    {
      label: t('netCashFlow'),
      value: getAnalysisResultFormattedData("money", data?.netCashFlow, undefined, currentCurrency),
      description: t('netCashFlowDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('totalCreditTurnover'),
      value: getAnalysisResultFormattedData("money", data?.totalCreditTurnover, undefined, currentCurrency),
      description: t('totalCreditTurnoverDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('totalDebitTurnover'),
      value: getAnalysisResultFormattedData("money", data?.totalDebitTurnover, true, currentCurrency),
      description: t('totalDebitTurnoverDescription'),
      Icon: () => <CardSendIcon />
    },
    {
      label: t('averageCredits'),
      value: getAnalysisResultFormattedData("money", data?.averageCredits, undefined, currentCurrency),
      description: t('averageCreditsDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('averageDebits'),
      value: getAnalysisResultFormattedData("money", data?.averageDebits, undefined, currentCurrency),
      description: t('averageDebitsDescription'),
      Icon: () => <CardSendIcon />
    },
    {
      label: t('nbAverageMonthlyCredit'),
      value: getAnalysisResultFormattedData("selfNumber", data?.nbAverageMonthlyCredit),
      description: t('nbAverageMonthlyCreditDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('nbAverageMonthlyDebit'),
      value: getAnalysisResultFormattedData("selfNumber", data?.nbAverageMonthlyDebit),
      description: t('nbAverageMonthlyDebitDescription'),
      Icon: () => <CardSendIcon />
    },
    {
      label: t('nbTotalCreditTurnover'),
      value: getAnalysisResultFormattedData("selfNumber", data?.nbTotalCreditTurnover),
      description: t('nbTotalCreditTurnoverDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('nbTotalDebitTurnover'),
      value: getAnalysisResultFormattedData("selfNumber", data?.nbTotalDebitTurnover),
      description: t('nbTotalDebitTurnoverDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('averageNetEarningsReducedBy40'),
      value: getAnalysisResultFormattedData("money", data?.MaxMonthlyRepayment, undefined, currentCurrency),
      description: t('averageNetEarningsReducedBy40Description'),
      Icon: () => <CardReciveIcon />
    }
  ];

  return (
    <Box sx={{ ...cashflowTabContentStyles }}>
      {formattedData.map(({ label, value, description, Icon }, index) => (
        <AnalysisResultCard
          sx={cashflowCardStyles}
          contentContainerSx={contentContainerSx}
          labelSx={labelStyles}
          valueSx={valueStyles}
          key={`${index}-${label}`}
          label={label}
          value={value}
          description={description}
          Icon={Icon}
        />
      ))}
    </Box>
  );
};
