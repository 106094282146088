import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const AddCircleIcon41: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.white,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
      <circle cx="20.4999" cy="20.5" r="16.6667" stroke={stroke} strokeWidth={strokeWidth} />
      <path
        d="M25.5 20.5L20.5 20.5M20.5 20.5L15.5 20.5M20.5 20.5L20.5 15.5M20.5 20.5L20.5 25.5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
