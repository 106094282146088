import { FC } from "react";

import { Box } from "@mui/material";
import { BankList } from "components/Bank/BankList/BankList";
import { BankStats } from "components/Bank/BankStats/BankStats";
import { GlobalStats } from "./GlobalStats/GlobalStats";
import { homeContainerStyles, listBanksContainerStyles } from "./styles";

const Home: FC = () => {
  return (
    <Box sx={homeContainerStyles}>
      <Box sx={listBanksContainerStyles}>
        <BankList withAddBank={false} />
      </Box>
      <BankStats />
      <GlobalStats />
    </Box>
  );
};

export default Home;
