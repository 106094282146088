import { Box, Skeleton } from "@mui/material";
import { FC } from "react";

import { flexCenter, flexColumn } from "utils/constants/baseStyles";
import { containerStyles, nameContainerStyles } from "./styles";

export const ClienteleCardLoading: FC = () => {
  return (
    <Box sx={{ ...containerStyles }}>
      <Box sx={{ ...flexCenter }}>
        <Skeleton width={58} height={58} variant="circular" />
      </Box>
      <Box
        sx={{ ...nameContainerStyles, ...(flexColumn as any), gap: "5px", alignItems: "center" }}
      >
        <Skeleton width={50} height={13.5} variant="rounded" animation="wave" />
        <Skeleton width={80} height={13.5} variant="rounded" animation="wave" />
      </Box>
    </Box>
  );
};
