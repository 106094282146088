export class Storage {
    public static getItem = (item: string) => {
        return sessionStorage.getItem(item);
    }

    public static setItem = (item: string, value: string) => {
        sessionStorage.setItem(item, value);
    }

    public static clear = () => {
        sessionStorage.clear();
    }
}