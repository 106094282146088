import { FC, useEffect } from "react";

import { Box } from "@mui/material";
import ReactJson from 'react-json-view';
import { styles } from "./styles";
import { useGetAnalysisQuery } from "store/api/analysisApi";
import { useMatch } from "react-router";
import { useTranslation } from "react-i18next";

interface IProps {

};

export const FirstCentralResults: FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
  
    const route = useMatch("analysis/:id/:activeTab");
    const analysisId = route?.params.id;
    
    const { data: analysis, error, isLoading, isFetching } = useGetAnalysisQuery(Number(analysisId));

    useEffect(() => {
        if (analysis) {
            console.log('result', analysis.result);
        }

    }, [analysis]);
    return (
        <Box sx={styles.resultContainer}>
            <ReactJson src={analysis?.result ?? {}} />
        </Box>
    );
};