import { Box, Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import { styles } from "./styles";

export const LeftPageLogin = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs={false} sm={6} md={6} sx={styles.imageBg}>
      <Grid sx={styles.leftPart}>
        <Box sx={styles.platformPresentation}>
          <Box sx={styles.platformPresentationTextBox}>
            {/* Rendre le <br />
            <Box component="span" sx={styles.credit}>
              Crédit
            </Box>
            <br />
            Plus sûrs <br />
            En toute <br />
            Transparence{" "} */}
            <Trans i18nKey={'catchPhrase'}>
              <Box>Rendre le</Box>
              <Box component="span" sx={styles.credit}>Crédit</Box>
              <Box>Plus sûrs</Box>
              <Box>En toute transparence</Box>
            </Trans>
          </Box>
          <Box sx={styles.bottomText}>
            {t("leftLogin.slogan")}
            <br />
            {/* <Box component="span" sx={styles.devise}>
              {t("leftLogin.devise")}
            </Box> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
