import { adapterSupportTicket, adapterSupportTicketMessage } from "types/supportTicket";

import axios from "services/AuthorizedAxiosInstance";

interface SupportTicketListParams {
  issuerId?: number,
  bankId?: number
};

export const getAllSupportTickets = (params?: SupportTicketListParams) => {
  let issuerId: number | undefined;
  let bankId: number | undefined;
  
  if (params) {
    issuerId = params['issuerId'];
    bankId = params['bankId'];
  }
  
  const returnedError = {
    status: 400,
    message: "Erreur bad request"
  };
  const onSuccess = (axiosRespponse: any) => {
    return axiosRespponse.data.map(
      (data: any) => adapterSupportTicket(data)
    );
  };

  // Traitement s'il y a des erreurs
  const onError = (error: any) => {
    console.log(error);
  };
  return new Promise<Array<SupportTicket>>((resolve, reject) => {
    let params: any = {};

    if (issuerId) {
      params["issuerId"] = issuerId;
    }

    if (bankId) {
      params["bankId"] = bankId;
    }

    axios
      .get("api/supportTickets", { params: params })
      .then(
        (data: any) => {
          resolve(onSuccess(data));
        },
        (error) => {
          onError(error);
          returnedError.message = "Bad request";
        }
      )
      .catch((reason: any) => {
        reject(returnedError);
      });
  });
};

export const getAllMessagesBySupportTicketId = (supportTicketId: number | string) => {
  const returnedError = {
    status: 400,
    message: "Erreur bad request"
  };
  const onSuccess = (axiosRespponse: any) => {
    console.log(axiosRespponse.data);
    return axiosRespponse.data.map((data: any) => adapterSupportTicketMessage(data));
  };

  // Traitement s'il y a des erreurs
  const onError = (error: any) => {
    console.log(error);
  };
  return new Promise<Array<SupportTicketMessage>>((resolve, reject) => {
    axios
      .get("api/supportTickets/messages", {
        params: {
          supportTicketId
        }
      })
      .then(
        (data: any) => {
          resolve(onSuccess(data));
        },
        (error) => {
          onError(error);
          returnedError.message = "Bad request";
        }
      )
      .catch((reason: any) => {
        console.log("here in catch");
        console.log(reason);
        reject(returnedError);
      });
  });
};

export const addSupportTicketMessage = (
  supportTicketId: number | string,
  message: string,
  files?: string[]
) => {
  const onSuccess = (axiosResponse: any) => {
    return;
  };

  return new Promise((resolve, reject) => {
    axios
      .post("api/supportTickets/messages", {
        supportTicketId: supportTicketId,
        text: message,
        files: files && files.length > 0 ? files : null
      })
      .then((response: any) => {
        resolve(onSuccess(response));
      });
  });
};

export const addSupportTicket = (data: any) => {
  return axios.post("api/supportTickets/", data);
};

export const getUnseenMessagescount = () => {
  return axios.get("api/supportTickets/messages/unseenCount");
}

export const setSeenMessageSupportTicket = (supportTicketId: number | string) => {
  return axios.post(`api/supportTickets/${supportTicketId}/setSeenMessages`);
}