import { SxProps } from "@mui/material";
import { baseStyles, flexColumn, flexRow } from "utils/constants/baseStyles";

export const homeContainerStyles: SxProps = {
  ...flexRow,
  height: "100%"
};

export const listBanksContainerStyles: SxProps = {
  ...flexColumn,
  gap: "30px",
  width: "200px",
  minWidth: "200px",
  borderRight: `1px solid ${baseStyles.color.blue.primary10}`
};
