import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses
} from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import { Typo14 } from "components/Typography/Typo14";
import { baseStyles } from "utils/constants/baseStyles";

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number; textColor?: string; circularColor?: string }
) => {
  const {
    textColor = baseStyles.color.blue.primary,
    circularColor = baseStyles.color.blue.primary10
  } = props;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: circularColor,
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
        size={props.size}
        thickness={props.thickness}
      />
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typo14 weight="medium" color={textColor}>{`${Math.round(props.value)}%`}</Typo14>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
