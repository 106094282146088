import { FC, createContext, useState } from "react"

type UserContextType = {
    currentUser: UserInfo | null;
    setCurrentUser: (user: any) => any
}

export const UserContext = createContext<UserContextType>({
    currentUser: null,
    setCurrentUser: (user: any) => {}
});

interface UserProviderProps {
    children: any
}

export const UserProvider: FC<UserProviderProps> = (props: UserProviderProps) => {
    const [currentUser, setCurrentUser] = useState<UserInfo| null>(null);
    const { children } = props;

    return (
        <UserContext.Provider value={{currentUser: currentUser, setCurrentUser: setCurrentUser}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;


