import { Box, SxProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export interface CenterProps extends Required<PropsWithChildren> {
  height?: string | number;
  sx?: SxProps;
}

export const Center: FC<CenterProps> = ({ children, height = 200, sx = {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        width: "100%",
        height,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
