import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface StatsFilters {
  startDate?: string | null;
  endDate?: string | null;
}

export interface StatsState {
  filters: StatsFilters;
}

const initialState: StatsState = {
  filters: { startDate: null, endDate: null }
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStatsFilters: (state, action: PayloadAction<StatsFilters>) => {
      state.filters = action.payload;
    }
  }
});

export const { setStatsFilters } = statsSlice.actions;

export const selectStatsState = (state: RootState) => state.stats as StatsState;

export const statsReducer = statsSlice.reducer;
