import { Skeleton, SxProps } from "@mui/material";
import { FC } from "react";
import { getArrayOfNumber } from "utils";
import { firstTdStyles, lastTdStyles, rowTrStyles, tdStyles, trStyles } from "./styles";

export interface AnalysisTableLoadingProps {
  nbLine?: number;
}

const BaseSkeleton: FC<{ height?: number; sx?: SxProps }> = ({ height = 18, sx = {} }) => {
  return (
    <Skeleton
      variant="rounded"
      animation="wave"
      height={height}
      width="100%"
      sx={{ margin: "auto", maxWidth: "79px", ...(sx as any) }}
    />
  );
};

export const AnalysisTableLoading: FC<AnalysisTableLoadingProps> = ({ nbLine = 4 }) => {
  return (
    <>
      {getArrayOfNumber(nbLine).map((n) => (
        <tr key={n} style={{ ...trStyles, ...rowTrStyles(n) }}>
          <td style={{ ...tdStyles(false), ...firstTdStyles }}>
            <BaseSkeleton />
          </td>
          <td style={tdStyles(false)}>
            <BaseSkeleton />
          </td>
          <td style={tdStyles(false)}>
            <BaseSkeleton sx={{ maxWidth: "125px" }} />
          </td>
          <td style={tdStyles(false)}>
            <BaseSkeleton sx={{ maxWidth: "105px" }} />
          </td>
          <td style={tdStyles(false)}>
            <BaseSkeleton sx={{ maxWidth: "125px" }} />
          </td>
          <td style={tdStyles(false)}>
            <BaseSkeleton sx={{ maxWidth: "145px" }} />
          </td>
          <td style={{ ...tdStyles(false), ...lastTdStyles }}>
            <BaseSkeleton height={40} sx={{ maxWidth: "98px", borderRadius: "20px" }} />
          </td>
        </tr>
      ))}
    </>
  );
};
