import { BJ, FR, GB, NG } from 'country-flag-icons/react/3x2';
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";

import i18next from "i18next";
import { reactHooksModule } from '@reduxjs/toolkit/dist/query/react';
import { styles } from './styles';
import { useTranslation } from "react-i18next"

const SwitchLanguage = () => {
  const [language, setLanguage] = useState<string>(i18next.language);
  
  const { i18n, t } = useTranslation();
    
  useEffect(() => {
    if (language) {
      console.log('language is ', language);
      const newLanguage = normalizeLanguage(language);

      i18n.changeLanguage(newLanguage);    
    }
  }, [language]);
  
  const normalizeLanguage = (lng: string) => {
    if (lng.indexOf("-") === -1) {
      return lng;
    }

    return lng.split('-')[0];
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
      setLanguage(event.target.value as string);
  };
  
  return (
      <Grid item sx={{display: "flex", justifyContent: "center", color: "white"}}>
        <Select value={language} onChange={handleChangeLanguage} sx={styles.select}>
          <MenuItem value='fr'><BJ width="30px"></BJ></MenuItem>
          <MenuItem value='en'><NG width="30px"></NG></MenuItem>
        </Select>
      </Grid>
  )
}

export default SwitchLanguage;