import { FC, useCallback, useState } from "react";
import { SxProps, TablePagination } from "@mui/material";

import { CustomPagination } from "./CustomPagination";
import { KeyboardArrowDown } from "@mui/icons-material";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

const styles: SxProps = {
  ".MuiTablePagination-toolbar": {
    padding: "0px",
    minHeight: "50px",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "start",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-spacer": {
      display: "none"
    },
    ".MuiInputBase-root": {
      marginX: "10px",
      minWidth: "91px",
      border: "2px solid rgba(1, 29, 123, 0.10)",
      borderRadius: "10px",
      ".MuiTablePagination-select": {
        paddingY: "0px",
        paddingX: "15px",
        backgroundColor: "rgba(1, 29, 123, 0.03)",
        height: "50px",
        display: "flex",
        alignItems: "center",
        color: baseStyles.color.blue.primary,
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "0.5px",
        lineHeight: "18px"
      },
      ".MuiTablePagination-selectIcon": {
        marginRight: "9px",
        color: baseStyles.color.blue.primary40
      }
    },
    ".MuiTablePagination-selectLabel": {
      color: baseStyles.color.blue.primary60
    }
  }
};

export interface CustomTablePaginationProps {
  data: any[];
  defaultRowsPerPage?: number;
  sx?: SxProps;
  updateData: (newData: any[]) => void;
}

export const CustomTablePagination: FC<CustomTablePaginationProps> = ({
  data,
  defaultRowsPerPage = 10,
  sx = {},
  updateData
}) => {
  const { t } = useTranslation();
  
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const numberTotalOfRows = data.length;
  const numberOfPages = Math.ceil(numberTotalOfRows / rowsPerPage);
  const realPage = numberOfPages <= 0 ? 0 : page;

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      const newTableData = data.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
      updateData(newTableData);
      setPage(newPage);
    },
    [data, updateData, rowsPerPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newRowsPerPage = Number(event.target.value);
      const newTableData = data.slice(0, newRowsPerPage);
      setRowsPerPage(newRowsPerPage);
      updateData(newTableData);
      setPage(1);
    },
    [data, updateData]
  );

  return (
    <TablePagination
      sx={{ ...styles, ...sx }}
      component="div"
      count={numberTotalOfRows}
      page={realPage}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={t('perPage')}
      SelectProps={{ IconComponent: KeyboardArrowDown }}
      rowsPerPageOptions={Array.from(new Set([defaultRowsPerPage, 10, 25, 50, 100]))}
      ActionsComponent={() => (
        <CustomPagination
          count={numberOfPages}
          page={realPage}
          onChange={(e, newPage) => handleChangePage(e as any, newPage)}
        />
      )}
    />
  );
};
