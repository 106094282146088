import {
  analysisSummaryStyles,
  itemContainerStyles,
  itemTotalContainerStyles,
  numberContainerStyles,
  numberStyles,
  numberTotalStyles,
  textStyles,
  textTotalStyles
} from "./styles";

import { Box } from "@mui/material";
import CircularProgressWithLabel from "components/Progress/CircularProgressWithLabel/CircularProgressWithLabel";
import { Typo16 } from "components/Typography/Typo16";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

type AnalysisSummaryProps = {
  total: number;
  done: number;
  failed: number;
  canceled: number;
};

const AnalysisSummary = (props: AnalysisSummaryProps) => {
  const { t } = useTranslation();
  
  const { total, done, failed, canceled } = props;

  const getColor = (type: string) => {
    switch (type) {
      case "DONE":
        return baseStyles.color.success.main;

      case "FAILED":
        return baseStyles.color.warning.main;

      case "CANCELED":
        return baseStyles.color.error.main;

      default:
        return baseStyles.color.blue.primary;
    }
  };

  const getLabel = (type: string) => {
    const labels: any = {
      DONE: t('carriedOutAnalyses'),
      FAILED: t('failedAnalyses'),
      CANCELED: t('canceledAnalyses')
    };

    return labels[type];
  };

  const getContent = (type: string, value: number) => {
    const percentageValue = total > 0 ? (value * 100) / total : 0;
    return (
      <Box sx={itemContainerStyles}>
        <CircularProgressWithLabel
          sx={{ color: getColor(type) }}
          thickness={5}
          {...props}
          value={percentageValue}
          size={65}
        />
        <Box sx={numberContainerStyles}>
          <Box sx={numberStyles}>{value}</Box>
          <Typo16 sx={textStyles}>{getLabel(type)}</Typo16>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={analysisSummaryStyles}>
      <Box sx={{ ...itemContainerStyles, ...(itemTotalContainerStyles as any) }}>
        <Box sx={{ ...numberStyles, ...(numberTotalStyles as any) }}>{total}</Box>
        <Typo16 sx={{ ...textStyles, ...(textTotalStyles as any) }}>{t('totalAnalysisCount')}</Typo16>
      </Box>
      {getContent("DONE", done)}
      {getContent("FAILED", failed)}
      {getContent("CANCELED", canceled)}
    </Box>
  );
};

export default AnalysisSummary;
