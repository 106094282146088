import { Box, Grid, IconButton } from "@mui/material";
import { getAllSupportTickets, setSeenMessageSupportTicket } from "services/SupportTickets/SupportTicketsService";
import {
  logoMsgStyles,
  msgListContainerStyles,
  newSupportMessageButtonStyles,
  newSupportMessageContainerStyles,
  penNewSquareIconContainerStyles
} from "./styles";
import { useContext, useEffect, useState } from "react";

import { ListSupport } from "./ListSupport/ListSupport";
import MessagerieContent from "pages/Messagerie/MessagerieContent/MessagerieContent";
import { NewSupportModal } from "./NewSupportModal/NewSupportModal";
import { PenNewSquareIcon } from "assets/icons/PenNewSquareIcon";
import { SupportTicket } from "providers/SupportMessageProvider";
import { Typo14 } from "components/Typography/Typo14";
import { UserContext } from "providers/UserProvider";
import { baseStyles } from "utils/constants/baseStyles";
import logo_message from "assets/images/img_message.png";
import { useTranslation } from "react-i18next";

type SupportsProps = {};

const Supports = (props: SupportsProps) => {
  const { t } = useTranslation();
  
  const {
    setAllSupportTickets,
    currentSupportTicket,
    allSupportTickets,
    loadingAllSupportTickets,
    resetSupportTickets
  } = useContext(SupportTicket);
  const { currentUser } = useContext(UserContext);

  const { reloadUnseenMessagesCount } = useContext(SupportTicket);
  
  const [openSupportModal, setOpenSupportModal] = useState<boolean>(false);

  let supportTicket: any;

  const handleNewSupportMessageClick = (event: any) => {
    setOpenSupportModal(true);
  };

  useEffect(() => {
    let currentUserId: number | undefined = 0;

    if (typeof currentUser?.id === "string") {
      currentUserId = parseInt(currentUser.id);
    } else {
      currentUserId = currentUser?.id;
    }

    getAllSupportTickets({ issuerId: currentUserId })
      .then((data) => {
        setAllSupportTickets(data);
        if (data.length > 0) {
          currentSupportTicket.set(data[0]);
          if (currentSupportTicket.get) {
            supportTicket["id"] = currentSupportTicket.get.id;
            supportTicket["subject"] = currentSupportTicket.get.subject;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [resetSupportTickets]);

  useEffect(() => {
    console.log('current support ticket from use effect', currentSupportTicket.get);
    if (currentSupportTicket.get) {
      setSeenMessageSupportTicket(currentSupportTicket.get.id).then(response => {
        reloadUnseenMessagesCount();
      }).catch(error => {
        console.error(error);
      })
    }
  }, [currentSupportTicket.get]);
  
  return (
    <Grid container sx={{ height: "100%", flexWrap: "nowrap" }}>
      <Box
        sx={{
          width: "20%",
          minWidth: "260px",
          maxWidth: "350px",
          height: "100%",
          minHeight: "100px",
          flexShrink: 0
        }}
      >
        {/* Messenger sidebar */}
        <ListSupport
          loading={loadingAllSupportTickets.get}
          messageList={allSupportTickets}
          currentSupportTicketId={currentSupportTicket.get?.id}
          handleClick={(selectedSupportId: any) => {
            currentSupportTicket.setById(selectedSupportId);
          }}
        />
      </Box>
      <Box sx={msgListContainerStyles}>
        <MessagerieContent
          supportTicketId="1"
          supportTicketDate="2023-09-06"
          supportTicketSubject="Subject"
        />
      </Box>
      <Box sx={newSupportMessageContainerStyles}>
        <img src={logo_message} alt="logo_message" style={logoMsgStyles} />
        <Box onClick={handleNewSupportMessageClick} sx={newSupportMessageButtonStyles}>
          <IconButton sx={penNewSquareIconContainerStyles}>
            <PenNewSquareIcon />
          </IconButton>
          <Typo14 color={baseStyles.color.blue.primary} weight="bold">
            {t('newMessage')}
          </Typo14>
        </Box>
        <NewSupportModal
          open={openSupportModal}
          handleClose={() => setOpenSupportModal(false)}
        ></NewSupportModal>
      </Box>
    </Grid>
  );
};

export default Supports;
