import { SxProps } from "@mui/material";
import {
  baseStyles,
  breakpoints,
  breakpointsBetween,
  fontPoppins
} from "utils/constants/baseStyles";

export const analysisSummaryStyles: SxProps = {
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  overflow: "hidden",
  width: "100%",
  [breakpointsBetween("laptop", "figma")]: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(215px, 1fr))"
  }
};

export const itemContainerStyles: SxProps = {
  display: "flex",
  justifyContent: "center",
  gap: "15px",
  width: "100%",
  maxWidth: "215px",
  height: "128px",
  padding: "15px",
  borderRadius: "20px",
  border: "2px solid",
  backgroundColor: baseStyles.color.white,
  borderColor: baseStyles.color.blue.primary10,
  [breakpoints("laptop").min]: {
    padding: "30px 20px",
    maxWidth: "unset"
  },
  [breakpoints("figma").min]: {
    maxWidth: "309.5px",
    padding: "30px 26px"
  }
};

export const itemTotalContainerStyles: SxProps = {
  backgroundColor: baseStyles.color.blue.primary,
  borderColor: baseStyles.color.blue.primary,
  alignItems: "flex-start",
  flexDirection: "column"
};

export const numberContainerStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: "15px"
};

export const numberStyles: SxProps = {
  ...(fontPoppins as any),
  fontSize: "32px",
  fontWeight: "700",
  lineHeight: "23px",
  letterSpacing: "0.5px",
  color: baseStyles.color.blue.primary
};

export const numberTotalStyles: SxProps = {
  color: baseStyles.color.white,
  fontSize: "40px",
  lineHeight: "28px"
};

export const textStyles: SxProps = {
  color: baseStyles.color.blue.primary60,
  fontWeight: "500",
  lineHeight: "18px",
  letterSpacing: "0.5px"
};

export const textTotalStyles: SxProps = {
  color: baseStyles.color.white
};
