import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  InputAdornment,
  Paper,
  Typography
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { CustomAlert } from "components/Alert/CustomAlert";
import { CustomInput } from "components/InputField/CustomInput";
import { LeftPageLogin } from "./LeftPageLogin";
import { LockIcon24 } from "assets/icons/LockIcon";
import { LogoMediumSize } from "components/Logo/LogoMediumSize";
import { ShieldIcon } from "components/Icons/SvgIcons";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { Typo16 } from "components/Typography/Typo16";
import { baseStyles } from "utils/constants/baseStyles";
import { resetPassword } from "services/User/UserService";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    isInputError: false,
    message: "Les mots de passes ne sont pas identiques"
  });
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isInputError } = error;
  const inputAdornmentStroke = isInputError ? baseStyles.color.error.main : undefined;

  useEffect(() => {
    let code = searchParams.get("passwordReinitialisationCode");
    if (code) {
      setConfirmationCode(code);
    } else {
      console.error("No confirmation code");
    }
  }, []);

  const onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onConfirmationPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationPassword(e.target.value);
  };

  const verifyPasswordMatch = () => {
    return password === confirmationPassword;
  };

  const hideError = () => {
    setError((state) => {
      return { ...state, show: false, isInputError: false };
    });
  };

  const performResetPassword = () => {
    hideError();
    if (!verifyPasswordMatch()) {
      setError((state) => {
        return {
          ...state,
          message: t('passwordsNotTheSame'),
          show: true,
          isInputError: true
        };
      });
      return;
    }
    setLoading(true);
    console.log("Sending password ", password, " and ", confirmationPassword);
    resetPassword({
      newPassword: password,
      code: confirmationCode
    })
      .then(
        (response) => {
          // perform action when no error
          navigate("/login");
        },
        (error) => {
          // perform action when error(promise rejected)
          console.log(error.response);
          if (error.response?.status === 400) {
            setError((state) => {
              return {
                ...state,
                message: t('errorOccuredPleaseVerifyInformations'),
                show: true,
                isInputError: true
              };
            });
          } else if (error.response?.status === 404) {
            setError((state) => {
              return {
                ...state,
                message: t('noUserFound'),
                show: true,
                isInputError: true
              };
            });
          } else if (error.response?.status === 500) {
            setError((state) => {
              return {
                ...state,
                message: t('serverError'),
                show: true
              };
            });
          }
        }
      )
      .catch((e) => {
        // perform action when error on axios
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" sx={styles.mainContainer}>
      <CssBaseline />
      <LeftPageLogin />
      <Grid item xs={12} sm={6} md={6} component={Paper} sx={styles.rightPart}>
        <Box sx={styles.rightPartContainer}>
          <Box sx={styles.switchLanguageContainer}>
            <SwitchLanguage></SwitchLanguage>
          </Box>
          <Box sx={styles.logoContainer}>
            <Link to={"/"}>
              <LogoMediumSize />
            </Link>
          </Box>
          <Typography variant="h1" sx={styles.welcome}>
            {t("resetPassword.createNewPassword")}
          </Typography>
          <Box sx={styles.welcomeTextAndErrorContainer}>
            <Box sx={styles.welcomeText}>{t("resetPassword.enterPassword")}</Box>
            <CustomAlert
              sx={styles.alertStyles}
              title={t("alert.error") + " : "}
              body={error.message}
              icon={<ShieldIcon />}
              severity="error"
              closable={true}
              opened={error.show}
              onclose={() => {
                setError((state) => {
                  return { ...state, show: false, isInputError: false };
                });
              }}
            />
          </Box>
          <CustomInput
            sx={styles.inputStyles}
            endAdornment={
              <InputAdornment position="end">
                <LockIcon24 stroke={inputAdornmentStroke} />
              </InputAdornment>
            }
            type="password"
            placeholder={t("resetPassword.newPassword")}
            onchange={onPasswordChanged}
            isValid={!isInputError}
          />
          <CustomInput
            sx={styles.inputStyles}
            endAdornment={
              <InputAdornment position="end">
                <LockIcon24 stroke={inputAdornmentStroke} />
              </InputAdornment>
            }
            type="password"
            placeholder={t("resetPassword.confirm")}
            onchange={onConfirmationPasswordChanged}
            isValid={!isInputError}
          />
          <Button
            variant="contained"
            disableElevation
            sx={styles.loginButton}
            onClick={loading ? undefined : performResetPassword}
          >
            <Typo16 weight="medium" sx={styles.loginButtonText}>
              {t("resetPassword.modifyPassword")}
              {loading && <CircularProgress color="inherit" size={22} sx={styles.loadingStyles} />}
            </Typo16>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
