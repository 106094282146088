import { Alert, SxProps } from "@mui/material";
import { Center } from "components/Center";
import { GLOBAL_ERROR_MSG } from "constants/error";
import { FC } from "react";

export interface CustomErrorProps {
  error?: string;
  sx?: SxProps;
}

export const CustomError: FC<CustomErrorProps> = ({ error = GLOBAL_ERROR_MSG, sx }) => {
  return (
    <Center height="auto" sx={sx}>
      <Alert severity="error" style={{ width: "100%" }}>
        {error}
      </Alert>
    </Center>
  );
};
