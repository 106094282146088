import { Box, Stack, SxProps } from "@mui/material";

import { MessageIcon } from "components/Icons/SvgIcons";
import { Typo12 } from "components/Typography/Typo12";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { FC } from "react";
import { formatDateTime } from "utils";
import { listSupportContainer, listSupportContainerActive } from "./styles";

type IProps = {
  user: Issuer;
  date: string;
  subject: string;
  active: boolean;
  loading: boolean;
  countMessage?: number;
  onClick?: Function;
};

export const ListSupportItem: FC<IProps> = (props: IProps) => {
  const { active = false, subject, user, onClick = () => {}, date = "", countMessage } = props;

  const styles: SxProps = active
    ? { ...listSupportContainer, ...(listSupportContainerActive as any) }
    : listSupportContainer;

  return (
    <Box
      sx={styles}
      onClick={(e) => {
        onClick();
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
        <Box>
          <UserAvatar user={user} size={48} fontSize={22} />
        </Box>
        <Stack spacing={2} flexGrow={"1"}>
          <Box>
            <Stack spacing={1}>
              <Box>
                <Typo14Medium>{user.fullname}</Typo14Medium>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "fit-content",
                  padding: "10px 15px",
                  borderRadius: "10px",
                  backgroundColor: "rgba(1, 29, 123, 0.10)"
                }}
              >
                <Typo14Medium>{user.bank?.name ? user.bank.name : "no bank"}</Typo14Medium>
              </Box>
            </Stack>
          </Box>
          <>
            <Box>
              <Typo14Medium>{subject}</Typo14Medium>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                <Typo12>{formatDateTime(date)}</Typo12>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                {countMessage && countMessage > 0 && <Typo12>{countMessage}</Typo12>}
                <Box sx={{ width: "18px", height: "18px" }}>
                  <MessageIcon height="18" width="18" />
                </Box>
              </Box>
            </Box>
          </>
        </Stack>
      </Box>
    </Box>
  );
};
