import { Box, Skeleton } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import { iconContainerStyles, infoContainerStyles, styles } from "./AnalysisInfoCard";

export const AnalysisInfoCardLoading = () => {
  return (
    <Box
      sx={{
        ...styles(baseStyles.color.white),
        border: `1px solid ${baseStyles.color.gray.border}`
      }}
    >
      <Box sx={iconContainerStyles}>
        <Skeleton width={60} height={60} animation="pulse" variant="circular" />
      </Box>
      <Box sx={infoContainerStyles}>
        <Skeleton width={150} height={25} animation="wave" />
        <Skeleton width={212} height={20} animation="wave" />
      </Box>
    </Box>
  );
};
