import { baseStyles, fontPoppins } from "utils/constants/baseStyles";

export const styles = {
  alertContainer: {
    borderRadius: "5px",
    display: "flex",
    padding: "20px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch"
  },
  alertContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0"
  },
  alertContentTitle: {
    ...(fontPoppins as any),
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.5px",
    lineHeight: "18px"
  },
  alertContentBody: {
    fontSize: "12px",
    ...(fontPoppins as any),
    fontWeight: "500",
    lineHeight: "15px",
    letterSpacing: "0.5px",
    wordWrap: "break-word"
  },
  "alert-variant-normal-success": {
    border: "1px solid" + baseStyles.color.success.border,
    background: baseStyles.color.success.bg,
    color: baseStyles.color.success.color
  },
  "alert-variant-filled-success": {
    border: "1px solid" + baseStyles.color.success.main,
    background: baseStyles.color.success.main,
    color: baseStyles.color.white
  },
  "alert-variant-outlined-success": {
    border: "1px solid" + baseStyles.color.success.main,
    background: "transparent",
    color: baseStyles.color.success.color
  },
  "alert-variant-normal-info": {
    border: "1px solid" + baseStyles.color.info.border,
    background: baseStyles.color.info.bg,
    color: baseStyles.color.info.color
  },
  "alert-variant-filled-info": {
    border: "1px solid" + baseStyles.color.info.main,
    background: baseStyles.color.info.main,
    color: baseStyles.color.white
  },
  "alert-variant-outlined-info": {
    border: "1px solid" + baseStyles.color.info.main,
    background: "transparent",
    color: baseStyles.color.info.color
  },
  "alert-variant-normal-warning": {
    border: "1px solid" + baseStyles.color.warning.border,
    background: baseStyles.color.warning.bg,
    color: baseStyles.color.warning.color
  },
  "alert-variant-filled-warning": {
    border: "1px solid" + baseStyles.color.warning.main,
    background: baseStyles.color.warning.main,
    color: baseStyles.color.white
  },
  "alert-variant-outlined-warning": {
    border: "1px solid" + baseStyles.color.warning.main,
    background: "transparent",
    color: baseStyles.color.warning.color
  },
  "alert-variant-normal-error": {
    border: "1px solid" + baseStyles.color.error.border,
    background: baseStyles.color.error.bg,
    color: baseStyles.color.error.color
  },
  "alert-variant-filled-error": {
    border: "1px solid" + baseStyles.color.error.main,
    background: baseStyles.color.error.main,
    color: baseStyles.color.white
  },
  "alert-variant-outlined-error": {
    border: "1px solid" + baseStyles.color.error.main,
    background: "transparent",
    color: baseStyles.color.error.color
  }
};
