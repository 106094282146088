import { Box, Button } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { inputsContainer, styles, textStyles } from "./styles";

import { CustomReactDateRangePicker } from "components/InputField/CustomReactDatePicker";
import { DeleteIcon } from "components/Icons/SvgIcons";
import { GetAnalysisSummaryQueryParams } from "store/api";
import { Typo14 } from "components/Typography/Typo14";
import dayjs from "dayjs";
import { setDateFilter } from "store/slices";
import { useAppDispatch } from "store/hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWidth } from "hooks/useWidth";

type AnalysisTimeSelectorProps = {
  filters: GetAnalysisSummaryQueryParams;
  onChangeFilters: (name: keyof GetAnalysisSummaryQueryParams, value: string) => void;
};

const AnalysisTimeSelector: FC<AnalysisTimeSelectorProps> = ({ filters, onChangeFilters }) => {
  const { t } = useTranslation();
  
  const { startDate, endDate } = filters;
  const [startDateState, setStartDateState] = useState<string | undefined>();
  const [endDateState, setEndDateState] = useState<string | undefined>();
  
  const calendarRefContainer = useRef<HTMLDivElement>(null);
  const { width: calendarWidth } = useWidth(calendarRefContainer);
  const dispatch = useAppDispatch();
  
  const handleChangeStartDate = (date: Date | null) => {
    onChangeFilters("startDate", date ? dayjs(date).format("YYYY-MM-DD") : "");
    dispatch(setDateFilter({'startDate': date ? dayjs(date).format("YYYY-MM-DD"): "", 'endDate': endDate}))
  };

  const handleChangeEndDate = (date: Date | null) => {
    onChangeFilters("endDate", date ? dayjs(date).format("YYYY-MM-DD") : "");
    dispatch(setDateFilter({'startDate': startDate, 'endDate': date ? dayjs(date).format("YYYY-MM-DD"): ""}))
  };

  const clearDateInputs = () => {
    console.log('clearing inputs');
    setStartDateState(undefined);
    setEndDateState(undefined);
    handleChangeStartDate(null);
    handleChangeEndDate(null);
    dispatch(setDateFilter({'startDate': '', 'endDate': ''}))
  };

  useEffect(() => {
    setStartDateState(startDate);
    setEndDateState(endDate);
  }, [startDate, endDate]);
  

  return (
    <>
      <Box sx={styles}>
        <Typo14 letterSpacing="0.5px" lineHeight="18px" sx={textStyles}>
          {t('selectTimeRange')} :
        </Typo14>
        <Box sx={inputsContainer} ref={calendarRefContainer}>
          <CustomReactDateRangePicker
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            startDate={startDateState ? new Date(startDateState) : null}
            endDate={endDateState ? new Date(endDateState) : null}
            popperWidth={calendarWidth}
          />
        </Box>
        <Button onClick={clearDateInputs}><DeleteIcon /></Button>
      </Box>
    </>
  );
};

export default AnalysisTimeSelector;
