import { FC, createContext, useContext, useEffect, useRef } from 'react';

import { AuthContext } from './AuthProvider';

// import { WebSocket } from 'ws';

interface WebsocketContextProps {
    subscribe: (channel: string, componentName: string, callback: (data: any) => any) => void,
    unsubscribe: (channel: string, componentName: string) => void
}
const WebsocketContext = createContext<WebsocketContextProps>({
    subscribe: (channel: string, componentname: string, callback: (data: any) => any) => { },
    unsubscribe: (channel: string, componentName: string) => { }
});

interface WebsocketProviderProps {
    children: any;
}

interface ChannelType {
    [key: string]: () => any
}

const WebsocketProvider: FC<WebsocketProviderProps> = (props: WebsocketProviderProps) => {
    const ws = useRef(null as any);
    const { children } = props;

    const channels = useRef({} as any) // maps each channel to the callback
    channels.current = ({} as ChannelType);

    /* called from a component that registers a callback for a channel */
    const subscribe = (channel: string, componentName: string, callback: any) => {
        if (!(channel in channels.current)) {
            channels.current[channel] = {};
        }

        if (componentName in channels.current[channel]) {
            channels.current[channel][componentName].push(callback);
        } else {
            channels.current[channel][componentName] = [callback];
        }
    }
    
    /* remove callback  */
    const unsubscribe = (channel: string, componentName: string) => {
        if (channel in channels.current && componentName in channels.current[channel]) {
            delete channels.current[channel][componentName]
        }
    }

    const { token } = useContext(AuthContext);
    
    const reloadWebsocket = () => {
        if (ws.current) {
            console.log('closing websocket');
            ws.current.close();
        }
        
        /* WS initialization and cleanup */
        console.log('process env', process.env);

        if (!token) {
            return;
        }
        
        console.log('reinit websocket');
        ws.current = new WebSocket(`${ process.env.REACT_APP_SOCKET_URL }?token=${token}`);
        ws.current.onopen = () => { console.log('WS open') }
        ws.current.onclose = () => { console.log('WS close') }
        ws.current.onmessage = (message: any) => {
            const { type, data } = JSON.parse(message.data)['data'];
            
            if (channels.current[type]) {
                for (let componentName in channels.current[type]) {
                    channels.current[type][componentName].forEach((callback: any) => {
                        callback(data);
                    });
                }
            }
        }
    }

    useEffect(() => {
        reloadWebsocket();
    }, [token]);

    /* WS provider dom */
    /* subscribe and unsubscribe are the only required prop for the context */
    return (
        <WebsocketContext.Provider value={{ subscribe, unsubscribe }}>
            {children}
        </WebsocketContext.Provider>
    )
}

export { WebsocketContext, WebsocketProvider }