import { FC, MouseEvent } from "react";
import {
  arrowContainerStyles,
  containerStyles,
  separatorContainerStyles,
  separatorStyles
} from "./styles";

import { AltArrowDownIcon18 } from "assets/icons/AltArrowDownIcon";
import { Box } from "@mui/material";
import { CustomButton } from "components/Button/CustomButton";
import { Typo14 } from "components/Typography/Typo14";
import { baseStyles } from "utils/constants/baseStyles";

interface StatusLabelProps {
  status?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const StatusLabel: FC<StatusLabelProps> = ({ status, onClick }) => {
  const getData = () => {
    switch (status) {
      case "VALIDATED":
        return {
          label: "Validé",
          backgroundColor: baseStyles.color.green.primary,
          separatorBg1: "#48F4A5",
          separatorBg2: "#09AB61"
        };
      case "WAITING":
        return {
          label: "En attente",
          backgroundColor: baseStyles.color.gray.labelBackground,
          separatorBg1: "#99ADF8",
          separatorBg2: "#4B557C"
        };
      default:
        return { label: "N/A", backgroundColor: "", separatorBg1: "", separatorBg2: "" };
    }
  };

  if (!status) {
    return (
      <Typo14 weight="medium" sx={{ color: baseStyles.color.blue.primary60 }}>
        N/A
      </Typo14>
    );
  }

  return (
    <Box
      sx={{
        ...containerStyles,
        backgroundColor: getData().backgroundColor
      }}
      // onClick={onClick}
    >
      <Typo14 weight="medium" color={baseStyles.color.white}>
        {getData().label}
      </Typo14>
      {/* <Box sx={arrowContainerStyles}>
        <Box sx={separatorContainerStyles}>
          <Box sx={{ ...separatorStyles, backgroundColor: getData().separatorBg1 }} />
        </Box>
      </Box> */}
    </Box>
  );
};
