import { FormControl, RadioGroup, SxProps } from "@mui/material";
import { CustomButton } from "components/Button/CustomButton";
import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";

export interface CustomRadioGroupItem {
  label: string;
  value: string;
}

export interface CustomRadioGroupProps {
  items: CustomRadioGroupItem[];
  selectedValue: string;
  onChange: (value: string) => void;
}

const isHoverBtnStyles: SxProps = {
  color: baseStyles.color.white,
  backgroundColor: baseStyles.color.blue.primary
};

const getBtnStyles = (isActive?: boolean): SxProps => ({
  height: "40px",
  padding: "0px 20px",
  fontSize: "14px",
  fontStyle: "normal",
  color: isActive ? isHoverBtnStyles.color : baseStyles.color.blue.primary60,
  backgroundColor: isActive ? isHoverBtnStyles.backgroundColor : baseStyles.color.blue.primary10,
  ":hover": isHoverBtnStyles
});

export const CustomRadioGroup: FC<CustomRadioGroupProps> = ({ items, onChange, selectedValue }) => {
  return (
    <FormControl>
      <RadioGroup
        value={selectedValue}
        sx={{ display: "flex", alignItems: "center", gap: "10px", flexDirection: "row" }}
      >
        {items.map(({ label, value }) => (
          <CustomButton
            key={value}
            sx={{ ...getBtnStyles(selectedValue === value) }}
            onClick={() => onChange(value)}
          >
            {label}
          </CustomButton>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
