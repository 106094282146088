import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../index';

interface TimeSelectorActionProps {
    startDate: string | undefined;
    endDate: string | undefined;
};

export const timeSelectorSlice = createSlice({
    name: 'timeSelector',
    initialState: {
        startDate: '' as string | undefined,
        endDate: '' as string | undefined
    },
    reducers: {
        setDateFilter: (state, action: PayloadAction<TimeSelectorActionProps>) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        }
    }
});

export const { setDateFilter } = timeSelectorSlice.actions;
export const selectTimeSelectorState = (state: RootState) => state.timeSelector;
export const timeSelectorReducer = timeSelectorSlice.reducer;