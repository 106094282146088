import { Box, Grid } from "@mui/material";

import { FC } from "react";
import { ListSupportItem } from "./ListSupportItem/ListSupportItem";
import { ListSupportItemLoading } from "./ListSupportItem/ListSupportItemLoading";
import { Typo16Bold } from "components/Typography/Typo16Bold";
import { getArrayOfNumber } from "utils";
import { useTranslation } from "react-i18next";

type IProps = {
  messageList: Array<SupportTicket> | null;
  currentSupportTicketId: string | number | undefined;
  loading: boolean;
  handleClick: Function;
};

export const ListSupport: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  
  const { messageList, currentSupportTicketId, loading = false, handleClick } = props;

  return (
    <Box sx={{ height: "100%" }}>
      <Grid container direction={"column"} sx={{ height: "100%", flexWrap: "nowrap" }}>
        <Grid item>
          {/* Select bar */}
          <Box
            sx={{
              borderTop: "1px solid rgba(1, 29, 123, 0.10)",
              borderBottom: " 1px solid rgba(1, 29, 123, 0.10)",
              height: "100px",
              padding: "0px 30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typo16Bold>{t('mySupportRequests')}</Typo16Bold>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            flexGrow: "1",
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": { width: 0, height: 0 }
          }}
        >
          {loading ? (
            <Box>
              {getArrayOfNumber(3).map((i) => (
                <ListSupportItemLoading key={i} />
              ))}
            </Box>
          ) : messageList ? (
            messageList.map((support) => (
              <ListSupportItem
                key={support.id}
                user={support.issuer}
                date={support.date}
                subject={support.subject}
                active={support.id === currentSupportTicketId}
                loading={false}
                onClick={() => {
                  handleClick(support.id);
                }}
                countMessage={support.messagesCount}
              />
            ))
          ) : (
            <>No Item</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
