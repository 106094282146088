import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const SupportConversationIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M22 9C22 5.41015 19.0899 2.5 15.5 2.5C13.4171 2.5 11.5631 3.4823 10.3735 5.00721C15.4471 5.20336 19.5 9.37838 19.5 14.5C19.5 14.6103 19.4981 14.7202 19.4944 14.8296L19.8267 14.9185C20.793 15.177 21.677 14.293 21.4185 13.3267L21.2911 12.8506C21.1882 12.4661 21.2501 12.0598 21.4155 11.6977C21.7908 10.876 22 9.96242 22 9Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14.5C18 18.9183 14.4183 22.5 10 22.5C8.76449 22.5 7.5944 22.2199 6.54976 21.7198C6.19071 21.5479 5.78393 21.4876 5.39939 21.5904L4.17335 21.9185C3.20701 22.177 2.32295 21.293 2.58151 20.3267L2.90955 19.1006C3.01245 18.7161 2.95209 18.3093 2.7802 17.9502C2.28008 16.9056 2 15.7355 2 14.5C2 10.0817 5.58172 6.5 10 6.5C14.4183 6.5 18 10.0817 18 14.5ZM6.5 15.5C7.05228 15.5 7.5 15.0523 7.5 14.5C7.5 13.9477 7.05228 13.5 6.5 13.5C5.94772 13.5 5.5 13.9477 5.5 14.5C5.5 15.0523 5.94772 15.5 6.5 15.5ZM10 15.5C10.5523 15.5 11 15.0523 11 14.5C11 13.9477 10.5523 13.5 10 13.5C9.44772 13.5 9 13.9477 9 14.5C9 15.0523 9.44772 15.5 10 15.5ZM13.5 15.5C14.0523 15.5 14.5 15.0523 14.5 14.5C14.5 13.9477 14.0523 13.5 13.5 13.5C12.9477 13.5 12.5 13.9477 12.5 14.5C12.5 15.0523 12.9477 15.5 13.5 15.5Z"
        fill={fill}
      />
    </svg>
  );
};
