import { Box, Skeleton } from "@mui/material";
import { FC } from "react";
import { getArrayOfNumber } from "utils";
import { baseStyles, flexRow } from "utils/constants/baseStyles";

export interface ThreePointLoadingProps {
  size?: number;
}

export const ThreePointLoading: FC<ThreePointLoadingProps> = ({ size = 5 }) => {
  return (
    <Box sx={{ ...flexRow, alignItems: "center", gap: "5px" }}>
      {getArrayOfNumber(3).map((i) => (
        <Skeleton
          key={i}
          width={size}
          height={size}
          variant="circular"
          sx={{ backgroundColor: baseStyles.color.blue.primary }}
        />
      ))}
    </Box>
  );
};
