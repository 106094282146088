import { baseStyles, flexCenter, typoCommonStyles } from "utils/constants/baseStyles";

import Box from "@mui/material/Box";
import { CloseIcon14 } from "assets/icons/CloseIcon";
import { FC } from "react";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { SxProps } from "@mui/material";
import { Typo24Bold } from "components/Typography/Typo24Bold";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: "40px 30px"
};

interface CustomModalProps {
  handleClose: any;
  open?: boolean;
  children: any;
  title?: string;
  width?: any;
  height?: any;
  footer?: any;
  bgBlur?: any;
  disableBackdropClick?: boolean;
  closeButton?: boolean;
  sx?: SxProps;
  sxChildrenContainer?: SxProps;
  headerSx?: SxProps;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    open = false,
    handleClose,
    children,
    title,
    width,
    height,
    footer,
    disableBackdropClick,
    bgBlur,
    closeButton = false,
    sx = {},
    headerSx = {},
    sxChildrenContainer = {}
  } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => (disableBackdropClick ? {} : handleClose())}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            width: width ?? 400,
            height: height ?? '70vh',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            ...sx
          }}
        >
          <Box
            sx={{
              ...flexCenter,
              paddingBottom: "10px",
              marginBottom: "20px",
              borderBottom: "1px solid rgba(1, 29, 123, 0.10)",
              ...(headerSx as any)
            }}
          >
            <Typo24Bold sx={typoCommonStyles}>{title ?? ""}</Typo24Bold>

            {!disableBackdropClick && closeButton && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  marginLeft: "auto",
                  color: baseStyles.color.blue.primary,
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
                  width: "40px",
                  height: "40px"
                }}
              >
                <CloseIcon14 />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              overflow: "hidden",
              overflowY: "auto",
              height: 'auto',
              ...sxChildrenContainer
            }}
          >
            {children}
          </Box>
          {footer}
          {bgBlur}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
