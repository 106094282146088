import { Avatar, Box, Skeleton, Stack } from "@mui/material";

import { FileIcon } from "assets/icons/FileIcon";
import { Typo12 } from "components/Typography/Typo12";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { UserAvatar } from "pages/MyAccount/UserAvatar";
import { formatDateTime } from "utils";
import { baseStyles } from "utils/constants/baseStyles";
import { downloadFile } from "utils/download-file";
import { fileContainerStyles, filesContainerStyles, imageStles } from "./styles";

export type MessageItemProps = {
  sender: Issuer;
  id: string | number;
  date: string;
  content: string;
  files?: SupportTicketMessageFile[] | null;
  loading?: boolean;
};

const MessageItem = (props: MessageItemProps) => {
  const { id, content, sender, loading, date, files } = props;

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        marginBottom: "20px",
        padding: "30px",
        boxShadow: " 0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
        borderRadius: "20px"
      }}
      gap={"1.25rem"}
    >
      <Box sx={{ display: "flex", gap: "0.625rem", alignItems: "center" }}>
        {loading ? (
          <>
            <Skeleton variant="circular">
              <Avatar
                sx={{ width: "48px", height: "48px" }}
                alt={sender.fullname}
                src="/static/images/avatar/1.jpg"
              />
            </Skeleton>
            <Skeleton width="200px"></Skeleton>
          </>
        ) : (
          <>
            <UserAvatar size={48} fontSize={22} user={sender} />
            <Typo14Medium color={baseStyles.color.blue.primary}>{sender.fullname}</Typo14Medium>
            <Box
              sx={{
                display: "flex",
                width: "fit-content",
                padding: "10px 15px",
                borderRadius: "10px",
                backgroundColor: "rgba(1, 29, 123, 0.10)"
              }}
            >
              <Typo14Medium color={baseStyles.color.blue.primary}>
                {sender.bank?.name ? sender.bank.name : "no bank"}
              </Typo14Medium>
            </Box>
            <Box sx={{ marginLeft: "auto" }}>
              <Typo12>{formatDateTime(date)}</Typo12>
            </Box>
          </>
        )}
      </Box>
      <Box key={id} sx={{}}>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton width={"60%"} />
          </>
        ) : (
          <>
            <Typo12>{content}</Typo12>
          </>
        )}
      </Box>
      {files && files.length > 0 && (
        <Box sx={filesContainerStyles}>
          {files.map(({ name, path, type }) => {
            const fileUrl = `${process.env.REACT_APP_BACK_URL}${path}`;
            const isImage =
              (type && type.startsWith("image/")) ||
              name.endsWith(".jpeg") ||
              name.endsWith(".jpg") ||
              name.endsWith(".png");

            return isImage ? (
              <img
                key={path}
                src={fileUrl}
                alt={name}
                style={imageStles}
                onClick={() => downloadFile(fileUrl, name)}
              />
            ) : (
              <Box key={path} sx={fileContainerStyles} onClick={() => downloadFile(fileUrl, name)}>
                <FileIcon />
              </Box>
            );
          })}
        </Box>
      )}
    </Stack>
  );
};

export default MessageItem;
