import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const PenIcon18: FC<SvgIconProps> = ({
  stroke = baseStyles.color.blue.primary,
  strokeOpacity = 0.4,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M3 16.5H15"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M10.416 2.74719L10.9722 2.19107C11.8936 1.26964 13.3875 1.26964 14.3089 2.19107C15.2304 3.11249 15.2304 4.60641 14.3089 5.52783L13.7528 6.08396M10.416 2.74719C10.416 2.74719 10.4856 3.92897 11.5283 4.97171C12.571 6.01444 13.7528 6.08396 13.7528 6.08396M10.416 2.74719L5.30329 7.85994C4.957 8.20624 4.78385 8.37939 4.63494 8.5703C4.45928 8.79551 4.30868 9.03919 4.18581 9.29701C4.08164 9.51558 4.00421 9.74789 3.84934 10.2125L3.19308 12.1812M13.7528 6.08396L8.64006 11.1967C8.29376 11.543 8.12061 11.7162 7.9297 11.8651C7.70449 12.0407 7.46082 12.1913 7.20299 12.3142C6.98442 12.4184 6.75211 12.4958 6.28751 12.6507L4.31875 13.3069M4.31875 13.3069L3.8375 13.4673C3.60887 13.5435 3.35679 13.484 3.18638 13.3136C3.01596 13.1432 2.95646 12.8911 3.03267 12.6625L3.19308 12.1812M4.31875 13.3069L3.19308 12.1812"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
