import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const FileIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.blue.primary }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9648 16.5H7.96484C5.13642 16.5 3.7222 16.5 2.84352 15.6213C1.96484 14.7426 1.96484 13.3284 1.96484 10.5V7.5C1.96484 4.67157 1.96484 3.25736 2.84352 2.37868C3.7222 1.5 5.14386 1.5 7.98719 1.5C8.44169 1.5 8.80589 1.5 9.11235 1.5125C9.10226 1.57244 9.09697 1.6336 9.09677 1.69542L9.08984 3.82123C9.08977 4.64405 9.08972 5.37123 9.16852 5.95737C9.25394 6.59271 9.45007 7.22795 9.96852 7.7464C10.487 8.26485 11.1222 8.46098 11.7576 8.5464C12.3437 8.6252 13.0709 8.62515 13.8937 8.62508L13.9648 8.62508H16.9329C16.9648 9.02578 16.9648 9.51757 16.9648 10.1722V10.5C16.9648 13.3284 16.9648 14.7426 16.0862 15.6213C15.2075 16.5 13.7933 16.5 10.9648 16.5Z"
        fill={fill}
      />
      <path
        d="M14.9786 5.71249L12.0095 3.04031C11.1637 2.27901 10.7407 1.89836 10.2218 1.69922L10.2148 3.75008C10.2148 5.51785 10.2148 6.40173 10.764 6.9509C11.3132 7.50008 12.1971 7.50008 13.9648 7.50008H16.6499C16.378 6.97191 15.8911 6.53373 14.9786 5.71249Z"
        fill={fill}
      />
    </svg>
  );
};
