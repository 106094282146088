import { Box, Stack } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { baseStyles } from "utils/constants/baseStyles";

export const AnalysisResultsWrapper: FC<Required<PropsWithChildren>> = ({ children }) => {
  return (
    <Box sx={baseStyles.container}>
      <Stack gap={"50px"}>{children}</Stack>
    </Box>
  );
};
