import { Typography } from "@mui/material";
import { FC } from "react";
import { baseStyles, fontMontserrat } from "utils/constants/baseStyles";
import { TypoProps, getColor, getWeight } from "./utils";

export const Typo14: FC<TypoProps> = (props) => {
  const {
    children,
    color = baseStyles.color.blue.primary,
    weight = "regular",
    lineHeight = "140%",
    letterSpacing,
    sx
  } = props;

  return (
    <Typography
      sx={{
        ...(fontMontserrat as any),
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: getWeight(weight),
        color: getColor(color),
        lineHeight,
        letterSpacing,
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};
