import dayjs from "dayjs";
import i18n from "services/translation/i18n";
import i18next from "i18next";

export const getArrayOfNumber = (length: number): number[] => {
  return Array.from({ length }, (v, i) => i + 1);
};

export const formatDate = (date: string | Date): string => {
  return dayjs(date).format("DD MMMM YYYY");
};

export const formatDateTime = (date: string | Date): string => {
  return dayjs(date).format("DD MMMM YYYY, HH:mm");
};

export const customFormatDatetime = (date: string | Date | undefined, format: string): string => {
  if (!date) {
    return "";
  }

  return dayjs(date).format(format);
};

export const formatPercentage = (percentage: number): string => {
  return `${Number(percentage.toFixed(2))}%`;
};

export const formatNumber = (value: number): string => {
  let num = value;
  const stringNum = String(num);

  if (stringNum.includes(".")) {
    num = Number(value.toFixed(2));
  }

  return `${new Intl.NumberFormat("en").format(num).replaceAll(",", " ").replaceAll(".", ",")}`;
};

export const formatMoney = (money: number, unit: string = ''): string => {
  return `${formatNumber(money)} ${unit}`;
};

export const formatMoneyFork = (min?: number | null, max?: number | null, unit: string = ''): string => {
  if (typeof min !== "number" || typeof max !== "number") {
    return i18n.t('');
  }
  return `${formatNumber(min)} - ${formatNumber(max)} ${unit}`;
};

export const formatBoolean = (value: boolean): string => {
  return value === true ? i18n.t('yes').toUpperCase() : i18n.t('no').toUpperCase();
};

export const getAnalysisResultFormattedData = (
  type:
    | "date"
    | "money"
    | "percentage"
    | "boolean"
    | "month"
    | "day"
    | "self"
    | "selfNumber"
    | "array",
  data?: string | number | boolean | Date | null | Array<string | number>,
  isDefault0?: boolean,
  unit?: string
): string => {
  if (data === null || data === undefined) {
    console.log('data', data);
    if (isDefault0) return "0";

    if (type === "boolean") return i18n.t('NO');

    return i18n.t('notAvailable');
  }

  switch (type) {
    case "date":
      return typeof data === "string" || data instanceof Date ? formatDate(data) : "";
    case "money":
      return typeof data === "number" ? formatMoney(data, unit) : "";
    case "percentage":
      return typeof data === "number" ? formatPercentage(data) : "";
    case "boolean":
      return typeof data === "boolean" ? formatBoolean(data) : "";
    case "month":
      return typeof data === "number" ? `${data} ${i18n.t('months')}` : "";
    case "day":
      return typeof data === "number" ? `${data} ${i18n.t('days')}` : "";
    case "self":
      return data instanceof Date ? "" : data ? String(data) : "";
    case "selfNumber":
      return typeof data === "number" ? formatNumber(data) : "";
    case "array":
      return Array.isArray(data) ? data.join(", ") : "";
    default:
      return "";
  }
};
