import { Typography } from "@mui/material";
import { fontMontserrat } from "utils/constants/baseStyles";
import { TypoProps, getColor, getWeight } from "./utils";

export const Typo12 = (props: TypoProps) => {
  const { children, color = "#011D7B99", weight = "regular", sx = {} } = props;

  return (
    <Typography
      sx={{
        ...(fontMontserrat as any),
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: getWeight(weight),
        lineHeight: "140%",
        color: getColor(color),
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};
