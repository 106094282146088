import { Box, Skeleton } from "@mui/material";
import { FC } from "react";
import { AnalysisResultCardProps } from "./AnalysisResultCard";
import { contentContainerStyles, headerStyles, iconContainerStyles, styles } from "./styles";

export const AnalysisResultCardLoading: FC<Pick<AnalysisResultCardProps, "sx">> = ({ sx = {} }) => {
  return (
    <Box sx={{ ...styles, ...(sx as any) }}>
      <Box sx={{ ...headerStyles }}>
        <Box sx={{ ...iconContainerStyles, backgroundColor: "transparent" }}>
          <Skeleton width={40} height={40} animation="pulse" variant="rounded" />
        </Box>
        <Skeleton width={18} height={18} animation="pulse" variant="circular" />
      </Box>
      <Box sx={{ ...contentContainerStyles }}>
        <Skeleton width={150} height={15} animation="wave" variant="rounded" />
        <Skeleton width={50} height={22} animation="wave" variant="rounded" />
      </Box>
    </Box>
  );
};
