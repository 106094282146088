import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { Box, SxProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { breakpoints, breakpointsBetween } from "utils/constants/baseStyles";
import { formatMoneyFork, getAnalysisResultFormattedData, getArrayOfNumber } from "utils";

import { AnalysisResultCardLoading } from "components/AnalysisResultCard/AnalysisResultCardLoading";
import { AnalysisResultsTabContentProps } from "./types";
import { BillListIcon } from "assets/icons/BillListIcon";
import { CalendarSearchIcon } from "assets/icons/CalendarSearchIcon";
import { CardTransferIcon } from "assets/icons/CardTransferIcon";
import { RoundDoubleAltArrowDownIcon } from "assets/icons/RoundDoubleAltArrowDownIcon";
import { SafeCircleIcon } from "assets/icons/SafeCircleIcon";
import { SaleSquareIcon } from "assets/icons/SaleSquareIcon";
import { selectAnalysisState } from "store/slices";
import { tabContentStyles } from "./styles";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface AccountOverviewProps extends AnalysisResultsTabContentProps {
  loading?: boolean;
}

const loadingData = getArrayOfNumber(6);

const containerStyles: SxProps = {
  ...tabContentStyles,
  [breakpointsBetween("laptop", "figma")]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(292px, 1fr))"
  }
};

const cardStyles: SxProps = {
  maxWidth: "292px",
  height: "220px",
  [breakpointsBetween("laptop", "figma")]: {
    maxWidth: "unset"
  },
  [breakpoints("figma").min]: {
    maxWidth: "412px"
  }
};

export const AccountOverview: FC<AccountOverviewProps> = ({ loading, data }) => {
  const { t } = useTranslation();
  
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const formattedData: AnalysisResultCardData[] = [
    {
      label: t('accountActivity'),
      value: getAnalysisResultFormattedData(
        "percentage",
        data?.accountActivity ? data.accountActivity * 100 : 0
      ),
      description: t('accountActivityDescription'),
      Icon: () => <SaleSquareIcon />
    },
    {
      label: t('salaryEarner'),
      value: getAnalysisResultFormattedData("boolean", data?.salaryEarner),
      description: t('salaryEarnerDescription'),
      Icon: () => <BillListIcon />
    },
    {
      label: t('gambling'),
      value: getAnalysisResultFormattedData("money", data?.gambling, true, currentCurrency),
      description: t('gamblingDescription'),
      Icon: () => <SafeCircleIcon />
    },
    {
      label: t('accountSweep'),
      value: getAnalysisResultFormattedData("boolean", data?.accountSweep),
      description: t('accountSweepDescription'),
      Icon: () => <CardTransferIcon />
    },
    {
      label: t('mostFrequentBalanceRange'),
      value: formatMoneyFork(
        data?.mostFrequentBalanceRange?.min,
        data?.mostFrequentBalanceRange?.max,
        currentCurrency
      ),
      description: t('mostFrequentBalanceRangeDescription'),
      Icon: () => <RoundDoubleAltArrowDownIcon />
    },
    {
      label: t('NODWBalanceLess5000'),
      value: getAnalysisResultFormattedData("day", data?.NODWBalanceLess5000),
      description: t('NODWBalanceLess5000Description'),
      Icon: () => <CalendarSearchIcon />
    },
    {
      label: t('numberOtherIncomePayments'),
      value: getAnalysisResultFormattedData("selfNumber", data?.numberOtherIncomePayments),
      description: t('numberOtherIncomePaymentsDescription'),
      Icon: () => <CalendarSearchIcon />
    }
  ];

  return (
    <Box sx={containerStyles}>
      {loading
        ? loadingData.map((i) => <AnalysisResultCardLoading key={i} sx={cardStyles} />)
        : formattedData.map(({ label, value, description, Icon }, index) => (
            <AnalysisResultCard
              sx={cardStyles}
              labelSx={{ textAlign: "center" }}
              key={`${index}-${label}`}
              label={label}
              value={value}
              description={description}
              Icon={Icon}
            />
          ))}
    </Box>
  );
};
