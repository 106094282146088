import { AxiosError } from "axios";

export const isUrlHasParams = (url: string): boolean => url.includes("?");

export const addUrlParams = (url: string, key: string, value: string): string => {
  const isHasParams = isUrlHasParams(url);
  return `${url}${isHasParams ? "&" : "?"}${key}=${value}`;
};

export const getAxiosError = (
  error: AxiosError & { message?: string; reason?: AxiosError; status?: number }
): { error?: string; code?: string; status?: number } | null => {
  if (error.response?.data) {
    return error.response.data;
  }

  if (error.reason?.response?.data) {
    return error.reason?.response?.data;
  }

  return null;
};
