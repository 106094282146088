import { SxProps } from "@mui/material";

export type Breakpoint =
  | "sm"
  | "md"
  | "tablet"
  | "lg"
  | "laptop"
  | "xl"
  | "datepicker"
  | "xxl"
  | "figma";

export const baseStyles = {
  color: {
    blue: {
      primary: "#011D7B",
      primary3: "#011D7B08",
      primary5: "#011D7B0D",
      primary10: "#011D7B1A",
      primary20: "#011D7B33",
      primary30: "#011D7B4D",
      primary40: "#011D7B66",
      primary60: "#011D7B99",
      background80: "rgba(1,29,123,0.7)",
      background: "#011D7B",
      border: "#344d96"
    },
    green: {
      primary: "#0CD97B",
      primary10: "#0CD97B1A",
      primary80: "#E6FBE4CC",
      dark: "#15bf71"
    },
    gray: {
      primary: "#011D7B99",
      primary40: "#011D7B66",
      border: "#e6e8f2",
      background: "#e6e9f2",
      labelBackground: "#6777b0"
    },
    white: "#fff",
    white30: "#FFFFFF4D",
    white20: "#FFFFFF33",
    success: {
      main: "#0CD97B",
      border: "#0CD97B33",
      color: "#0CD97B",
      bg: "#0CD97B1A"
    },
    warning: {
      main: "#FFB800",
      border: "#FFB80033",
      color: "#FFB800",
      bg: "#FFB8001A",
      main80: "#FFF6E2CC"
    },
    error: {
      main: "#FF5A60",
      bg: "#FF5A601A",
      color: "#FF5A60",
      border: "#FF5A6033",
      main3: "#FF5A6008",
      main40: "#FF5A6066"
    },
    info: {
      main: "#293F8A",
      border: "#293F8A33",
      color: "#293F8A",
      bg: "#293F8A1A"
    },
    button: {
      primary: {
        bg: {
          filled: "",
          contained: ""
        },
        color: {
          filled: "",
          contained: ""
        },
        border: {
          filled: "",
          contained: ""
        }
      },
      secondary: {
        bg: {
          filled: "",
          contained: ""
        },
        color: {
          filled: "",
          contained: ""
        },
        border: {
          filled: "",
          contained: ""
        }
      },
      success: {
        bg: {
          filled: "",
          contained: ""
        },
        color: {
          filled: "",
          contained: ""
        },
        border: {
          filled: "",
          contained: ""
        }
      }
    }
  },
  container: {
    padding: "50px 40px"
  },
  breakpoints: {
    sm: 600,
    md: 900,
    tablet: 1024,
    lg: 1200,
    laptop: 1366,
    xl: 1536,
    datepicker: 1640,
    xxl: 1687,
    figma: 1728
  }
};

export const breakpoints = (breakpoint: Breakpoint) => {
  return {
    min: `@media (min-width: ${baseStyles.breakpoints[breakpoint]}px)`,
    max: `@media (max-width: ${baseStyles.breakpoints[breakpoint]}px)`
  };
};

export const breakpointsBetween = (min: Breakpoint, max: Breakpoint, maxMinus1 = true) => {
  return `@media (min-width: ${baseStyles.breakpoints[min]}px) and (max-width: ${
    maxMinus1 ? baseStyles.breakpoints[max] - 1 : baseStyles.breakpoints[max]
  }px)`;
};

export const flexRowCenter: SxProps = {
  display: "flex",
  alignItems: "center"
};

export const flexColumn: SxProps = {
  display: "flex",
  flexDirection: "column"
};

export const flexRow: SxProps = {
  display: "flex",
  flexDirection: "row"
};

export const flexCenter: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

export const typoCommonStyles: SxProps = {
  lineHeight: "18px",
  letterSpacing: "0.5px"
};

export const titleCommonStyles: SxProps = {
  ...typoCommonStyles,
  lineHeight: "28px"
};

export const fontMontserrat: SxProps = {
  fontFamily: "Montserrat, sans-serif"
};

export const fontPoppins: SxProps = {
  fontFamily: "Poppins, sans-serif"
};
