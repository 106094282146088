import { adaptFirstCentralConsumerAccount } from "types/analysis";
import axios from "services/AuthorizedAxiosInstance";

export enum FirstCentralAnalysisType {
    CONSUMER = 'CONSUMER',
    COMMERCIAL = 'COMMERCIAL'
}

export const searchAccounts = (bvn: string, type: FirstCentralAnalysisType) => {
    let url = "/api/analyses/firstCentral/searchAccount";
    const params = {
        "bvn": bvn,
        "type": type
    };
    
    return axios.get(url, { params: params }).then(response => {
        return response.data.map((x: any) => adaptFirstCentralConsumerAccount(x))
    });
};

export const getReport = (data: any) => {
    let url = "/api/analyses/firstCentral/report";

    return axios.post(url, data);
};