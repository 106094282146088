import { Box, IconButton } from "@mui/material";
import { useContext, useState } from "react";

import { BankContext } from "providers/BankProvider";
import { BankList } from "components/Bank/BankList/BankList";
import { BankUsersListConnected } from "./BankUsersList/BankUsersListConnected";
import { ChipSelectConnected } from "components/ChipSelect/ChipSelectConnected";
import { ConfirmDialog } from "components/Dialog/ConfirmDialog/ConfirmDialog";
import { EditRounded } from "@mui/icons-material";
import { TrashBinIcon18 } from "assets/icons/TrashBinIcon";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { UpdateBankModal } from "components/Bank/UpdateBankModal/UpdateBankModal";
import { deleteBank } from "services/Bank/BankService";
import { listBanksContainerStyles } from "pages/Home/styles";
import { styles } from "./styles";
import { titleCommonStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

type AccountManagementProps = {};

const AccountManagement = (props: AccountManagementProps) => {
  const { t } = useTranslation();
  
  const { selectedBank, reloadBank } = useContext(BankContext);
  const [modalUpdateBankOpen, setModalUpdateBankOpen] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  const openModalUpdateBank = () => {
    setModalUpdateBankOpen(true);
  };

  const handleCloseModalUpdateBank = () => {
    setModalUpdateBankOpen(false);
  };

  const handleConfirmDelete = (bankId?: number) => {
    deleteBank(bankId)
      .then((response) => {
        reloadBank();
        selectedBank.set(undefined);
      })
      .catch((error) => {
        console.error(error);
      });

    setConfirmVisible(false);
  };

  const handleCancelDelete = () => {
    setConfirmVisible(false);
  };

  /**
   * * The className here is to identify this component in the navigator inspector
   * * PLEASE DONT REMOVE IT
   */
  return (
    <Box className="AccountManagement" sx={styles.managingAccountContainer}>
      <Box sx={listBanksContainerStyles}>
        <BankList />
      </Box>

      {selectedBank.get() ? (
        <Box sx={styles.listUsersContainer}>
          <Box sx={styles.bankInfoContainer}>
            <Typo24Bold sx={titleCommonStyles}>{selectedBank.get()?.name}</Typo24Bold>
            <Box sx={styles.bankCrud}>
              <IconButton color="inherit" onClick={openModalUpdateBank}>
                <EditRounded color="inherit" fontSize="small" />
              </IconButton>
              <IconButton onClick={() => setConfirmVisible(true)}>
                <TrashBinIcon18 fillOpacity={1} />
              </IconButton>
            </Box>
            <UpdateBankModal open={modalUpdateBankOpen} handleClose={handleCloseModalUpdateBank} />
            <ConfirmDialog
              visible={confirmVisible}
              title={t('confirmBankDeletion')}
              message={t('doYouReallyWantToDeleteTheBank')}
              onConfirm={() => handleConfirmDelete(selectedBank.get()?.id)}
              onCancel={handleCancelDelete}
            />
          </Box>
          <ChipSelectConnected />
          <BankUsersListConnected />
        </Box>
      ) : (
        <Box sx={{ width: "100%", padding: "40px", textAlign: "center" }}>
            <Typo16 weight="medium">{t('noSelectedBank')}</Typo16>
        </Box>
      )}
    </Box>
  );
};

export default AccountManagement;
