import { Box, Typography } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { baseStyles, titleCommonStyles, typoCommonStyles } from "utils/constants/baseStyles";

import { AnalysisStatResult } from "types/analysis/analysis";
import CircularProgressWithLabel from "components/Progress/CircularProgressWithLabel/CircularProgressWithLabel";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { getAnalysesStats } from "services/Analysis/AnalysisService";
import { getPercentageValueOfStats } from "utils/analysisStats";
import { selectStatsState } from "store/slices";
import { styles } from "./styles";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

interface IProps {}

export const GlobalStats: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  
  const [stats, setStats] = useState<AnalysisStatResult>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [bgHeight, setBgHeight] = useState("0px");

  const { filters } = useAppSelector(selectStatsState);

  const getColor = (type: string) => {
    switch (type) {
      case "DONE":
        return baseStyles.color.success.main;

      case "FAILED":
        return baseStyles.color.warning.main;

      case "CANCELED":
        return baseStyles.color.error.main;

      default:
        return baseStyles.color.blue.primary;
    }
  };

  React.useEffect(() => {
    getAnalysesStats({ ...filters })
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [filters]);

  React.useEffect(() => {
    if (containerRef && containerRef.current) {
      setBgHeight(`${containerRef.current.scrollHeight}px`);
    }
  }, [containerRef]);

  return (
    <Box sx={styles.container} ref={containerRef}>
      <Box sx={{ ...styles.containerBg, minHeight: bgHeight }}></Box>
      <Typo24Bold color={baseStyles.color.white} sx={{ ...titleCommonStyles, zIndex: 1 }}>
        {t('globalStatistics')}
      </Typo24Bold>

      <Box sx={styles.totalAnalysisContainer}>
        <Box sx={styles.totalAnalysis}>
          <Typography sx={styles.totalAnalysisNumber}>{stats?.totalCount}</Typography>
          <Typo16 color="white" weight="medium" sx={typoCommonStyles}>
            {t('totalAnalysisCount')}
          </Typo16>
        </Box>
      </Box>
      <Box sx={styles.analysisStatContainer}>
        <Box sx={styles.analysisStatRoundContainer}>
          <CircularProgressWithLabel
            sx={{ color: getColor("DONE") }}
            thickness={5}
            {...props}
            value={getPercentageValueOfStats(stats?.doneCount, stats?.totalCount)}
            size={65}
            textColor={baseStyles.color.white}
            circularColor={baseStyles.color.white}
          />
        </Box>
        <Box component="span" sx={{ ...styles.analysisStatNumber, color: getColor("DONE") }}>
          {stats?.doneCount}
        </Box>
        <Typo16 weight="medium" color={baseStyles.color.white} sx={typoCommonStyles}>
          {t('carriedOutAnalyses')}
        </Typo16>
      </Box>
      <Box sx={styles.analysisStatContainer}>
        <Box sx={styles.analysisStatRoundContainer}>
          <CircularProgressWithLabel
            sx={{ color: getColor("FAILED") }}
            thickness={5}
            {...props}
            value={getPercentageValueOfStats(stats?.failedCount, stats?.totalCount)}
            size={65}
            textColor={baseStyles.color.white}
            circularColor={baseStyles.color.white}
          />
        </Box>
        <Box component="span" sx={{ ...styles.analysisStatNumber, color: getColor("FAILED") }}>
          {stats?.failedCount}
        </Box>
        <Typo16 weight="medium" color={baseStyles.color.white} sx={typoCommonStyles}>
          {t('failedAnalyses')}
        </Typo16>
      </Box>
      <Box sx={styles.analysisStatContainer}>
        <Box sx={styles.analysisStatRoundContainer}>
          <CircularProgressWithLabel
            sx={{ color: getColor("CANCELED") }}
            thickness={5}
            {...props}
            value={getPercentageValueOfStats(stats?.canceledCount, stats?.totalCount)}
            size={65}
            textColor={baseStyles.color.white}
            circularColor={baseStyles.color.white}
          />
        </Box>
        <Box component="span" sx={{ ...styles.analysisStatNumber, color: getColor("CANCELED") }}>
          {stats?.canceledCount}
        </Box>
        <Typo16 weight="medium" color={baseStyles.color.white} sx={typoCommonStyles}>
          {t('canceledAnalyses')}
        </Typo16>
      </Box>
    </Box>
  );
};
