import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export interface PenNewSquareIconProps extends Pick<SvgIconProps, "fill"> {}

export const PenNewSquareIcon: FC<PenNewSquareIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M21.1938 2.87216C22.2687 3.94716 22.2687 5.69006 21.1938 6.76506L20.6982 7.2606C20.5539 7.22936 20.3722 7.1818 20.1651 7.10995C19.6108 6.91764 18.8823 6.55418 18.197 5.86892C17.5117 5.18366 17.1483 4.45515 16.956 3.90082C16.8841 3.69372 16.8366 3.512 16.8053 3.36771L17.3009 2.87216C18.3759 1.79717 20.1188 1.79717 21.1938 2.87216Z"
        fill={fill}
      />
      <path
        d="M14.5801 13.3787C14.1761 13.7828 13.9741 13.9848 13.7513 14.1585C13.4886 14.3634 13.2043 14.5391 12.9035 14.6825C12.6485 14.804 12.3775 14.8943 11.8354 15.075L8.97709 16.0278C8.71035 16.1167 8.41626 16.0473 8.21744 15.8485C8.01862 15.6497 7.9492 15.3556 8.03811 15.0888L8.99089 12.2305C9.17157 11.6885 9.26191 11.4174 9.38344 11.1624C9.52679 10.8616 9.70249 10.5773 9.90743 10.3146C10.0812 10.0919 10.2832 9.88986 10.6872 9.48585L15.6033 4.56977C15.867 5.26396 16.3293 6.12255 17.1363 6.92958C17.9434 7.73661 18.802 8.19888 19.4962 8.46266L14.5801 13.3787Z"
        fill={fill}
      />
      <path
        d="M20.5355 20.6015C22 19.137 22 16.78 22 12.0659C22 10.5177 22 9.22365 21.9481 8.13202L15.586 14.4942C15.2347 14.8456 14.9708 15.1096 14.6738 15.3413C14.3252 15.6132 13.948 15.8463 13.5488 16.0366C13.2088 16.1986 12.8546 16.3166 12.3833 16.4736L9.45143 17.4508C8.64568 17.7194 7.75734 17.5097 7.15678 16.9091C6.55621 16.3086 6.34651 15.4202 6.61509 14.6145L7.59235 11.6827C7.74936 11.2113 7.86732 10.8571 8.02935 10.5171C8.21958 10.118 8.45272 9.74072 8.72466 9.39207C8.9563 9.09509 9.22032 8.83119 9.57173 8.47995L15.9339 2.1178C14.8423 2.06592 13.5483 2.06592 12 2.06592C7.28595 2.06592 4.92893 2.06592 3.46447 3.53038C2 4.99485 2 7.35187 2 12.0659C2 16.78 2 19.137 3.46447 20.6015C4.92893 22.0659 7.28595 22.0659 12 22.0659C16.714 22.0659 19.0711 22.0659 20.5355 20.6015Z"
        fill={fill}
      />
    </svg>
  );
};
