import { Box } from "@mui/material";
import { Typo14 } from "components/Typography/Typo14";
import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { styles } from "./styles";

type IProps = {
  bank: Bank;
  selected?: boolean;
  onClick?: () => void;
};

export const BankListItem: FC<IProps> = (props: IProps) => {
  const { onClick = () => {}, bank, selected } = props;

  return (
    <Box sx={styles.itemContainer} className={selected ? "active" : ""} onClick={onClick}>
      <Typo14
        color={selected ? baseStyles.color.white : baseStyles.color.blue.primary}
        weight="medium"
      >
        {bank.name}
      </Typo14>
    </Box>
  );
};
