import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const filesContainerStyles: SxProps = {
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: "20px"
};

export const imageStles: CSSProperties = {
  width: "105px",
  height: "70px",
  borderRadius: "10px",
  border: `1px solid ${baseStyles.color.blue.primary}`,
  cursor: "pointer"
};

export const fileContainerStyles: SxProps = {
  ...flexCenter,
  borderRadius: "10px",
  gap: "10px",
  padding: "15px",
  color: baseStyles.color.blue.primary,
  backgroundColor: baseStyles.color.blue.primary10,
  cursor: "pointer",
  maxWidth: "165px",
  overflow: "hidden",
  "& > p": {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  "& > svg": {
    width: "18px",
    minWidth: "18px",
    height: "18px"
  }
};
