import { FC, createContext, useEffect, useState } from "react";

import { Storage } from "utils/storage";

type AuthType = {
  token: string | null;
  login: (token: string, refreshToken: string) => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthType>({
  token: null,
  login: () => {},
  logout: () => {}
});

interface AuthProviderProps {
  children: any;
}

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const { children } = props;
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setToken(Storage.getItem("token"));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        login: (loginToken: string, refreshToken: string) => {
          setToken(loginToken);
          Storage.setItem("token", loginToken);
          Storage.setItem("refreshToken", refreshToken);
        },
        logout: () => {
          setToken(null);
          Storage.clear();
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
