import { analysisApi, bankApi, userApi } from "./api";
import { analysisReducer, statsReducer, timeSelectorReducer } from "./slices";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { statementsReducer } from "./slices/statementsSlice";

export const store = configureStore({
  devTools: process.env.NODE_ENV === "development",
  reducer: {
    analysis: analysisReducer,
    stats: statsReducer,
    statements: statementsReducer,
    timeSelector: timeSelectorReducer,
    [analysisApi.reducerPath]: analysisApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [bankApi.reducerPath]: bankApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      analysisApi.middleware,
      userApi.middleware,
      bankApi.middleware
    );
  }
});

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
