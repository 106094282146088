import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const EcommerceCartIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.blue.primary }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4175 3.25089C8.69818 2.65917 9.30106 2.25 9.99951 2.25H13.9995C14.698 2.25 15.3008 2.65917 15.5815 3.25089C16.265 3.25586 16.7978 3.28724 17.2733 3.47309C17.8415 3.69516 18.3357 4.07266 18.6994 4.56242C19.0663 5.0565 19.2386 5.68979 19.4757 6.56144L20.2176 9.28272L20.4981 10.124C20.506 10.1339 20.5139 10.1438 20.5218 10.1539C21.4226 11.3076 20.9936 13.0235 20.1357 16.4553C19.59 18.638 19.3172 19.7293 18.5034 20.3647C17.6896 21.0001 16.5647 21.0001 14.3148 21.0001H9.68413C7.43427 21.0001 6.30935 21.0001 5.49556 20.3647C4.68178 19.7293 4.40894 18.638 3.86327 16.4553C3.00532 13.0235 2.57635 11.3076 3.47718 10.1539C3.48506 10.1438 3.49301 10.1338 3.50103 10.1239L3.78141 9.28271L4.52335 6.56145C4.76043 5.6898 4.93267 5.0565 5.2996 4.56242C5.66332 4.07266 6.15753 3.69516 6.72572 3.4731C7.20126 3.28724 7.73399 3.25586 8.4175 3.25089ZM8.41902 4.75231C7.75714 4.759 7.49156 4.78427 7.27175 4.87018C6.9658 4.98976 6.69969 5.19303 6.50384 5.45674C6.32773 5.69388 6.22439 6.0252 5.93349 7.09206L5.36393 9.18091C6.38403 9.00012 7.77704 9.00012 9.68413 9.00012H14.3148C16.222 9.00012 17.615 9.00012 18.6351 9.18092L18.0655 7.09206C17.7746 6.0252 17.6713 5.69388 17.4952 5.45674C17.2993 5.19303 17.0332 4.98976 16.7273 4.87018C16.5075 4.78427 16.2419 4.759 15.58 4.75231C15.2987 5.3423 14.6967 5.75 13.9995 5.75H9.99951C9.30232 5.75 8.70035 5.3423 8.41902 4.75231Z"
        fill={fill}
      />
    </svg>
  );
};
