import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { FC, useEffect, useState } from "react";
import {
  cashflowCardStyles,
  cashflowTabContentStyles,
  contentContainerSx,
  labelStyles,
  valueStyles
} from "./styles";

import { AirTimeIcon } from "assets/icons/AirTimeIcon";
import { AnalysisResultsTabContentProps } from "./types";
import { BanknoteIcon } from "assets/icons/BanknoteIcon";
import { BillIcon } from "assets/icons/BillIcon";
import { Box } from "@mui/material";
import { CardReciveIcon } from "assets/icons/CardReciveIcon";
import { CardSendIcon } from "assets/icons/CardSendIcon";
import { EcommerceCartIcon } from "assets/icons/EcommerceCartIcon";
import { SafeCircleIcon } from "assets/icons/SafeCircleIcon";
import { getAnalysisResultFormattedData } from "utils";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface ExpensesProps extends AnalysisResultsTabContentProps {}

export const Expenses: FC<ExpensesProps> = ({ data }) => {
  const { t } = useTranslation();
  
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const formattedData: AnalysisResultCardData[] = [
    {
      label: t('hasRecurringExpense'),
      value: getAnalysisResultFormattedData("boolean", data?.hasRecurringExpense),
      description: t('hasRecurringExpenseDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('averageRecurringExpense'),
      value: getAnalysisResultFormattedData("money", data?.averageRecurringExpense, true, currentCurrency),
      description: t('averageRecurringExpenseDescription'),
      Icon: () => <CardSendIcon />
    },
    {
      label: t('posSpend'),
      value: getAnalysisResultFormattedData("money", data?.posSpend, true, currentCurrency),
      description: t('posSpendDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('bills'),
      value: getAnalysisResultFormattedData("money", data?.bills, true, currentCurrency),
      description: t('billsDescription'),
      Icon: () => <BillIcon />
    },
    {
      label: t('internationalTransactionsSpend'),
      value: getAnalysisResultFormattedData("money", data?.internationalTransactionsSpend, true, currentCurrency),
      description: t('internationalTransactionsSpendDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('spendOnTransfers'),
      value: getAnalysisResultFormattedData("money", data?.spendOnTransfers, true, currentCurrency),
      description: t('spendOnTransfersDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('dab'),
      value: getAnalysisResultFormattedData("money", data?.dab, true, currentCurrency),
      description: t('dabDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('airtime'),
      value: getAnalysisResultFormattedData("money", data?.airtime, true, currentCurrency),
      description: t('airtime'),
      Icon: () => <AirTimeIcon />
    },
    {
      label: t('bundleSpend'),
      value: getAnalysisResultFormattedData("money", data?.bundleSpend, undefined, currentCurrency),
      description: t('bundleSpendDescription'),
      Icon: () => <EcommerceCartIcon />
    },
    {
      label: t('gambling'),
      value: getAnalysisResultFormattedData("money", data?.gambling, true, currentCurrency),
      description: t('gamblingDescription'),
      Icon: () => <SafeCircleIcon />
    },
    {
      label: t('webSpend'),
      value: getAnalysisResultFormattedData("money", data?.webSpend, true, currentCurrency),
      description: t('webSpendDescription'),
      Icon: () => <EcommerceCartIcon />
    },
    {
      label: t('totalExpenses'),
      value: getAnalysisResultFormattedData("money", data?.totalExpenses, true, currentCurrency),
      description: t('totalExpensesDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('bankCharges'),
      value: getAnalysisResultFormattedData("money", data?.bankCharges, true, currentCurrency),
      description: t('bankChargesDescription'),
      Icon: () => <BanknoteIcon />
    }
  ];

  return (
    <Box sx={{ ...cashflowTabContentStyles }}>
      {formattedData.map(({ label, value, description, Icon }, index) => (
        <AnalysisResultCard
          sx={cashflowCardStyles}
          contentContainerSx={contentContainerSx}
          labelSx={labelStyles}
          valueSx={valueStyles}
          key={`${index}-${label}`}
          label={label}
          value={value}
          description={description}
          Icon={Icon}
        />
      ))}
    </Box>
  );
};
