import { Alert, AlertColor } from "@mui/material"
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { useEffect, useState } from "react"

interface IProps {
    message: string,
    open: boolean,
    autoHideDuration?: number,
    severity?: AlertColor
}
export const CustomSnackbar = (props: IProps) => {
    const { message, open, autoHideDuration = 5000, severity = 'success' } = props;
    const [openState, setOpenState] = useState<boolean>(open);

    const handleClose = () => {
        setOpenState(false);
    }

    useEffect(() => {
        setOpenState(open);
    }, [open]);
    
    return (
        <Snackbar open={openState} autoHideDuration={autoHideDuration} anchorOrigin={{'vertical': 'top', 'horizontal': 'right'}}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
} 