import { FormControl, MenuItem, Select, SxProps } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

export type CustomSelectProps = {
  options: Array<{
    value: any;
    element: any;
  }>;
  value?: any;
  label?: any;
  placeholder?: any;
  fullWidth?: boolean;
  onChange: any;
  sx?: SxProps;
};

const CustomSelect = (props: CustomSelectProps) => {
  const {
    options,
    value = "",
    label = "",
    fullWidth = false,
    onChange,
    placeholder = label,
    sx = {}
  } = props;

  const styles = {
    fontSize: "15px",
    paddingLeft: 1,
    paddingTop: "7px",
    paddingBottom: "7px",
    ".MuiInputBase-input": {
      height: "24px",
      padding: 0,
      fontWeight: "400",
      color: baseStyles.color.blue.primary
    },
    ".MuiOutlinedInput-notchedOutline": {
      outline: "none",
      border: "none"
    },
    marginBottom: 2,
    borderRadius: "10px",
    backgroundColor: baseStyles.color.blue.primary3,
    border: "2px solid ",
    borderColor: "rgba(1, 29, 123, 0.10)",
    outline: "none",
    width: fullWidth ? "100%" : "auto"
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        sx={{ ...styles, ...sx }}
      >
        <MenuItem disabled value="" selected>
          {placeholder}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.element}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
