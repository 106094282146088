import { ConfirmDialog } from "components/Dialog/ConfirmDialog/ConfirmDialog";
import { BankContext } from "providers/BankProvider";
import { useCallback, useContext, useState } from "react";
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from "store/api";
import { BankUsersList } from "./BankUsersList";

export const BankUsersListConnected = () => {
  const { selectedBank, reloadBank } = useContext(BankContext);
  const bank = selectedBank.get();
  const bankId = bank?.id;

  const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | string | null>(null);

  const { data, isLoading, isFetching } = useGetUsersQuery({ bankId }, { skip: !bankId });
  const users: User[] = data || [];
  const loading = isLoading || isFetching;

  const [deleteUser, { isLoading: isLoadingDelete }] = useDeleteUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();

  const handleDeleteUser = (id: number | string) => {
    setSelectedUserId(id);
    setShowConfirmDeleteUser(true);
  };

  const handleConfirmDelete = useCallback(() => {
    if (selectedUserId) {
      setShowConfirmDeleteUser(false);
      deleteUser(selectedUserId)
        .unwrap()
        .then(() => {
          setSelectedUserId(null);
          reloadBank();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const handleCancelDelete = () => {
    setShowConfirmDeleteUser(false);
  };

  const handleUpdateStatus = useCallback(
    (statusCode: string) => {
      if (selectedUserId) {
        updateUser({ id: selectedUserId, statusCode })
          .unwrap()
          .then(() => {
            setSelectedUserId(null);
            reloadBank();
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUserId, updateUser]
  );

  return (
    <>
      <BankUsersList
        users={users}
        selectedUserId={selectedUserId}
        loading={loading}
        deleteLoading={isLoadingDelete}
        updateLoading={isLoadingUpdate}
        onDeleteUser={handleDeleteUser}
        onClickStatus={setSelectedUserId}
        onUpdateStatus={handleUpdateStatus}
      />
      <ConfirmDialog
        visible={showConfirmDeleteUser}
        title="Confirmation suppression utilisateur"
        message="Voulez-vous vraiment supprimer l'utilisateur?"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
    </>
  );
};
