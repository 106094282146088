import { BankProvider } from "providers/BankProvider";
import Messagerie from "./Messagerie";
import SupportTicketProvider from "providers/SupportMessageProvider";

type IProps = {

}
const MessagerieWrapper = () => {
    return (
        <>  
            <BankProvider>
                <Messagerie />
            </BankProvider>
        </>
    )
}

export default MessagerieWrapper;