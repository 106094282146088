import { SxProps } from "@mui/material";
import { baseStyles, flexCenter, flexColumn, typoCommonStyles } from "utils/constants/baseStyles";

export const userAvatarContainerStyles = (size = 126): SxProps => ({
  ...flexCenter,
  position: "relative",
  width: `${size}px`,
  height: `${size}px`
});

export const cameraContainerStyles: SxProps = {
  ...flexCenter,
  position: "absolute",
  zIndex: 1,
  right: "-6px",
  top: "6px",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: baseStyles.color.blue.primary,
  border: `3px solid ${baseStyles.color.white}`,
  cursor: "pointer"
};

export const loadingContainerStyles: SxProps = {
  ...flexCenter,
  position: "absolute",
  inset: 0,
  zIndex: 3,
  borderRadius: "50%",
  ":after": {
    content: '" "',
    position: "absolute",
    inset: 0,
    backgroundColor: baseStyles.color.blue.primary,
    borderRadius: "50%",
    opacity: 0.5,
    zIndex: 2
  }
};

export const editBtnStyles: SxProps = {
  padding: "0px 15px",
  height: "40px",
  gap: "10px",
  border: `1px solid ${baseStyles.color.blue.primary10}`,
  color: baseStyles.color.blue.primary60,
  "&:hover": {
    border: `1px solid ${baseStyles.color.blue.primary10}`,
    backgroundColor: baseStyles.color.white
  }
};

export const infoPersoLineStyles: SxProps = {
  ...flexColumn,
  gap: "20px"
};

export const inputStyles: SxProps = {
  padding: "11px 15px",
  marginBottom: "0px"
};

export const inputPasswordStyles: SxProps = {
  ...inputStyles,
  fontSize: "34px",
  fontWeight: "700",
  letterSpacing: "0.5px"
};

export const inputErrorStyles: SxProps = {
  borderColor: baseStyles.color.error.bg,
  backgroundColor: baseStyles.color.error.main3,
  "& .MuiInputBase-input": {
    color: baseStyles.color.error.main
  }
};

export const inputLabelStyles: SxProps = {
  ...typoCommonStyles,
  color: baseStyles.color.blue.primary60
};
