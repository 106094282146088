import { FC } from "react";

export const UsersGroupTwoRoundedIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M15.5 8C15.5 9.933 13.933 11.5 12 11.5C10.067 11.5 8.5 9.933 8.5 8C8.5 6.067 10.067 4.5 12 4.5C13.933 4.5 15.5 6.067 15.5 8Z"
        fill="white"
      />
      <path
        d="M18 17C18 18.933 15.3137 20.5 12 20.5C8.68629 20.5 6 18.933 6 17C6 15.067 8.68629 13.5 12 13.5C15.3137 13.5 18 15.067 18 17Z"
        fill="white"
      />
      <path
        d="M7.12205 5.5C7.29951 5.5 7.47276 5.51741 7.64005 5.55056C7.23249 6.27446 7 7.11008 7 8C7 8.86825 7.22131 9.68482 7.61059 10.3964C7.45245 10.4258 7.28912 10.4413 7.12205 10.4413C5.70763 10.4413 4.56102 9.33512 4.56102 7.97063C4.56102 6.60614 5.70763 5.5 7.12205 5.5Z"
        fill="#0CD97B"
      />
      <path
        d="M5.44734 19.486C4.87942 18.8071 4.5 17.974 4.5 17C4.5 16.0558 4.85657 15.244 5.39578 14.5767C3.4911 14.7245 2 15.7662 2 17.0294C2 18.3044 3.5173 19.3538 5.44734 19.486Z"
        fill="#0CD97B"
      />
      <path
        d="M16.9999 8C16.9999 8.86825 16.7786 9.68482 16.3893 10.3964C16.5475 10.4258 16.7108 10.4413 16.8779 10.4413C18.2923 10.4413 19.4389 9.33512 19.4389 7.97063C19.4389 6.60614 18.2923 5.5 16.8779 5.5C16.7004 5.5 16.5272 5.51741 16.3599 5.55056C16.7674 6.27446 16.9999 7.11008 16.9999 8Z"
        fill="#0CD97B"
      />
      <path
        d="M18.5526 19.486C20.4826 19.3538 21.9999 18.3044 21.9999 17.0294C21.9999 15.7662 20.5088 14.7245 18.6041 14.5767C19.1433 15.244 19.4999 16.0558 19.4999 17C19.4999 17.974 19.1205 18.8071 18.5526 19.486Z"
        fill="#0CD97B"
      />
    </svg>
  );
};
