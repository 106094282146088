import { FC, useEffect, useState } from "react";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

import { Box } from "@mui/material";
import { CalendarIcon18 } from "assets/icons/CalendarIcon18";
import { Typo14 } from "components/Typography/Typo14";
import { formatDate } from "utils";
import i18next from "i18next";

export interface HeaderDateProps {
  date?: string | Date;
}

export const HeaderDate: FC<HeaderDateProps> = ({ date = new Date() }) => {
  const [displayDate, setDisplayDate] = useState<string | Date>(date);

  i18next.on('languageChanged', () => {
    console.log('reset date')
    setDisplayDate(new Date(displayDate));
  });
  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "10px",
        marginLeft: "auto",
        marginRight: "20px",
        paddingRight: "20px",
        borderRight: `1px dashed ${baseStyles.color.blue.primary20}`,
        height: "45px"
      }}
    >
      <Box
        sx={{
          ...flexCenter,
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: baseStyles.color.blue.primary10
        }}
      >
        <CalendarIcon18 />
      </Box>
      <Typo14
        sx={{ lineHeight: "18px", letterSpacing: "0.5px", color: baseStyles.color.blue.primary60 }}
      >
        {formatDate(displayDate)}
      </Typo14>
    </Box>
  );
};
