import { Box, Grid, Stack } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";

import { ListMessageContent } from "./ListMessageContent/ListMessageContent";
import MessageInput from "components/MessageInput/MessageInput";
import { SupportTicket } from "providers/SupportMessageProvider";
import { Typo12 } from "components/Typography/Typo12";
import { Typo16Bold } from "components/Typography/Typo16Bold";
import { WebsocketContext } from "providers/WebsocketProvider";
import { baseStyles } from "utils/constants/baseStyles";
import { formatDateTime } from "utils";
import { getAllMessagesBySupportTicketId } from "services/SupportTickets/SupportTicketsService";
import { noSupportTicketContainerStyles } from "./styles";
import { useTranslation } from "react-i18next";

type IProps = {
  supportTicketId: string;
  supportTicketSubject: string;
  supportTicketDate: string;
};

const MessagerieContent: FC<IProps> = (props) => {
  const { t } = useTranslation();
  
  // const { supportTicketSubject, supportTicketDate } = props;
  const { currentSupportTicket, supportTicketMessages } = useContext(SupportTicket);
  const [supportTicketSubject, setSupportTicketSubject] = useState<string>("");
  const [supportTicketDate, setSupportTicketDate] = useState<string>("");
  const [supportTicketId, setSupportTicketId] = useState<number | string>(0);
  const { subscribe } = useContext(WebsocketContext);

  

  const updateListMessageScroll = () => {
    setTimeout(() => {
      const messageListContainer = document.getElementById('message-list-container');
      
      if (messageListContainer) {
        messageListContainer.scrollTop = messageListContainer.scrollHeight;
      }
    }, 1000);
  };

  const loadSupportTicketsMessages = () => {
    if (!currentSupportTicket || !currentSupportTicket.get) {
      return;
    }
    console.log('load support tickets for ' + currentSupportTicket.get.id);
    getAllMessagesBySupportTicketId(currentSupportTicket.get.id).then(
      (data) => {
        console.log('data', data);
        supportTicketMessages.set(data);
        updateListMessageScroll();
      },
      (error: any) => {
        console.log("Error on fetching messages: ", error);
      }
    );
  };

  React.useEffect(() => {
    if (currentSupportTicket.get) {
      setSupportTicketSubject(currentSupportTicket.get.subject);
      setSupportTicketDate(currentSupportTicket.get.date);
      setSupportTicketId(currentSupportTicket.get.id);

      // Fetch details for currentSupportTicket
      supportTicketMessages.setLoading(true);
      loadSupportTicketsMessages();

      subscribe(`new_support_message_${currentSupportTicket.get.id}`, 'MessagerieContent', (data: any) => {
        console.log('received a message in ticket ' + currentSupportTicket.get?.id);
        loadSupportTicketsMessages();
      });
    } else {
      console.log("no current support ticket");
    }
  }, [currentSupportTicket.get]);

  return (
    <Box sx={{ height: "100%", backgroundColor: "rgba(1, 29, 123, 0.05)" }}>
      {currentSupportTicket.get ? (
        <Grid container direction={"column"} sx={{ height: "100%", flexWrap: "nowrap" }}>
          <Grid item>
            {/* Title */}
            <Box
              sx={{
                backgroundColor: baseStyles.color.blue.background,
                borderBottom: "1px solid rgba(1, 29, 123, 0.10)",
                height: "100px",
                display: "flex",
                alignItems: "center",
                padding: "0px 30px"
              }}
            >
              <Stack gap={"0.625rem"}>
                <Typo16Bold color={"#FFF"}>{supportTicketSubject}</Typo16Bold>
                <Typo12 color={"#FFF"}>{formatDateTime(supportTicketDate)}</Typo12>
              </Stack>
            </Box>
          </Grid>
          <Grid item sx={{ flexGrow: "1", overflowY: "auto", height: "100%" }}>
            {/* Content */}
            <Grid
              container
              direction={"column"}
              sx={{ gap: "1.5rem", height: "100%", flexWrap: "nowrap" }}
            >
              {/* Message list */}
              <Grid
                id="message-list-container"
                item
                sx={{
                  padding: "50px",
                  paddingBottom: "0",
                  flexGrow: "1",
                  maxHeight: "100%",
                  overflowY: "auto"
                }}
              >
                <ListMessageContent
                  supportTicketMessages={supportTicketMessages.get}
                  loading={supportTicketMessages.loading}
                />
              </Grid>

              {/* New message form */}
              <Grid item sx={{ padding: "50px", paddingTop: "0" }}>
                <MessageInput supportTicketId={supportTicketId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={noSupportTicketContainerStyles}>
          {t('noSupportTicket')}
        </Grid>
      )}
    </Box>
  );
};

export default MessagerieContent;
