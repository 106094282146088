import { SxProps } from "@mui/material";
import { baseStyles, flexCenter, flexRow, fontMontserrat } from "utils/constants/baseStyles";

export const styles = {
  tagsInputContainer: {
    ...flexRow,
    alignItems: "center",
    width: "100%",
    minHeight: "60px",
    padding: "0px 15px",
    borderRadius: "10px",
    gap: "10px",
    backgroundColor: baseStyles.color.blue.primary5,
    overflow: "hidden"
  } as SxProps,
  messageMailIconContainer: {
    ...flexCenter,
    borderRadius: "10px",
    width: "40px",
    minWidth: "40px",
    height: "40px",
    backgroundColor: baseStyles.color.blue.background
  } as SxProps,
  itemsContainer: {
    ...flexRow,
    alignItems: "center",
    gap: "10px",
    padding: "5px 0px",
    overflowX: "auto"
  } as SxProps,
  tagItem: {
    ...flexRow,
    gap: "10px",
    backgroundColor: baseStyles.color.blue.primary60,
    color: baseStyles.color.white,
    padding: "10px 15px",
    borderRadius: "10px"
  } as SxProps,
  closeButton: {
    display: "inline-block",
    cursor: "pointer"
  } as SxProps,
  input: {
    ...fontMontserrat,
    minWidth: "150px",
    color: baseStyles.color.blue.primary,
    fontSize: "14px",
    fontWeight: "500"
  } as SxProps
};
