import { Popover, PopoverOrigin, SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { PopoverArrowIcon } from "assets/icons/PopoverArrowIcon";
import { FC, PropsWithChildren, useState } from "react";
import { ArrowContainer, popoverStyles, styles } from "./styles";

export interface MouseOverPopoverProps extends Required<PropsWithChildren> {
  Element: (props: any) => JSX.Element;
  sx?: SxProps;
  popoverSx?: SxProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  id?: string;
}

export const MouseOverPopover: FC<MouseOverPopoverProps> = ({
  Element,
  children,
  sx = {},
  popoverSx = {},
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  transformOrigin = { vertical: "top", horizontal: "center" },
  id = "mouse-over-popover"
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...styles, ...(sx as any) }}>
      <Element
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id={id}
        sx={{ ...(popoverStyles as any), ...popoverSx }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus={true}
      >
        <ArrowContainer>
          <PopoverArrowIcon />
        </ArrowContainer>
        {children}
      </Popover>
    </Box>
  );
};
