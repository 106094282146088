import { baseStyles, flexCenter, flexRow } from "utils/constants/baseStyles";

import { SxProps } from "@mui/material";

export const containerStyles: SxProps = {
  ...flexRow,
  alignItems: "center",
  justifyContent: "space-between",
  width: "158px",
  height: "40px",
  borderRadius: "10px",
  padding: "0px 10px 0px 15px",
  color: baseStyles.color.white
};

export const arrowContainerStyles: SxProps = {
  ...flexCenter,
  gap: "10px",
  height: "100%"
};

export const separatorContainerStyles: SxProps = {
  ...flexRow,
  height: "100%"
};

export const separatorStyles: SxProps = {
  width: "1px",
  height: "100%"
};
