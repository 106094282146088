import { adapterBank, adapterMobileMoneyCompany } from "types/bank";

import axios from "services/AuthorizedAxiosInstance";

export const getBanks = (data: any) => {
  return axios.get("api/banks/", {params: data}).then((response) => {
    return response.data.map((x: any) => adapterBank(x));
  });
};

export const getClientBanks = () => {
  return getBanks({ 'showInClient': 1 });
};

export const getStatementBanks = () => {
  return getBanks({ 'showInStatement': 1 });
};

export const addBank = (data: any) => {
  return axios.post("api/banks/", data);
};

export const updateBank = (bankId: number | undefined, data: any) => {
  return axios.put(`api/banks/${bankId}`, data);
};

export const deleteBank = (bankId: number | undefined) => {
  return axios.delete(`api/banks/${bankId}`);
};

export const getMobileMoneyCompanies = () => {
  return axios.get("api/banks/mobileMoneyCompanies").then((response) => {
    return response.data.map((x: any) => adapterMobileMoneyCompany(x));
  });
}
