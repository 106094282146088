import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  InputAdornment,
  Paper,
  Typography
} from "@mui/material";
import { ChangeEvent, useState } from "react";

import { CustomAlert } from "components/Alert/CustomAlert";
import { CustomInput } from "components/InputField/CustomInput";
import { LeftPageLogin } from "./LeftPageLogin";
import { LettreIcon24 } from "assets/icons/LetterIcon";
import { Link } from "react-router-dom";
import { LogoMediumSize } from "components/Logo/LogoMediumSize";
import { ShieldIcon } from "components/Icons/SvgIcons";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { Typo16 } from "components/Typography/Typo16";
import { baseStyles } from "utils/constants/baseStyles";
import { forgotPassword } from "services/User/UserService";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

export const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<{
    status: boolean;
    statusCode: number | null;
    message: string;
  }>({
    status: false,
    statusCode: null,
    message: ""
  });

  const isInputError = error.statusCode === 400;
  const inputAdornmentStroke = isInputError ? baseStyles.color.error.main : undefined;

  const { t } = useTranslation();

  const onEmailChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const performForgotPassword = () => {
    setError((state) => {
      return { ...state, status: false, statusCode: null };
    });
    setSuccess(false);
    setLoading(true);
    console.log("Sending email to ", email);
    forgotPassword({
      email: email
    })
      .then(
        (response) => {
          // perform action when no error
          console.log(response);
          setSuccess(true);
        },
        (error) => {
          // perform action when error(promise rejected)
          if (error.response?.status === 400) {
            setError((state) => {
              return { ...state, message: t("alert.noEmail"), status: true, statusCode: 400 };
            });
          } else if (error.response?.status === 500) {
            setError((state) => {
              return {
                ...state,
                message: t("alert.serverError"),
                status: true
              };
            });
          } else {
            setSuccess(true);
          }
          console.log(error.response);
        }
      )
      .catch((e) => {
        // perform action when error on axios
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" sx={styles.mainContainer}>
      <CssBaseline />
      <LeftPageLogin />
      <Grid item xs={12} sm={6} md={6} component={Paper} sx={styles.rightPart}>
        <Box sx={styles.rightPartContainer}>
          <Box sx={styles.switchLanguageContainer}>
            <SwitchLanguage></SwitchLanguage>
          </Box>
          <Box sx={styles.logoContainer}>
            <Link to={"/"}>
              <LogoMediumSize />
            </Link>
          </Box>
          <Typography variant="h1" sx={styles.welcome}>
            {t("forgotPassword.forgotenPassword")}
          </Typography>
          <Box sx={styles.welcomeTextAndErrorContainer}>
            <Box sx={styles.welcomeText}>{t("forgotPassword.info")}</Box>
            <CustomAlert
              sx={styles.alertStyles}
              title={t("alert.success") + ": "}
              body={t("alert.emailResetPassword")}
              icon={<ShieldIcon />}
              severity="success"
              closable={true}
              opened={success}
              onclose={() => {
                setSuccess(false);
              }}
            />
            <CustomAlert
              sx={styles.alertStyles}
              title={t("alert.error") + ": "}
              body={error.message}
              icon={<ShieldIcon />}
              severity="error"
              closable={true}
              opened={error.status}
              onclose={() => {
                setError((state) => {
                  return { ...state, status: false };
                });
              }}
            />
          </Box>
          <CustomInput
            sx={styles.inputStyles}
            endAdornment={
              <InputAdornment position="end">
                <LettreIcon24 stroke={inputAdornmentStroke} />
              </InputAdornment>
            }
            placeholder={t("login.email")}
            onchange={onEmailChanged}
            isValid={!isInputError}
          />
          <Button
            variant="contained"
            disableElevation
            sx={styles.loginButton}
            onClick={loading ? undefined : performForgotPassword}
          >
            <Typo16 weight="medium" sx={styles.loginButtonText}>
              {t("forgotPassword.sendMail")}
              {loading && <CircularProgress color="inherit" size={22} sx={styles.loadingStyles} />}
            </Typo16>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
