import { Storage } from 'utils/storage';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BACK_URL;

const instance = axios.create({
    baseURL: baseURL
});

// Implementing axios interceptors
instance.interceptors.request.use(
    (config) => {
        // Modify the request config here (e.g., add headers, authentication tokens)

        const accessToken = Storage.getItem("token");

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            // if (config.headers) config.headers.token = accessToken;
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

export default instance;