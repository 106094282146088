import { Box, IconButton, Stack } from "@mui/material";
import { customModalStyles, styles } from "./styles";
import { useContext, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "components/Button/CustomButton";
import { CustomInput } from "components/InputField/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import DragableFileInput from "components/InputField/DragableFileInput";
import { FileIcon } from "components/Icons/SvgIcons";
import { SupportTicket } from "providers/SupportMessageProvider";
import { Typo14 } from "components/Typography/Typo14";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { Typo16 } from "components/Typography/Typo16";
import { addSupportTicket } from "services/SupportTickets/SupportTicketsService";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

type IProps = {
  open: boolean;
  handleClose: any;
};

type NewSupportFormPayload = {
  subject: string;
  text: string;
  files: any[];
};

export const NewSupportModal = (props: IProps) => {
  const { t } = useTranslation();
  
  const { open, handleClose } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showBgBlur, setShowBgBlur] = useState<Boolean>(false);
  const [selectedFile, setSelectedFile] = useState<AnalysisFile | undefined>();
  const [newSupportForm, setNewSupportForm] = useState<NewSupportFormPayload>({
    subject: "",
    text: "",
    files: []
  });

  const isValidSupportForm = (supportForm: NewSupportFormPayload) => {
    return supportForm.subject !== "" && supportForm.text !== "";
  };

  const disabledSendBtn = !isValidSupportForm(newSupportForm);

  const { reloadSupportTickets } = useContext(SupportTicket);

  const showFile = (filename: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          padding: "10px 20px",
          alignItems: "center",
          gap: "20px"
        }}
      >
        <FileIcon />
        <Typo14 weight="regular" sx={styles.filename}>
          {filename}
        </Typo14>
        <IconButton
          aria-label="close"
          onClick={() => {
            setSelectedFile(undefined);
            // setAnalysisForm(
            //     (oldState) => ({ ...oldState, files: [] })
            // )
          }}
          // size="small"
          sx={{
            marginLeft: "auto",
            color: "white",
            width: "24px",
            height: "24px",
            "&.MuiIconButton-root": {
              backgroundColor: baseStyles.color.blue.primary
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const handleCreateSupport = () => {
    console.log("new support form", newSupportForm);
    addSupportTicket(newSupportForm)
      .then((response) => {
        reloadSupportTickets();
        handleClose();
        setSelectedFile(undefined);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <CustomModal
      title={t('newMessage')}
      open={open}
      handleClose={handleClose}
      closeButton={true}
      bgBlur={
        showBgBlur && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              boxSizing: "border-box",
              border: "none",
              zIndex: 1000
            }}
          ></Box>
        )
      }
      sx={customModalStyles}
      sxChildrenContainer={{ maxHeight: "unset" }}
    >
      <Stack gap={"30px"} sx={{ height: "calc(100% - 51px)" }}>
        <Stack gap={"15px"}>
          <Typo14Medium>{t('object')}: </Typo14Medium>
          <CustomInput
            sx={{ marginBottom: 0 }}
            fullWidth
            isValid={true}
            type="text"
            placeholder={`${t('object')}...`}
            required
            onchange={(e) => {
              setNewSupportForm({
                ...newSupportForm,
                subject: e.target.value
              });
            }}
          />
        </Stack>
        <Stack gap={"15px"}>
          <Typo14Medium>{t('message')}: </Typo14Medium>
          <CustomInput
            sx={{ marginBottom: 0, boxSizing: "border-box" }}
            fullWidth
            isValid={true}
            type="text"
            placeholder={`${t('message')}...`}
            required
            multiline={true}
            minRows={6}
            maxRows={6}
            onchange={(e) => {
              setNewSupportForm({
                ...newSupportForm,
                text: e.target.value
              });
            }}
          />
        </Stack>
        <Box>
          {selectedFile ? (
            showFile(selectedFile.name)
          ) : (
            <DragableFileInput
              onUploaded={(file) => {
                console.log(file);
                setSelectedFile(file);

                if (file?.base64data) {
                  setNewSupportForm({
                    ...newSupportForm,
                    files: [file.base64data]
                  });
                }
                console.log("Setting file done");
              }}
              message={
                <>
                  <strong>{t('clickToSelectFile')}</strong>
                  <br />
                  {t('orDragAndDropFile')} (max. 10mb)
                </>
              }
            />
          )}
        </Box>
        <Box sx={{ marginTop: "auto", paddingBottom: "50px" }}>
          <CustomButton
            onClick={handleCreateSupport}
            disabled={disabledSendBtn}
            sx={{ height: "60px", width: "100%" }}
          >
            <Typo16 weight="medium" color="inherit">
              {t('sendMessage')}
            </Typo16>
          </CustomButton>
        </Box>
      </Stack>
    </CustomModal>
  );
};
