import { FC, createContext, useContext } from 'react';

import { UserContext } from './UserProvider';

type PermissionContextType = {
    permissions: string[],
    isAllowedTo: (permission: string) => boolean;
}

const defaultBehaviour: PermissionContextType = {
    permissions: [],
    isAllowedTo: () => false
}

export const PermissionContext = createContext<PermissionContextType>( defaultBehaviour);


type Props = {
    children: any
}

const PermissionProvider: FC<Props> = ({ children }) => {
    const { currentUser } = useContext(UserContext);
    const permissions = currentUser ? currentUser?.permissions : [];
    
    const isAllowedTo = (permission: string) => permissions.includes(permission);

    return (
        <PermissionContext.Provider value={{ permissions: permissions, isAllowedTo: isAllowedTo }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;