import { GLOBAL_ERROR_MSG, GLOBAL_ERROR_MSG_GET_DATA } from "constants/error";
import React, { FC, useContext, useState } from "react";

import { BankContext } from "providers/BankProvider";
import { Box } from "@mui/material";
import { CLIENTELE_LIST_ID } from "constants/dom";
import { ClienteleCard } from "./ClienteleCard/ClienteleCard";
import { ClienteleCardLoading } from "./ClienteleCard/ClienteleCardLoading";
import { ClienteleContext } from "providers/ClienteleProvider";
import { ClienteleGlobalStats } from "../ClienteleGlobalStats/ClienteleGlobalStats";
import { CustomAlertError } from "components/Alert/CustomAlertError";
import { Typo16 } from "components/Typography/Typo16";
import { baseStyles } from "utils/constants/baseStyles";
import { containerStyles } from "./styles";
import { getArrayOfNumber } from "utils";
import { useGetUsersQuery } from "store/api";
import { useTranslation } from "react-i18next";

export const ClienteleList: FC = () => {
  const { t } = useTranslation();
  
  const { selectedBank } = useContext(BankContext);
  const { selectedClientele, clienteles } = useContext(ClienteleContext);

  const [clientele, setClientele] = useState<User>();
  const bankId = selectedBank?.get()?.id;

  const { data, isLoading, isFetching, isError } = useGetUsersQuery({ bankId }, { skip: !bankId });
  const users: User[] = data || [];
  const loading = isLoading || isFetching;

  React.useEffect(() => {
    if (data) {
      clienteles.set(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    setClientele(selectedClientele.get());
  }, [selectedClientele]);

  if (isError) {
    return (
      <CustomAlertError
        title={GLOBAL_ERROR_MSG}
        body={GLOBAL_ERROR_MSG_GET_DATA}
        opened={isError}
        closable={false}
      />
    );
  }

  if (loading) {
    return (
      <Box sx={containerStyles}>
        {getArrayOfNumber(4).map((i) => (
          <ClienteleCardLoading key={i} />
        ))}
      </Box>
    );
  }

  if (users.length === 0 && !loading && !isError) {
    return (
      <Typo16 weight="medium" color={baseStyles.color.warning.main}>
        {t('thisBankDoesNotHaveAccountManagers')}
      </Typo16>
    );
  }

  return (
    <>
      <Box sx={containerStyles} id={CLIENTELE_LIST_ID}>
        {users.map((user) => (
          <ClienteleCard user={user} key={user.id} selected={user.id === clientele?.id} />
        ))}
      </Box>
      <ClienteleGlobalStats />
    </>
  );
};
