import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { getArrayOfNumber } from "utils";
import { flexColumn } from "utils/constants/baseStyles";
import { tableCellRowStyles, userNameContainerStyles } from "./styles";

export interface BankUsersListLoadingProps {
  nbLine?: number;
}

export const BankUsersListLoading: FC<BankUsersListLoadingProps> = ({ nbLine = 2 }) => {
  return (
    <>
      {getArrayOfNumber(nbLine).map((n) => (
        <TableRow key={n}>
          <TableCell sx={{ ...tableCellRowStyles(true) }}>
            <Box sx={userNameContainerStyles}>
              <Skeleton width={63} height={63} variant="circular" />
              <Box sx={{ ...flexColumn, gap: "5px" }}>
                <Skeleton width={120} height={20} animation="wave" />
                <Skeleton width={120} height={15} animation="wave" />
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ ...tableCellRowStyles() }}>
            <Skeleton width={158} height={40} animation="wave" />
          </TableCell>
          <TableCell sx={{ ...tableCellRowStyles() }}>
            <Skeleton width={120} height={20} animation="wave" />
          </TableCell>
          <TableCell sx={{ ...tableCellRowStyles(false, true) }}>
            <Skeleton width={18} height={18} variant="circular" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
