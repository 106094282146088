import { ChangeEvent, FC, PropsWithChildren } from "react";
import { CircularProgress, Stack, styled } from "@mui/material";
import {
  CustomInput,
  CustomInputProps,
  CustomRadioGroup,
  CustomRadioGroupItem,
  CustomReactDatePicker
} from "components/InputField";
import CustomSelect, { CustomSelectProps } from "components/InputField/CustomSelect";

import { AnalysisFiltersProps } from "./AnalysisFilters";
import { CustomButton } from "components/Button/CustomButton";
import CustomModal from "components/CustomModal/CustomModal";
import { GetManyAnalysisQueryParams } from "store/api";
import { Typo14 } from "components/Typography/Typo14";
import { Typo16 } from "components/Typography/Typo16";
import { baseStyles } from "utils/constants/baseStyles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const StyledCustomReactDatePicker = styled(CustomReactDatePicker)`
  & input {
    padding: 0;
  }
`;

export interface AnalysisFiltersModalProps extends AnalysisFiltersProps {
  open: boolean;
  resultsCount: number;
  loading?: boolean;
  onClose: () => void;
}

interface LineProps extends Required<PropsWithChildren> {
  label: string;
}

const Line: FC<LineProps> = ({ children, label }) => {
  return (
    <Stack gap="15px">
      <Typo14>{label}</Typo14>
      {children}
    </Stack>
  );
};

const Input: FC<CustomInputProps> = (props) => {
  return (
    <CustomInput
      {...props}
      fullWidth={true}
      sx={{
        color: baseStyles.color.blue.primary40,
        height: 50,
        margin: "0px",
        paddingLeft: "15px",
        paddingRight: "15px"
      }}
    />
  );
};

const Select: FC<CustomSelectProps> = (props) => {
  return (
    <CustomSelect
      {...props}
      fullWidth={true}
      sx={{
        color: baseStyles.color.blue.primary40,
        height: 50,
        margin: "0px",
        paddingLeft: "15px",
        paddingRight: "15px"
      }}
    />
  );
};

export const AnalysisFiltersModal: FC<AnalysisFiltersModalProps> = ({
  open,
  filters,
  loading,
  resultsCount,
  onClose,
  onChangeFilters
}) => {
  const { t } = useTranslation();
  
  const { statusCode, analysisTypeCode, clientRef, clientName, ref, date } = filters;

  const handleChangeFilters = (key: keyof GetManyAnalysisQueryParams, value: string) => {
    onChangeFilters(key, value);
  };

  const ANALYSIS_STATUS_FILTER_ITEMS: CustomRadioGroupItem[] = [
    { label: t('all'), value: "" },
    { label: t('waiting'), value: "WAITING" },
    { label: t('done'), value: "DONE" },
    { label: t('canceled'), value: "CANCELED" },
    { label: t('failed'), value: "FAILED" }
  ];

  return (
    <CustomModal
      handleClose={onClose}
      open={open}
      title={t('filters')}
      width="610px"
      sx={{ paddingX: "0px" }}
      headerSx={{ paddingX: "30px" }}
      closeButton={true}
    >
      <Stack gap="30px" paddingX="30px">
        <Line label={t('status') + ': '}>
          <CustomRadioGroup
            items={ANALYSIS_STATUS_FILTER_ITEMS}
            onChange={(value) => handleChangeFilters("statusCode", value)}
            selectedValue={statusCode || ""}
          />
        </Line>
        <Line label={t('clientId') + ': '}>
          <Input
            type="text"
            placeholder={`${t('clientId')}...`}
            value={clientRef || ""}
            onchange={(e) => handleChangeFilters("clientRef", e.target.value)}
          />
        </Line>
        <Line label={`${t('analysisReference')}: `}>
          <Input
            type="text"
            placeholder={`${t('analysisReference')}... `}
            value={ref || ""}
            onchange={(e) => handleChangeFilters("ref", e.target.value)}
          />
        </Line>
        <Line label={`${t('clientName')}: `}>
          <Input
            type="text"
            placeholder={`${t('clientName')}... `}
            value={clientName || ""}
            onchange={(e) => handleChangeFilters("clientName", e.target.value)}
          />
        </Line>
        <Line label={`${t('documentNature')}: `}>
          <Select
            options={[
              { element: t('bankAccount'), value: "BANK" },
              { element: t('mobileMoneyAccount'), value: "MOBILE_MONEY" }
            ]}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleChangeFilters("analysisTypeCode", e.target.value)
            }
            value={analysisTypeCode || ""}
          />
        </Line>
        <Line label={`${t('analysisDate')}: `}>
          <StyledCustomReactDatePicker
            onChange={(value) => {
              handleChangeFilters("date", value ? dayjs(value).format("YYYY-MM-DD") : "");
            }}
            value={date ? dayjs(date).format("DD/MM/YYYY") : ""}
            placeholderText={`${t('analysisDate')}... `}
            withIcon={false}
          />
        </Line>
        <CustomButton sx={{ height: "60px", gap: "10px" }} onClick={onClose}>
          <Typo16 weight="medium" color="white">
            {`${resultsCount} ${t('results')}`}
          </Typo16>
          {loading && <CircularProgress color="inherit" size={22} />}
        </CustomButton>
      </Stack>
    </CustomModal>
  );
};
