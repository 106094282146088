import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const CloseIcon14: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.blue.primary,
  strokeWidth = 2
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M1.1543 12.923L13.0003 1.07705"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1 1.077L12.846 12.923"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CloseIcon12: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.white,
  strokeWidth = 2
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M1.12842 10.9358L11.0001 1.06417"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1 1.06418L10.8717 10.9358"
        stroke={stroke}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
      />
    </svg>
  );
};
