import {
  IconInfoContainer,
  contentContainerStyles,
  headerStyles,
  iconContainerStyles,
  styles
} from "./styles";

import { SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { InfoCircleIcon18 } from "assets/icons/InfoCircleIcon";
import { MouseOverPopover } from "components/MouseOverPopover";
import { Typo16 } from "components/Typography/Typo16";
import { Typo16Bold } from "components/Typography/Typo16Bold";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";

export interface AnalysisResultCardData {
  label: string;
  value: string;
  description: string;
  Icon: () => JSX.Element;
}

export interface AnalysisResultCardProps extends AnalysisResultCardData {
  sx?: SxProps;
  iconContainerSx?: SxProps;
  headerSx?: SxProps;
  contentContainerSx?: SxProps;
  labelSx?: SxProps;
  valueSx?: SxProps;
}

export const AnalysisResultCard: FC<AnalysisResultCardProps> = ({
  label,
  value,
  description,
  sx = {},
  iconContainerSx = {},
  headerSx = {},
  contentContainerSx = {},
  labelSx = {},
  valueSx = {},
  Icon
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...styles, ...(sx as any) }}>
      <Box sx={{ ...headerStyles, ...(headerSx as any) }}>
        <Box sx={{ ...iconContainerStyles, ...(iconContainerSx as any) }}>
          <Icon />
        </Box>
        <MouseOverPopover
          Element={(props) => (
            <IconInfoContainer {...props}>
              <InfoCircleIcon18 />
            </IconInfoContainer>
          )}
        >
          {description}
        </MouseOverPopover>
      </Box>
      <Box sx={{ ...(contentContainerStyles as any), ...(contentContainerSx as any) }}>
        <Typo16 weight="medium" color={baseStyles.color.blue.primary60} sx={labelSx}>
          {label}
        </Typo16>
        <Typo16Bold color="primary-blue" sx={{ wordBreak: "break-all", ...valueSx }}>
          {value || t("notAvailable")}
        </Typo16Bold>
      </Box>
    </Box>
  );
};
