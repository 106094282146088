import { Menu, MenuItem, SxProps } from "@mui/material";

import { FC } from "react";
import { Typo16 } from "components/Typography/Typo16";
import { menuItemStyles } from "./styles";

export interface CustomMenuItem {
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

export interface CustomMenuProps {
  items: CustomMenuItem[];
  anchorEl: HTMLElement | null;
  width?: number;
  sx?: SxProps;
  itemSx?: SxProps;
  onClose: () => void;
}

export const CustomMenu: FC<CustomMenuProps> = ({
  items,
  anchorEl,
  width,
  sx = {},
  itemSx = {},
  onClose
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{ ".MuiMenu-paper": { marginTop: "5px" }, ...(sx as any) }}
      MenuListProps={{
        style: {
          width,
          padding: 0,
          borderRadius: 10,
          boxShadow: `0px 0px 20px 0px rgba(0, 0, 0, 0.08)`
        }
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {items.map(({ label, disabled, onClick }) => (
        <MenuItem
          key={label}
          sx={{ ...menuItemStyles, ...(itemSx as any) }}
          disabled={disabled}
          onClick={() => {
            onClose();
            onClick();
          }}
        >
          <Typo16 weight="medium">{label}</Typo16>
        </MenuItem>
      ))}
    </Menu>
  );
};
