import { Avatar, Box, CircularProgress, SxProps } from "@mui/material";
import { CameraIcon } from "assets/icons/CameraIcon";
import { CustomSnackbar } from "components/CustomSnackbar/CustomSnackbar";
import { HiddenInputFile } from "components/HiddenInputFile";
import { GLOBAL_ERROR_MSG } from "constants/error";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useUpdateUserMutation } from "store/api";
import { adaptAnalysisFile } from "types/analysis";
import { baseStyles } from "utils/constants/baseStyles";
import { cameraContainerStyles, loadingContainerStyles, userAvatarContainerStyles } from "./styles";

export interface UserAvatarProps {
  user?: UserInfo | null;
  sx?: SxProps;
  avatarSx?: SxProps;
  withInput?: boolean;
  size?: number;
  fontSize?: number;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  user,
  sx = {},
  avatarSx = {},
  withInput = false,
  size = 126,
  fontSize = 40
}) => {
  const [src, setSrc] = useState("");
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [displayError, setDisplayError] = useState(false);

  const handleChangeAvatar = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const adaptedFile = await adaptAnalysisFile(e.target.files[0]);
        setSrc(adaptedFile.base64data);
        if (user) {
          updateUser({ id: user.id, profilePicture: adaptedFile.base64data })
            .unwrap()
            .catch(() => {
              setDisplayError(true);
              setTimeout(() => {
                setDisplayError(false);
              }, 3000);
            });
        }
      }
    },
    [updateUser, user]
  );

  React.useEffect(() => {
    if (!src && user?.profilePicture) {
      setSrc(`${process.env.REACT_APP_BACK_URL}${user.profilePicture.path}`);
    }
  }, [user?.profilePicture, src]);

  return (
    <>
      <Box sx={{ ...userAvatarContainerStyles(size), ...(sx as any) }}>
        {withInput && (
          <>
            {isLoading ? (
              <Box sx={loadingContainerStyles}>
                <CircularProgress size={24} sx={{ color: baseStyles.color.white, zIndex: 4 }} />
              </Box>
            ) : (
              <Box sx={cameraContainerStyles}>
                <CameraIcon />
                <HiddenInputFile
                  onChange={handleChangeAvatar}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </Box>
            )}
          </>
        )}
        <Avatar
          sx={{
            width: size,
            height: size,
            fontSize: fontSize,
            position: "relative",
            zIndex: 0,
            ...avatarSx
          }}
          alt={user?.fullname || ""}
          src={src || "/static/images/avatar/2.jpg"}
        ></Avatar>
      </Box>
      <CustomSnackbar open={displayError} severity="error" message={GLOBAL_ERROR_MSG} />
    </>
  );
};
