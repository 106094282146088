import { Box, styled } from "@mui/material";
import { CustomReactDateRangePicker } from "components/InputField";
import dayjs from "dayjs";
import { useWidth } from "hooks/useWidth";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectStatsState, setStatsFilters } from "store/slices";
import { flexColumn } from "utils/constants/baseStyles";

const StyledCustomReactDateRangePicker = styled(CustomReactDateRangePicker)`
  & > div {
    max-width: 404px;
  }

  & div.react-datepicker__tab-loop {
    & > div.react-datepicker-popper {
      margin-top: 15px;
      z-index: 10;
      padding: 12.5px !important;

      & .react-datepicker {
        gap: 12.5px !important;

        & > div.react-datepicker__month-container {
          max-width: 395px !important;
          padding: 10px !important;
        }
      }
    }
  }
`;

export const StatsDatesFilters = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector(selectStatsState);
  const { endDate, startDate } = filters;

  const calendarRefContainer = useRef<HTMLDivElement>(null);
  const { width: calendarWidth } = useWidth(calendarRefContainer);

  const handleChangeStartDate = (date: Date | null) => {
    dispatch(
      setStatsFilters({ ...filters, startDate: date ? dayjs(date).format("YYYY-MM-DD") : null })
    );
  };

  const handleChangeEndDate = (date: Date | null) => {
    dispatch(
      setStatsFilters({ ...filters, endDate: date ? dayjs(date).format("YYYY-MM-DD") : null })
    );
  };

  return (
    <Box
      sx={{
        ...flexColumn,
        width: "100%",
        maxWidth: "828px",
        marginLeft: "38px",
        marginRight: "20px"
      }}
      ref={calendarRefContainer}
    >
      <StyledCustomReactDateRangePicker
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        popperWidth={calendarWidth}
      />
    </Box>
  );
};
