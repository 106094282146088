import { Styles } from "types";
import { baseStyles, flexColumn, flexRow } from "utils/constants/baseStyles";

export const styles: Styles = {
  managingAccountContainer: {
    ...flexRow,
    height: "100%",
    overflowX: "hidden"
  },
  listUsersContainer: {
    ...flexColumn,
    width: "100%",
    padding: "40px",
    gap: "40px",
    overflowX: "hidden"
  },
  bankInfoContainer: {
    ...flexRow,
    alignItems: "center",
    gap: "20px"
  },
  statusSelect: {
    backgroundColor: baseStyles.color.green.primary,
    border: "none",
    padding: 0,
    "& .MuiSelect-select": {
      color: baseStyles.color.white,
      paddingTop: "3px",
      paddingBottom: "3px",
      "&:focus": {
        outline: "none"
      }
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "5px 5px 0 0"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },

  bankCrud: {
    ...flexRow,
    color: baseStyles.color.blue.primary
  }
};
