import { Typography } from "@mui/material";
import { FC } from "react";
import { baseStyles, fontMontserrat } from "utils/constants/baseStyles";
import { TypoProps, getColor } from "./utils";

export const Typo18Bold: FC<TypoProps> = ({
  children,
  color = baseStyles.color.blue.primary,

  sx = {}
}) => {
  return (
    <Typography
      sx={{
        ...(fontMontserrat as any),
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "140%",
        color: getColor(color),
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};
