import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Pagination, PaginationItem, PaginationProps, SxProps } from "@mui/material";
import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";

export interface CustomPaginationProps extends PaginationProps {
  sx?: SxProps;
  paginationItemSx?: SxProps;
}

const paginationItemSelectedStyles: SxProps = {
  backgroundColor: baseStyles.color.blue.primary,
  color: baseStyles.color.white
};

const styles: SxProps = {
  marginRight: "auto",
  ".MuiPagination-ul": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px",
    ".Mui-selected, .Mui-selected:hover": {
      ...paginationItemSelectedStyles
    }
  }
};

const paginationItemStyles: SxProps = {
  margin: "0px",
  borderRadius: "5px",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  letterSpacing: "0.5px",
  color: baseStyles.color.gray.primary40,
  backgroundColor: baseStyles.color.white,
  ":hover": { ...paginationItemSelectedStyles }
};

export const CustomPagination: FC<CustomPaginationProps> = ({
  sx = {},
  paginationItemSx = {},
  variant = "outlined",
  size = "large",
  shape = "rounded",
  ...props
}) => {
  return (
    <Pagination
      {...props}
      sx={{ ...styles, ...sx }}
      variant={variant}
      shape={shape}
      size={size}
      renderItem={(item) => {
        return (
          <PaginationItem
            {...item}
            sx={{ ...paginationItemStyles, ...paginationItemSx }}
            slots={{ previous: ChevronLeft, next: ChevronRight }}
          />
        );
      }}
    />
  );
};
