import styled from "@emotion/styled";
import { CSSProperties, ChangeEvent, FC } from "react";

export interface HiddenInputFileProps {
  style?: CSSProperties;
  multiple?: boolean;
  accept?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const VisuallyHiddenInput = styled("input")({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute",
  inset: 0,
  opacity: 0,
  cursor: "pointer",
  "&::-webkit-file-upload-button": {
    visibility: "hidden"
  }
});

export const HiddenInputFile: FC<HiddenInputFileProps> = ({
  style = {},
  multiple = false,
  accept,
  onChange
}) => {
  return (
    <VisuallyHiddenInput
      style={style}
      title=""
      type="file"
      multiple={multiple}
      onChange={onChange}
      accept={accept}
    />
  );
};
