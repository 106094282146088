import { SxProps } from "@mui/material";
import { baseStyles, flexCenter } from "utils/constants/baseStyles";

export const btnAddBankContainer: SxProps = {
  ...flexCenter,
  backgroundColor: baseStyles.color.gray.background,
  position: "relative",
  cursor: "pointer",
  width: "200px",
  padding: "20px 0px"
};

export const btnAddBank: SxProps = {
  ...flexCenter,
  flexDirection: "column",
  gap: "5px",
  padding: "0px 20px",
  backgroundColor: baseStyles.color.blue.background,
  color: baseStyles.color.white,
  border: `1px solid ${baseStyles.color.blue.primary10}`,
  borderRadius: "10px",
  textAlign: "center",
  width: "140px",
  minHeight: "120px"
};
