import { OutlinedInput, SxProps } from "@mui/material";
import { Typo12 } from "components/Typography/Typo12";
import { ChangeEvent, ReactNode, forwardRef } from "react";
import { baseStyles, fontMontserrat, typoCommonStyles } from "utils/constants/baseStyles";

export type CustomInputProps = {
  id?: string;
  startAdornment?: ReactNode;
  endAdornment?: any;
  placeholder?: string;
  type?: string;
  fullWidth?: boolean;
  sx?: SxProps;
  isValid?: boolean;
  value?: any;
  error?: boolean;
  required?: boolean;
  multiline?: boolean;
  maxRows?: string | number;
  minRows?: string | number;
  height?: string;
  name?: string;
  onchange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const CustomInput = forwardRef((props: CustomInputProps, ref) => {
  const {
    id,
    startAdornment,
    endAdornment,
    placeholder,
    type = "text",
    onchange,
    fullWidth = false,
    sx = {},
    isValid = true,
    value,
    error = false,
    required,
    multiline,
    maxRows,
    minRows,
    height = "24px",
    name
  } = props;

  const styles = {
    ...(fontMontserrat as any),
    ...(typoCommonStyles as any),
    fontSize: "14px",
    fontWeight: "400",
    paddingLeft: 1,
    paddingTop: "7px",
    paddingBottom: "7px",
    ".MuiInputBase-input": {
      height,
      padding: 0,
      fontWeight: "400",
      color: isValid && !error ? baseStyles.color.blue.primary : baseStyles.color.error.main
    },
    ".MuiOutlinedInput-notchedOutline": {
      outline: "none",
      border: "none"
    },
    marginBottom: 2,
    borderRadius: "10px",
    backgroundColor:
      isValid && !error ? baseStyles.color.blue.primary3 : baseStyles.color.error.main3,
    border: "2px solid ",
    borderColor: isValid && !error ? baseStyles.color.blue.primary10 : baseStyles.color.error.bg,
    outline: "none",
    width: fullWidth ? "100%" : "auto"
  };

  return (
    <>
      <OutlinedInput
        ref={ref}
        id={id}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        placeholder={placeholder}
        sx={{ ...styles, ...sx }}
        type={type}
        onChange={onchange}
        value={value}
        error={error}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        name={name}
        required={required}
      />
      {required && error && (
        <Typo12 color={baseStyles.color.error.main}>Ce champ est requis</Typo12>
      )}
    </>
  );
});
