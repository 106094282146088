import { SxProps } from "@mui/material";
import { Styles } from "types";
import {
  baseStyles,
  breakpoints,
  flexColumn,
  fontPoppins,
  titleCommonStyles
} from "utils/constants/baseStyles";

export const itemCommonStyles: SxProps = {
  ...flexColumn,
  justifyContent: "center",
  width: "100%",
  minHeight: "200px",
  borderRadius: "32px",
  padding: "25px",
  border: `1.5px solid ${baseStyles.color.white20}`,
  backdropFilter: "blur(10px)",
  zIndex: 1
};

export const numberStyles: SxProps = {
  ...(fontPoppins as any),
  fontWeight: 700,
  letterSpacing: "0.5px"
};

export const styles: Styles = {
  container: {
    ...flexColumn,
    position: "relative",
    justifyContent: "space-between",
    gap: "30px",
    textAlign: "center",
    width: "23%",
    maxWidth: "350px",
    minWidth: "280px",
    background: `url('images/globales-stats-bg.jpeg'), ${baseStyles.color.blue.primary}`,
    backgroundPosition: "50% 0",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowY: "auto",
    padding: "40px 20px",
    borderLeft: `1px solid ${baseStyles.color.blue.primary10}`,
    [breakpoints("xl").min]: {
      padding: "40px",
      minWidth: "350px"
    }
  },
  containerBg: {
    position: "absolute",
    content: "' '",
    zIndex: 0,
    inset: 0,
    backgroundColor: baseStyles.color.blue.primary,
    opacity: 0.8
  },
  totalAnalysisContainer: {
    ...itemCommonStyles,

    background: baseStyles.color.blue.primary30
  },
  totalAnalysis: {
    ...flexColumn,
    gap: "25px"
  },
  totalAnalysisNumber: {
    ...titleCommonStyles,
    ...(numberStyles as any),
    fontSize: "40px",
    color: baseStyles.color.white
  },
  analysisStatContainer: {
    ...itemCommonStyles
  },
  analysisStatRoundContainer: {
    marginBottom: "25px"
  },
  analysisStatNumber: {
    ...numberStyles,
    fontSize: "32px",
    marginBottom: "15px",
    lineHeight: "23px"
  }
};
