import {
  baseStyles,
  breakpoints,
  flexCenter,
  flexColumn,
  fontMontserrat,
  typoCommonStyles
} from "utils/constants/baseStyles";

import { SxProps } from "@mui/material";
import bg from "assets/images/background-login.jpg";
import loginCardBg from "assets/images/login-card-bg.png";

export const styles = {
  mainContainer: {
    height: "100vh"
  } as SxProps,
  imageBg: {
    backgroundImage: `url("${bg}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  } as SxProps,
  leftPart: {
    ...flexCenter,
    backgroundColor: baseStyles.color.blue.background80,
    padding: "80px 0 110px",
    height: "100%"
  } as SxProps,
  platformPresentation: {
    ...flexColumn,
    justifyContent: "space-between",
    gap: "10px",
    width: "80% ",
    maxWidth: "649px",
    height: "100%",
    maxHeight: "787px",
    borderRadius: "32px",
    paddingTop: "98px",
    paddingBottom: "92px",
    paddingLeft: "19.5px",
    paddingRight: "19.5px",
    background: `linear-gradient(111deg, rgba(255, 255, 255, 0.30) -4.87%, rgba(255, 255, 255, 0.00) 103.95%)`,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(20px)",
    [breakpoints("tablet").min]: {
      paddingLeft: "40px",
      background: `url("${loginCardBg}"), linear-gradient(111deg, rgba(255, 255, 255, 0.30) -4.87%, rgba(255, 255, 255, 0.00) 103.95%)`,
      backgroundSize: "200%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat"
    },
    [breakpoints("lg").min]: {
      paddingLeft: "79.5px"
    }
  } as SxProps,
  platformPresentationTextBox: {
    ...(fontMontserrat as any),
    fontWeight: "500",
    color: baseStyles.color.white,
    fontSize: "40px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    [breakpoints("tablet").min]: {
      fontSize: "50px",
      lineHeight: "75px"
    },
    [breakpoints("xl").min]: {
      fontSize: "60px",
      lineHeight: "80px"
    }
  } as SxProps,
  credit: {
    color: baseStyles.color.green.primary,
    fontWeight: "700"
  } as SxProps,
  bottomText: {
    color: baseStyles.color.white,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    letterSpacing: "0.5px",
    [breakpoints("tablet").min]: {
      fontSize: "18px",
      lineHeight: "27px"
    },
    [breakpoints("xl").min]: {
      fontSize: "24px",
      lineHeight: "36px"
    }
  } as SxProps,
  devise: {
    fontWeight: "700"
  } as SxProps,
  rightPart: {
    ...(fontMontserrat as any),
    paddingY: "92px",
    paddingX: "20px",
    [breakpoints("md").min]: {
      paddingX: "40px"
    },
    [breakpoints("tablet").min]: {
      paddingX: "79.5px"
    },
    [breakpoints("figma").min]: {
      paddingX: "200px"
    }
  } as SxProps,
  rightPartContainer: {
    ...flexColumn,
    justifyContent: "center",
    gap: "30px",
    width: "100%",
    maxWidth: "464px",
    height: "100%"
  } as SxProps,
  logoContainer: {
    paddingBottom: "20px"
  } as SxProps,
  welcome: {
    ...(fontMontserrat as any),
    color: baseStyles.color.blue.primary,
    fontSize: "36px",
    fontWeight: "500",
    lineHeight: "38px",
    letterSpacing: "0.5px"
  } as SxProps,
  welcomeTextAndErrorContainer: {
    ...flexColumn
  },
  welcomeText: {
    ...(typoCommonStyles as any),
    color: baseStyles.color.blue.primary60,
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "30px"
  } as SxProps,
  alertStyles: {
    marginBottom: "40px"
  } as SxProps,
  inputStyles: {
    marginBottom: 0,
    padding: "11px 15px"
  } as SxProps,
  inputBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  } as SxProps,
  forgotPasswordLink: {
    textDecoration: "none",
    cursor: "pointer"
  } as SxProps,
  loginButton: {
    backgroundColor: baseStyles.color.green.primary,
    color: baseStyles.color.white,
    height: "60px",
    marginTop: "40px",
    borderRadius: "10px",
    textTransform: "none",
    padding: "0px 30px",
    ":hover": {
      backgroundColor: baseStyles.color.green.dark
    }
  } as SxProps,
  loginButtonText: {
    ...flexCenter,
    color: baseStyles.color.white
  } as SxProps,
  loadingStyles: {
    marginLeft: "10px"
  } as SxProps,
  switchLanguageContainer: {
    position: "absolute",
    top: "0px",
    right: "0px"
  } as SxProps
};
