import { Box, Skeleton } from "@mui/material";
import { GetAnalysisSummaryQueryParams, useGetAnalyzesSummaryQuery } from "store/api";
import React, { useContext, useState } from "react";

import AnalysisSummary from "./AnalysisSummary";
import AnalysisTimeSelector from "../AnalysisTimeSelector/AnalysisTimeSelector";
import { CustomError } from "components/CustomError";
import { UserContext } from "providers/UserProvider";
import { baseStyles } from "utils/constants/baseStyles";
import { getArrayOfNumber } from "utils";
import { itemContainerStyles } from "./styles";
import { selectTimeSelectorState } from "store/slices";
import { useAppSelector } from "store/hooks";

export const AnalysisSummaryConnected = () => {
  const { currentUser } = useContext(UserContext);

  const [queryParams, setQueryParams] = useState<GetAnalysisSummaryQueryParams>({
    startDate: "",
    endDate: ""
  });

  const { data, error, isLoading, isFetching } = useGetAnalyzesSummaryQuery(queryParams);
  const loading = isLoading || isFetching;
  
  const handleChangeFilters = (name: keyof GetAnalysisSummaryQueryParams, value: string) => {
    setQueryParams((prev) => ({ ...prev, [name]: value }));
  };

  // Add default filter by responsibleId
  React.useEffect(() => {
    setQueryParams((prev) => ({ ...prev, responsibleId: currentUser?.id }));
  }, [currentUser]);
  
  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
          {getArrayOfNumber(4).map((i) => (
            <Box key={i} sx={{ ...itemContainerStyles, flexDirection: "column" }}>
              <Skeleton width={60} height={28} animation="wave" />
              <Skeleton width="100%" height={14} animation="wave" />
            </Box>
          ))}
        </Box>
      );
    }

    if (error) {
      return (
        <CustomError error="Une erreur est survenue lors de la récupération des statistiques" />
      );
    }

    return (
      <AnalysisSummary
        total={data?.totalCount || 0}
        done={data?.doneCount || 0}
        failed={data?.failedCount || 0}
        canceled={data?.canceledCount || 0}
      />
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          flexWrap: "wrap",
          gap: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid " + baseStyles.color.blue.primary10
        }}
      >
        {/* Filter time */}
        <AnalysisTimeSelector filters={queryParams} onChangeFilters={handleChangeFilters} />
      </Box>
      <Box sx={{ paddingTop: "30px" }}>
        {/* Summary of analysis */}
        {renderContent()}
      </Box>
    </Box>
  );
};
