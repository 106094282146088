import { baseStyles, breakpoints, flexCenter } from "../../utils/constants/baseStyles";

import { Box } from "@mui/system";
import { FC } from "react";
import { SxProps } from "@mui/material";
import { Typo14 } from "components/Typography/Typo14";
import { Typo18Bold } from "components/Typography/Typo18Bold";
import { useTranslation } from "react-i18next";

export interface AnalysisInfoCardData {
  label: string;
  value: string | number;
  bgColor: string;
  bgImageTop: string;
  bgImageBottom: string;
  Icon: () => JSX.Element | null;
}

export interface AnalysisInfoCardProps {
  data: AnalysisInfoCardData;
  sx?: SxProps;
}

export const styles = (bgColor: string): SxProps => ({
  ...flexCenter,
  flexDirection: "column",
  rowGap: "30px",
  width: "211.5px",
  height: "200px",
  borderRadius: "10px",
  backgroundColor: bgColor,
  position: "relative",
  overflow: "hidden",
  boxSizing: "border-box",
  [breakpoints("laptop").min]: {
    width: "302px"
  }
});

export const iconContainerStyles: SxProps = {
  ...flexCenter,
  width: "60px",
  height: "60px",
  borderRadius: "50%"
};

export const infoContainerStyles: SxProps = {
  ...flexCenter,
  flexDirection: "column",
  rowGap: "15px",
  maxWidth: "212px",
  textAlign: "center"
};

export const AnalysisInfoCard: FC<AnalysisInfoCardProps> = ({ data, sx = {} }) => {
  const { t } = useTranslation();
  
  const { Icon, bgColor, bgImageBottom, bgImageTop, label, value } = data;

  const topBoxStyles: SxProps = {
    width: "299px",
    height: "225px",
    borderRadius: "299px",
    position: "absolute",
    left: "-136.5px",
    top: "-102px",
    backgroundColor: bgImageTop
  };

  const bottomBoxStyles: SxProps = {
    width: "193px",
    height: "146px",
    position: "absolute",
    right: "-50px",
    bottom: "-96px"
  };

  return (
    <Box sx={{ ...styles(bgColor), ...(sx as any) }}>
      <Box sx={iconContainerStyles}>
        <Icon />
      </Box>
      <Box sx={infoContainerStyles}>
        <Typo18Bold sx={{ color: baseStyles.color.blue.primary }}>
          {value || t('notAvailable')}
        </Typo18Bold>
        <Typo14 sx={{ minHeight: "36px", color: baseStyles.color.blue.primary60, paddingX: "5px" }}>
          {label}
        </Typo14>
      </Box>
      <Box sx={topBoxStyles} />
      <Box sx={bottomBoxStyles}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="143"
          height="50"
          viewBox="0 0 143 50"
          fill="none"
        >
          <ellipse cx="96.5" cy="73" rx="96.5" ry="73" fill={bgImageBottom} fill-opacity="1" />
        </svg>
      </Box>
    </Box>
  );
};
