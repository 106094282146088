export const buildFullname = (firstname?: string, lastname?: string) => {
    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    } else if (firstname) {
      return `${firstname}`;
    } else if (lastname) {
      return `${lastname}`;
    } else {
      return '--';
    }
};