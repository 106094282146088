import { SxProps } from "@mui/material";

export const containerStyles: SxProps = {
  paddingY: "14px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: "20px",
  overflowX: "auto",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none"
  }
};
