import { Box, CircularProgress, Stack, SxProps } from "@mui/material";
import { CustomMenu, CustomMenuItem } from "components/CustomMenu";
import { FC, useState } from "react";

import { AltArrowDownIcon } from "assets/icons/AltArrowDownIcon";
import { CustomButton } from "components/Button/CustomButton";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { flexCenter } from "utils/constants/baseStyles";
import { getArrayOfNumber } from "utils";

export interface AnalysisSubHeadProps {
  title: string;
  subTitle: string;
  btnText: string;
  btnIcon: any;
  menus: CustomMenuItem[];
  sx?: SxProps;
  disabledBtn?: boolean;
  btnLoading?: boolean;
  btnWidth?: number;
}

export const AnalysisSubHead: FC<AnalysisSubHeadProps> = ({
  title,
  subTitle,
  btnText,
  btnIcon,
  sx = {},
  disabledBtn,
  btnLoading,
  btnWidth = 309,
  menus
}) => {
  const [btnMenuAnchorEl, setBtnMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBtnMenuAnchorEl(event.currentTarget);
  };

  const handleCloseBtnMenu = () => {
    setBtnMenuAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
        ...sx
      }}
    >
      <Stack gap="10px">
        <Typo24Bold sx={{ lineHeight: "18px", letterSpacing: "0.5px" }}>{title}</Typo24Bold>
        <Typo14Medium color="primary-blue-60">{subTitle}</Typo14Medium>
      </Stack>
      <Box>
        <CustomButton
          sx={{
            height: "60px",
            paddingLeft: "20px",
            paddingRight: "0px",
            letterSpacing: "0.5px",
            width: `${btnWidth}px`
          }}
          icon={btnIcon}
          disabled={disabledBtn}
          onClick={handleBtnClick}
        >
          <Typo16
            color="white"
            weight="medium"
            sx={{ paddingRight: "20px", minWidth: "fit-content" }}
          >
            {btnText}
          </Typo16>
          <Box sx={{ ...flexCenter, gap: "0px", alignSelf: "stretch" }}>
            {getArrayOfNumber(2).map((i) => (
              <Box
                key={i}
                sx={{
                  width: "1px",
                  height: "60px",
                  backgroundColor: i === 0 ? "#0BB567" : "#39F39E"
                }}
              />
            ))}
          </Box>
          <Box sx={{ ...flexCenter, width: "54px" }}>
            {btnLoading ? <CircularProgress color="inherit" size={24} /> : <AltArrowDownIcon />}
          </Box>
        </CustomButton>
        <CustomMenu
          items={menus}
          anchorEl={btnMenuAnchorEl}
          width={btnWidth}
          onClose={handleCloseBtnMenu}
        />
      </Box>
    </Box>
  );
};
