import { FC, createContext, useEffect, useState } from "react"

type SupportTicketContextType = {
    allSupportTickets: Array<SupportTicket> | null;
    setAllSupportTickets: (data: Array<SupportTicket>) => any;
    loadingAllSupportTickets: {
        get: boolean;
        set: (value: boolean) => any;
    },
    currentSupportTicket: {
        setById(selectedSupportId: any): unknown;
        get?: SupportTicket;
        set: (value?: SupportTicket) => any;
    },
    supportTicketMessages: {
        get: Array<SupportTicketMessage>| null;
        set: (value: Array<SupportTicketMessage>) => any;
        loading: boolean;
        setLoading: (value: boolean) => any;
    },
    resetSupportTickets: number;
    reloadSupportTickets: () => void;
    unseenMessagesCount: number;
    setUnseenMessagesCount: (count: number) => void;
    resetUnseenMessagesCount: number;
    reloadUnseenMessagesCount: () => void;
}

export const SupportTicket = createContext<SupportTicketContextType>({
    allSupportTickets: null,
    setAllSupportTickets: (tickets: any) => {
    },
    loadingAllSupportTickets: {
        get: true,
        set: (value: boolean) => {}
    },
    currentSupportTicket: {
        get: undefined,
        set: (ticket?: SupportTicket) => { },
        setById(selectedSupportId) { },
    },
    supportTicketMessages: {
        get: null,
        set: (value: Array<SupportTicketMessage>) => { },
        loading: true,
        setLoading(value) { },
    },
    resetSupportTickets: 1,
    reloadSupportTickets: () => { },
    unseenMessagesCount: 0,
    setUnseenMessagesCount: (count: number) => { },
    resetUnseenMessagesCount: 0,
    reloadUnseenMessagesCount: () => {}
});

interface SupportTicketProviderProps {
    children: any
}

export const SupportTicketProvider: FC<SupportTicketProviderProps> = (props: SupportTicketProviderProps) => {
    const [allSupportTicketsState, setAllSupportTicketsState] = useState<Array<SupportTicket> | null>(null);
    const [loadingAllSupportTickets, setLoadingAllSupportTickets] = useState<boolean>(true);
    const [loadingTicketMessages, setLoadingTicketMessages] = useState<boolean>(true);
    const [currentSupportTicketsState, setCurrentSupportTicketsState] = useState<SupportTicket | undefined>(undefined);
    const [supportTicketMessagesState, setSupportTicketMessagesState] = useState<Array<SupportTicketMessage> | null>(null);
    const [resetSupportTicketState, setResetSupportTicketState] = useState<number>(1);
    const [resetUnseenMessagesCountState, setResetUnseenMessagesCountState] = useState<number>(1);
    
    const [unseenMessagesCountState, setUnseenMessagesCountState] = useState<number>(0);
    
    const { children } = props;

    useEffect(() => {
        if (allSupportTicketsState) {
            setLoadingAllSupportTickets(false);
        }
    }, [allSupportTicketsState])
    useEffect(() => {
        console.log("State of supportTicketsMessageState", supportTicketMessagesState);
        if (supportTicketMessagesState) {
            setLoadingTicketMessages(false);
        }
    }, [supportTicketMessagesState])

    return (
        <SupportTicket.Provider value={{
            allSupportTickets: (() => {
                console.log("# Getting all support tickets");
                return allSupportTicketsState
            })(),
            setAllSupportTickets: (data: SupportTicket[]) => {
                console.log("# Setting all support tickets");
                console.log('test', data);
                setAllSupportTicketsState(data);
            },
            loadingAllSupportTickets: {
                get: (() => {
                    console.log("# Getting loadingAllSupportTickets");
                    return loadingAllSupportTickets;
                })(),
                set: (value: boolean) => {
                    console.log("# Setting loadingAllSupportTickets");
                    console.log(value);
                    setLoadingAllSupportTickets(value);
                }
            },
            currentSupportTicket: {
                get: (() => {
                    console.log("# Getting currentSupportTicket");
                    return currentSupportTicketsState;
                })(),
                set: (value?: SupportTicket) => {
                    console.log("# Setting currentSupportTicket");
                    console.log(value);
                    setCurrentSupportTicketsState(value);
                },
                setById: (supportId: string | number) => {
                    console.log("# Setting loadingAllSupportTicketsById");
                    console.log(supportId);
                    setCurrentSupportTicketsState(allSupportTicketsState?.find((supportTicket) => supportId === supportTicket.id));
                }
            },
            supportTicketMessages: {
                get: (() => {
                    console.log("# Getting supportTicketMessages");
                    return supportTicketMessagesState;
                })(),
                set: (value: Array<SupportTicketMessage>) => {
                    console.log("# Setting supportTicketMessages");
                    console.log(value);
                    setSupportTicketMessagesState(value);
                },
                loading: loadingTicketMessages,
                setLoading: (loading) => (setLoadingTicketMessages(loading))
            },
            resetSupportTickets: resetSupportTicketState,
            reloadSupportTickets: () => { setResetSupportTicketState(resetSupportTicketState + 1) },
            unseenMessagesCount: unseenMessagesCountState,
            setUnseenMessagesCount: (count: number) => {
                setUnseenMessagesCountState(count)
            },
            resetUnseenMessagesCount: resetUnseenMessagesCountState,
            reloadUnseenMessagesCount: () => {
                setResetUnseenMessagesCountState(resetUnseenMessagesCountState + 1);
            }
        }}>
            {children}
        </SupportTicket.Provider>
    )
}

export default SupportTicketProvider;


