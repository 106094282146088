import { Box, Button, CircularProgress, Stack } from "@mui/material";
import {
  UpdateCurrentUserPayload,
  isAdmin,
  isChargeClientele,
  updateCurrentUser
} from "services/User/UserService";
import { baseStyles, typoCommonStyles } from "utils/constants/baseStyles";
import { useContext, useState } from "react";

import { BorderContainer } from "components/BorderContainer/BorderContainer";
import { CustomSnackbar } from "components/CustomSnackbar/CustomSnackbar";
import { GLOBAL_ERROR_MSG } from "constants/error";
import { Typo14 } from "components/Typography/Typo14";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { Typo16 } from "components/Typography/Typo16";
import { UpdatePassword } from "./UpdatePassword";
import { UpdatePersonalInfo } from "./UpdatePersonalInfo";
import { UserAvatar } from "./UserAvatar";
import { UserContext } from "providers/UserProvider";
import { getAxiosError } from "utils/api";
import { styles } from "pages/Login/styles";
import { useTranslation } from "react-i18next";

export const PersonalInfo = () => {
  const { t } = useTranslation();
  
  const { currentUser } = useContext(UserContext);
  const [state, setState] = useState({
    incorrectPassword: false,
    openSnackbarPassword: false,
    updateLoading: false,
    serverError: false
  });

  const { incorrectPassword, openSnackbarPassword, serverError, updateLoading } = state;

  const [userInfo, setUserInfo] = useState<UpdateCurrentUserPayload>({
    firstname: currentUser?.firstname || "",
    lastname: currentUser?.lastname || "",
    email: currentUser?.email || "",
    phone: currentUser?.phone || "",
    actualPassword: "",
    newPassword: ""
  });

  const isUserChargeClientele = currentUser?.groups && isChargeClientele(currentUser.groups);

  const performUpdate = () => {
    // Build the data
    // send the data over the service
    if (userInfo && currentUser) {
      // const payload : UpdateCurrentUserPayload = {
      //     firstname: userInfo.firstname,
      //     lastname: userInfo.lastname
      // }
      console.log("Updating user", userInfo);

      setState((prevState) => ({
        ...prevState,
        incorrectPassword: false,
        serverError: false,
        openSnackbarPassword: false,
        updateLoading: true
      }));

      updateCurrentUser(currentUser.id, userInfo)
        .then(
          (currentUser) => {
            console.log("currentUser", currentUser);
            // setCurrentUser(currentUser);
            // updateCurrentUser

            // show toast confirm that update is successfull
            setState((prevState) => ({ ...prevState, openSnackbarPassword: true }));
          },
          (error) => {
            const err = getAxiosError(error);
            if (err?.code === "ACTUAL_PASSWORD_WRONG") {
              setState((prevState) => ({ ...prevState, incorrectPassword: true }));
            } else {
              setState((prevState) => ({
                ...prevState,
                openSnackbarPassword: true,
                serverError: true
              }));
            }
          }
        )
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            openSnackbarPassword: true,
            serverError: true
          }));
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, updateLoading: false }));
        });
    }
  };

  return (
    <Box sx={{ maxWidth: "900px", width: "100%" }}>
      <Stack gap={"30px"} alignItems="center">
        {/* for admin */}
        {currentUser?.groups && isAdmin(currentUser.groups) && (
          <BorderContainer
            sx={{
              padding: "0px",
              border: "none",
              maxWidth: "508px"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ display: "flex", gap: "30px", width: "90%", alignItems: "center" }}>
                <UserAvatar user={currentUser} withInput={true} />
                <Stack>
                  <Typo16 weight="medium" sx={typoCommonStyles}>
                    {currentUser?.fullname}
                  </Typo16>
                  <Typo14 sx={{ ...typoCommonStyles, color: baseStyles.color.blue.primary60 }}>
                    Admin
                  </Typo14>
                </Stack>
              </Box>
            </Box>
          </BorderContainer>
        )}

        {/* for clientele */}
        {isUserChargeClientele && (
          <BorderContainer>
            <Box sx={{ display: "flex", gap: "30px", width: "100%", alignItems: "center" }}>
              <UserAvatar user={currentUser} withInput={true} />
              <Stack gap="10px">
                <Typo16 weight="medium" sx={typoCommonStyles}>
                  {currentUser?.fullname}
                </Typo16>
                <Typo14 sx={typoCommonStyles}>{t('accountManager')}</Typo14>
                <Typo14 sx={typoCommonStyles}>{t('memberSince')} --</Typo14>
              </Stack>
              <Box sx={{ marginLeft: "auto" }}>
                <Box
                  sx={{
                    width: "fit-content",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: baseStyles.color.blue.primary5
                  }}
                >
                  <Typo14 sx={typoCommonStyles} color={baseStyles.color.blue.primary60}>
                    {t('bank')}
                  </Typo14>
                  <Typo14Medium>
                    {currentUser?.bank?.name
                      ? currentUser?.bank.name + " - " + currentUser?.bank?.city
                      : "--"}
                  </Typo14Medium>
                </Box>
              </Box>
            </Box>
          </BorderContainer>
        )}

        {/* Info personnelle block */}
        {currentUser && (
          <UpdatePersonalInfo
            id={currentUser.id}
            firstname={userInfo?.firstname}
            lastname={userInfo?.lastname}
            phone={userInfo?.phone}
            email={userInfo?.email}
            setState={setUserInfo}
            onUpdate={(state: any) => {
              performUpdate();
            }}
          />
        )}

        {/* Password block */}
        <UpdatePassword
          onChange={(data: any) => {
            setUserInfo((oldState) => ({
              ...oldState,
              actualPassword: data.actualPassword,
              newPassword: data.newPassword
            }));
          }}
          incorrectPassword={incorrectPassword}
        />
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              ...styles.loginButton,
              margin: "0",
              padding: "0px 30px",
              height: "60px",
              maxWidth: "435px",
              width: "100%",
              gap: "10px"
            }}
            onClick={updateLoading ? undefined : performUpdate}
          >
            <Typo16 weight="medium" color={baseStyles.color.white} sx={typoCommonStyles}>
              {t('updateInformations')}
            </Typo16>
            {updateLoading && <CircularProgress size={18} color="inherit" />}
          </Button>
          <CustomSnackbar
            open={openSnackbarPassword}
            severity={serverError ? "error" : "success"}
            message={serverError ? GLOBAL_ERROR_MSG : t('updatedSuccessfully')}
          />
        </Box>
      </Stack>
    </Box>
  );
};
