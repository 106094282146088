export const ADMIN_PERMISSION = [
    'view_welcome',
    'view_messagerie',
    'view_managing_account',
    'view_setting_account',
    'view_statistique',
]

export const CLIENT_PERMISSION = [
    'view_welcome',
    'view_setting_account_client',
    'view_home_clientele',
    'view_support',
]