import { FC } from "react";

export const PieChartIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M6.22209 5.10105C6.66665 4.804 7.13344 4.54636 7.6171 4.32976C8.98898 3.71539 9.67491 3.4082 10.5875 3.9994C11.5 4.59061 11.5 5.56041 11.5 7.50001V9.00001C11.5 10.8856 11.5 11.8284 12.0858 12.4142C12.6716 13 13.6144 13 15.5 13H17C18.9396 13 19.9094 13 20.5006 13.9125C21.0918 14.8251 20.7846 15.511 20.1702 16.8829C19.9536 17.3666 19.696 17.8334 19.399 18.2779C18.3551 19.8402 16.8714 21.0578 15.1355 21.7769C13.3996 22.4959 11.4895 22.684 9.64665 22.3175C7.80383 21.9509 6.11109 21.0461 4.78249 19.7175C3.45389 18.3889 2.5491 16.6962 2.18254 14.8534C1.81598 13.0105 2.00412 11.1004 2.72315 9.36451C3.44218 7.62861 4.65982 6.14492 6.22209 5.10105Z"
        fill="white"
      />
      <path
        d="M21.446 7.56899C20.6342 5.5083 18.9917 3.86577 16.931 3.05397C15.3895 2.44668 14 3.84315 14 5.5V9.5C14 10.0523 14.4477 10.5 15 10.5H19C20.6569 10.5 22.0533 9.11054 21.446 7.56899Z"
        fill="#0CD97B"
      />
    </svg>
  );
};
