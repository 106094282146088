import { SxProps } from "@mui/material";
import { baseStyles, flexRow } from "utils/constants/baseStyles";

export const tableCellStyles = (isFirst?: boolean, isLast?: boolean): SxProps => ({
  paddingLeft: isFirst ? "0px" : "5px",
  paddingRight: isLast ? "0px" : "5px",
  paddingTop: "0px",
  paddingBottom: "15px",
  borderBottom: `1px solid ${baseStyles.color.blue.primary10}`
});

export const tableCellRowStyles = (isFirst?: boolean, isLast?: boolean): SxProps => ({
  ...tableCellStyles(isFirst, isLast),
  paddingTop: "15px"
});

export const userNameContainerStyles: SxProps = {
  ...flexRow,
  alignItems: "center",
  gap: "10px"
};
