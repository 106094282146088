import { AttachementIcon, FileIcon } from "components/Icons/SvgIcons";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { UpdateStatementParams, removeStatement, updateStatement } from "store/slices/statementsSlice";
import { getMobileMoneyCompanies, getStatementBanks } from "services/Bank/BankService";

import { CancelRounded } from "@mui/icons-material";
import { CustomInput } from "components/InputField";
import CustomSelect from "components/InputField/CustomSelect";
import { HiddenInputFile } from "components/HiddenInputFile";
import { StatementFormProps } from "types/analysis/statement";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { adaptAnalysisFile } from "types/analysis";
import { attachementIconContainerStyles } from "components/MessageInput/styles";
import { baseStyles } from "utils/constants/baseStyles";
import { styles } from "./styles";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";

interface IProps {
    statement: StatementFormProps
}

export const StatementLine: FC<IProps> = (props: IProps) => {
    const { statement } = props;
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const [statementState, setStatementState] = useState<StatementFormProps>();
    const [institutions, setInstitutions] = useState<Bank[] | MobileMoneyCompany[]>([]);

    const removeStatementLine = () => {
        dispatch(removeStatement(statement.id))
    };
    
    const updateStatementState = (field: string, value: string|number|boolean|undefined) => {
        const newState = {
            ...statementState,
            [field]: value
        } as StatementFormProps;
        
        dispatch(updateStatement({ id: statement.id, key: field, value: value } as UpdateStatementParams));
        setStatementState(newState);
    };

    const handleChangeInputFile = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = await adaptAnalysisFile(e.target.files[0]);
            console.log('file', file);
            updateStatementState('filename', file.name);

            const base64data = transformBase64data(file.base64data);
            updateStatementState('base64data', base64data);
            updateStatementState('passwordRequired', file.passwordRequired ? true : false);
        }
    };
    
    const transformBase64data = (base64data: string) => {
        base64data = transformVndExcelToCsv(base64data);

        return base64data;
    };

    const transformVndExcelToCsv = (base64data: string) => {
        const [metadata, data] = base64data.split(";");
        const [_, extension] = metadata.split(":");
    
        if (extension === "application/vnd.ms-excel") {
          return `data:text/csv;${data}`;
        }
    
        return base64data;
    };
    
    useEffect(() => {
        if (!statement) {
            return;
        }
        
        setStatementState({
            id: statement.id,
            fileTypeCode: statement.fileTypeCode,
            analysisTypeCode: statement.analysisTypeCode,
            institutionId: statement.institutionId,
            accessCode: statement.accessCode,
            filename: statement.filename,
            base64data: statement.base64data
        });
    }, [statement]);
    
    useEffect(() => {
        if (statementState?.analysisTypeCode === "BANK") {
          getStatementBanks()
          .then((data) => {
            setInstitutions(data);
          }).catch(error => {
            console.error(error);
          });
        } else if (statementState?.analysisTypeCode === "MOBILE_MONEY") {
          getMobileMoneyCompanies()
          .then((data) => {
            setInstitutions(data);
          }).catch(error => {
            console.error(error);
          });
        }
    }, [statementState?.analysisTypeCode]);

    useEffect(() => {
        if (statement.analysisTypeCode === "BANK") {
            updateStatementState("bankId", statementState?.institutionId);
        } else if (statement.analysisTypeCode === "MOBILE_MONEY") {
            updateStatementState("mobileMoneyCompanyId", statementState?.institutionId);
        }
    }, [statementState?.institutionId]);

    return (
        <Grid sx={styles.statementLineContainer}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typo14Medium>{t('filetype')}: </Typo14Medium>
                    <CustomSelect
                        options={[
                            {
                            element: "PDF",
                            value: "PDF"
                            },
                            {
                            element: "CSV",
                            value: "CSV"
                            }
                        ]}
                        
                        onChange={(e: any) => {
                            updateStatementState('fileTypeCode', e.target.value);
                        }}
                        value={statementState?.fileTypeCode}
                    ></CustomSelect>
                </Grid>
                <Grid item xs={4}>
                    <Typo14Medium>{t('natureOfStatement')}: </Typo14Medium>
                    <CustomSelect
                        options={[
                            {
                            element: t('bankStatement'),
                            value: "BANK"
                            },
                            {
                            element: t('mobileMoneyStatement'),
                            value: "MOBILE_MONEY"
                            }
                        ]}
                        
                        onChange={(e: any) => {
                            updateStatementState('analysisTypeCode', e.target.value)
                        }}
                        value={statementState?.analysisTypeCode}
                    ></CustomSelect>
                </Grid>
                <Grid item xs={4}>
                    <Typo14Medium>Institution: </Typo14Medium>
                    <CustomSelect
                        options={institutions.map((c) => ({ value: c.id, element: c.name }))}
                        onChange={(e: any) => {
                            updateStatementState('institutionId', e.target.value)
                        }}
                        value={statementState?.institutionId}
                    ></CustomSelect>
                </Grid>
                <Grid item xs={4}>
                    <Typo14Medium>{t('accessCode')}
                        {
                            statementState?.passwordRequired ? 
                            (<Box component="span">*</Box>) : (<></>)
                        }
                            : </Typo14Medium>
                    <CustomInput
                        sx={{ marginBottom: 0 }}
                        fullWidth
                        isValid={true}
                        onchange={(e: any) => {
                        updateStatementState('accessCode', e.target.value);
                        }}
                        type="text"
                        value={statementState?.accessCode}    
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typo14Medium>{t('employerName')}: </Typo14Medium>
                    <CustomInput
                        sx={{ marginBottom: 0 }}
                        fullWidth
                        isValid={true}
                        onchange={(e: any) => {
                        updateStatementState('employerName', e.target.value);
                        }}
                        type="text"
                        value={statementState?.employerName}    
                    />
                </Grid>

                <Grid item xs={4} sx={{display: 'flex'}}>
                    <IconButton sx={{ ...attachementIconContainerStyles, ...styles.iconButton }}>
                        <HiddenInputFile multiple={true} onChange={handleChangeInputFile} />
                        <AttachementIcon color={baseStyles.color.green.primary} />
                    </IconButton>
                    {
                        statementState?.filename ?
                            (
                                <Tooltip title={statementState?.filename} placement="top">
                                    <IconButton sx={styles.iconButton}>
                                        <FileIcon></FileIcon>
                                    </IconButton>
                                </Tooltip>
                            )
                            : <></>
                    }
                </Grid>
                
            </Grid>
            <Grid item xs={1} sx={styles.buttonRemoveContainer}>
                <Box sx={styles.removeStatementLineButton} onClick={removeStatementLine}>
                    <IconButton>
                        <CancelRounded />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}