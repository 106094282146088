import { Box, CircularProgress, Input } from "@mui/material";
import { ChangeEvent, FC, KeyboardEvent, useCallback, useState } from "react";

import { CloseIcon12 } from "assets/icons/CloseIcon";
import { CustomButton } from "components/Button/CustomButton";
import { MessageMailIcon } from "components/Icons/SvgIcons";
import { Typo14 } from "components/Typography/Typo14";
import { baseStyles } from "utils/constants/baseStyles";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

interface ChipSelectProps {
  elements: string[];
  loading?: boolean;
  updateElements: (elements: string[]) => void;
  onSend: () => void;
}

export const ChipSelect: FC<ChipSelectProps> = ({ elements, loading, updateElements, onSend }) => {
  const { t } = useTranslation();
  
  const [currentInputValue, setCurrentInputValue] = useState<string>("");

  const handleAddElement = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === "Enter") {
        if (isValidMail(currentInputValue)) {
          updateElements([...elements, currentInputValue]);
          setCurrentInputValue("");
        }
      } else {
      }
    },
    [currentInputValue, elements, updateElements]
  );

  const isValidMail = (mail: string) => {
    return mail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentInputValue(event.target.value);
  };

  const removeElement = useCallback(
    (index: number) => {
      updateElements(elements.filter((e, i) => i !== index));
    },
    [elements, updateElements]
  );

  return (
    <Box sx={styles.tagsInputContainer}>
      <Box sx={styles.messageMailIconContainer}>
        <MessageMailIcon />
      </Box>
      <Box sx={styles.itemsContainer}>
        {elements.map((e, index) => (
          <Box sx={styles.tagItem} key={index}>
            <Typo14 weight="medium" color={baseStyles.color.white}>
              {e}
            </Typo14>
            <Box
              component="span"
              sx={styles.closeButton}
              onClick={loading ? undefined : () => removeElement(index)}
            >
              <CloseIcon12 />
            </Box>
          </Box>
        ))}
        <Input
          sx={styles.input}
          placeholder="..."
          disableUnderline={true}
          onKeyUp={handleAddElement}
          value={currentInputValue}
          onChange={handleInputChange}
        />
      </Box>
      <CustomButton
        sx={{
          marginLeft: "auto",
          padding: "0px 20px",
          height: "40px",
          borderRadius: "10px",
          minWidth: "fit-content"
        }}
        onClick={loading ? undefined : onSend}
        disabled={elements.length === 0 && !loading}
      >
        <Typo14 weight="medium" color={baseStyles.color.white}>
          {t('sendInvitation')}
        </Typo14>
        {loading && <CircularProgress size={20} color="inherit" sx={{ marginLeft: "5px" }} />}
      </CustomButton>
    </Box>
  );
};
