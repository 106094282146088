import { Box, CircularProgress, SelectChangeEvent, Stack } from "@mui/material";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { baseStyles, flexColumn, typoCommonStyles } from "utils/constants/baseStyles";
import { btnStyles, inputStyles } from "./styles";

import { CustomButton } from "components/Button/CustomButton";
import { CustomInput } from "components/InputField";
import CustomSelect from "components/InputField/CustomSelect";
import { Typo14 } from "components/Typography/Typo14";
import { useGetBankCountriesQuery } from "store/api";
import { useTranslation } from "react-i18next";

export interface BankFormState {
  name: string;
  address: string;
  phone: string;
  city: string;
  countryId: string;
}

export interface BankFormProps {
  defaultValues?: BankFormState;
  loading?: boolean;
  isEdit?: boolean;
  onSubmit: (data: BankFormState) => void;
}

export const bankFormDefaultValues: BankFormState = {
  name: "",
  address: "",
  phone: "",
  city: "",
  countryId: ""
};

export const BankForm: FC<BankFormProps> = ({
  defaultValues = bankFormDefaultValues,
  loading,
  isEdit,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [countryOptions, setCountryOptions] = useState<any>([]);
  const [formState, setFormState] = useState<BankFormState>(defaultValues);
  const { name, address, phone, city, countryId } = formState;

  const inputs = [
    { label: t('name'), value: name, name: "name" },
    { label: t('address'), value: address, name: "address" },
    { label: t('phone'), value: phone, name: "phone" },
    { label: t('city'), value: city, name: "city" },
    { label: t('country'), value: countryId, name: "countryId" }
  ];

  const { data: bankCountries } = useGetBankCountriesQuery({});

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    setFormState((prevState) => ({ ...prevState, countryId: e.target.value }));
  };

  const isValidDataAddBank = useCallback(() => {
    return Object.values(formState).every((value) => !!value);
  }, [formState]);

  React.useEffect(() => {
    if (bankCountries) {
      const selectOptions = bankCountries.map((d: Country) => ({
        value: isEdit ? d.code : d.id,
        element: d.name
      }));
      setCountryOptions(selectOptions);
    }
  }, [bankCountries, isEdit]);

  return (
    <Box sx={{ ...flexColumn, gap: "30px" }}>
      {inputs.map(({ label, name, value }) => (
        <Stack gap="15px" key={name}>
          <Typo14 color={baseStyles.color.blue.primary60} sx={typoCommonStyles}>
            {`${label} : `}
          </Typo14>
          {name === "countryId" ? (
            <CustomSelect
              label={t('selectCountry')}
              onChange={handleCountryChange}
              options={countryOptions}
              value={countryId}
              sx={inputStyles}
            />
          ) : (
            <CustomInput
              sx={inputStyles}
              fullWidth
              required
              type="text"
              name={name}
              placeholder={`${label} ...`}
              value={value}
              onchange={handleChangeInput}
            />
          )}
        </Stack>
      ))}
      <CustomButton
        onClick={loading ? undefined : () => onSubmit(formState)}
        disabled={!isValidDataAddBank()}
        sx={btnStyles}
      >
        {isEdit ? t('saveChanges') : t('createBank')}
        {loading && <CircularProgress color="inherit" size={18} sx={{ marginLeft: "5px" }} />}
      </CustomButton>
    </Box>
  );
};
