import { SxProps } from "@mui/material";
import { fontMontserrat, typoCommonStyles } from "utils/constants/baseStyles";

export const inputStyles: SxProps = {
  marginBottom: 0,
  height: "50px",
  padding: "0px 15px"
};

export const btnStyles: SxProps = {
  ...typoCommonStyles,
  ...(fontMontserrat as any),
  fontWeight: "500",
  fontSize: "16px",
  height: "60px",
  padding: "0px 30px"
};
