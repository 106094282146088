import { SxProps } from "@mui/material";
import { breakpoints, flexRowCenter } from "utils/constants/baseStyles";

const _baseStyles: SxProps = {
  ...flexRowCenter,
  gap: "20px"
};

export const styles: SxProps = {
  ..._baseStyles,
  flexWrap: "nowrap",
  width: "100%"
};

export const textStyles: SxProps = {
  minWidth: "150px",
  [breakpoints("lg").min]: {
    minWidth: "fit-content"
  }
};

export const inputsContainer: SxProps = {
  ..._baseStyles,
  width: "100%",
  maxWidth: `${503 * 2 + 20}px`
};
