import { useCallback, useContext, useState } from "react";

import { BankContext } from "providers/BankProvider";
import { Box } from "@mui/material";
import { ChipSelect } from "./ChipSelect";
import { CustomAlertError } from "components/Alert/CustomAlertError";
import { GLOBAL_ERROR_MSG } from "constants/error";
import { getErrorMessage } from "utils/errorMessage";
import { useInviteBankUsersMutation } from "store/api";

export const ChipSelectConnected = () => {
  const { selectedBank, reloadBank } = useContext(BankContext);
  const bank = selectedBank.get();
  const bankId = bank?.id;

  const [emails, setEmails] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState<string>(GLOBAL_ERROR_MSG);
  
  const [inviteBankUsers, { isLoading, isError }] = useInviteBankUsersMutation();

  const handleSendInviteMail = useCallback(() => {
    if (bankId) {
      inviteBankUsers({ bankId, emails })
        .unwrap()
        .then(() => {
          setEmails([]);
          reloadBank();
        })
        .catch((error) => {
          console.log("error", error);
          const message = getErrorMessage(error.data.code);
          console.log('message is ', message);
          setErrorMessage(message);
        });
    }
  }, [bankId, emails, inviteBankUsers, reloadBank]);

  return (
    <>
      <ChipSelect
        onSend={handleSendInviteMail}
        loading={isLoading}
        elements={emails}
        updateElements={(emails) => setEmails(emails)}
      />
      <Box sx={{ minHeight: "fit-content" }}>
        <CustomAlertError
          sx={{ minHeight: "fit-content" }}
          title={GLOBAL_ERROR_MSG}
          body={errorMessage}
          opened={isError}
          closable={false}
        />
      </Box>
    </>
  );
};
