import { ShieldIcon } from "components/Icons/SvgIcons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CustomAlert, CustomAlertProps } from "./CustomAlert";

export interface CustomAlertErrorProps extends CustomAlertProps {
  titleWithPrefix?: boolean;
}

export const CustomAlertError: FC<CustomAlertErrorProps> = ({
  title,
  titleWithPrefix = true,
  closable = true,
  severity = "error",
  ...restProps
}) => {
  const { t } = useTranslation();
  const defaultTitle = titleWithPrefix ? `${t("alert.alert")} : ${title}` : title;

  return (
    <CustomAlert
      {...restProps}
      title={defaultTitle}
      severity={severity}
      closable={closable}
      icon={<ShieldIcon />}
    />
  );
};
