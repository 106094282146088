import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "./customFetchBaseQuery";

export const bankApi = createApi({
  reducerPath: "bankApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Banks", "Countries"],
  endpoints: (builder) => ({
    getBankCountries: builder.query<Country[], any>({
      query: () => {
        let url = `/api/banks/countries`;
        return { url };
      },
      providesTags: (result, error, arg) => (error ? [] : ["Countries"])
    })
  })
});

export const { useGetBankCountriesQuery, useLazyGetBankCountriesQuery } = bankApi;
