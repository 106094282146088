import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const LogoutIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1251 11.9999C16.1251 11.5857 15.7893 11.2499 15.3751 11.2499L4.40256 11.2499L6.36321 9.56932C6.67771 9.29975 6.71413 8.82628 6.44456 8.51178C6.175 8.19729 5.70152 8.16087 5.38703 8.43044L1.88703 11.4304C1.72079 11.5729 1.62512 11.7809 1.62512 11.9999C1.62512 12.2188 1.72079 12.4268 1.88703 12.5693L5.38703 15.5693C5.70152 15.8389 6.175 15.8025 6.44456 15.488C6.71413 15.1735 6.67771 14.7 6.36322 14.4304L4.40256 12.7499L15.3751 12.7499C15.7893 12.7499 16.1251 12.4141 16.1251 11.9999Z"
        fill={fill}
      />
      <path
        d="M9.37512 7.99988C9.37512 8.70207 9.37512 9.05317 9.54363 9.30538C9.6166 9.41459 9.71037 9.50836 9.81958 9.58132C10.0718 9.74984 10.4229 9.74984 11.1251 9.74984L15.3751 9.74984C16.6178 9.74984 17.6251 10.7572 17.6251 11.9998C17.6251 13.2425 16.6178 14.2498 15.3751 14.2498L11.1252 14.2498C10.4229 14.2498 10.0718 14.2498 9.81952 14.4184C9.71036 14.4913 9.61662 14.5851 9.54367 14.6942C9.37512 14.9465 9.37512 15.2976 9.37512 15.9999C9.37512 18.8283 9.37512 20.2425 10.2538 21.1212C11.1325 21.9999 12.5465 21.9999 15.3749 21.9999L16.3749 21.9999C19.2033 21.9999 20.6175 21.9999 21.4962 21.1212C22.3749 20.2425 22.3749 18.8283 22.3749 15.9999L22.3749 7.99988C22.3749 5.17146 22.3749 3.75723 21.4962 2.87856C20.6175 1.99988 19.2033 1.99988 16.3749 1.99988L15.3749 1.99988C12.5465 1.99988 11.1325 1.99988 10.2538 2.87856C9.37512 3.75724 9.37512 5.17145 9.37512 7.99988Z"
        fill={fill}
      />
    </svg>
  );
};
