import { Box, Stack } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { baseStyles, typoCommonStyles } from "utils/constants/baseStyles";

import { AnalysisStatResult } from "types/analysis/analysis";
import CircularProgressWithLabel from "components/Progress/CircularProgressWithLabel/CircularProgressWithLabel";
import { ClienteleContext } from "providers/ClienteleProvider";
import { Typo16 } from "components/Typography/Typo16";
import { getAnalysesStats } from "services/Analysis/AnalysisService";
import { getPercentageValueOfStats } from "utils/analysisStats";
import { selectStatsState } from "store/slices";
import { styles } from "components/Bank/BankStats/BankGlobalStats/styles";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

interface IProps {}

export const ClienteleGlobalStats: FC<IProps> = (props: IProps) => {
  const { clienteles } = useContext(ClienteleContext);
  const { t } = useTranslation();
  
  const getColor = (type: string) => {
    switch (type) {
      case "DONE":
        return baseStyles.color.success.main;

      case "FAILED":
        return baseStyles.color.warning.main;

      case "CANCELED":
        return baseStyles.color.error.main;

      default:
        return baseStyles.color.blue.primary;
    }
  };

  const { selectedClientele } = useContext(ClienteleContext);
  const [clientele, setClientele] = useState<User>();
  const [stats, setStats] = useState<AnalysisStatResult>();

  const { filters } = useAppSelector(selectStatsState);

  useEffect(() => {
    setClientele(selectedClientele.get());
  }, [selectedClientele]);

  useEffect(() => {
    const params = {
      ...filters,
      responsibleId: clientele?.id
    };

    getAnalysesStats(params)
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [clientele, filters]);

  return clientele && clienteles.get().length > 0 ? (
    <Box sx={styles.bankGlobalStatsContainer}>
      <Box sx={{ ...styles.container, ...(styles.total as any) }}>
        <Stack gap={"15px"}>
          <Box
            sx={{
              color: "white",
              fontSize: 40,
              fontWeight: 700
            }}
          >
            {stats?.totalCount}
          </Box>
          <Typo16 color="white" weight="medium">
            {t('totalAnalysisCount')}
          </Typo16>
        </Stack>
      </Box>
      <Box sx={styles.statsContainer}>
        <CircularProgressWithLabel
          sx={{ color: getColor("DONE") }}
          thickness={5}
          {...props}
          value={getPercentageValueOfStats(stats?.doneCount, stats?.totalCount)}
          size={65}
        />
        <Stack gap="15px">
          <Box sx={{ ...styles.statsNumber }}>{stats?.doneCount}</Box>
          <Typo16 weight="medium" color={baseStyles.color.blue.primary40} sx={typoCommonStyles}>
            {t('carriedOutAnalyses')}
          </Typo16>
        </Stack>
      </Box>
      <Box sx={styles.statsContainer}>
        <CircularProgressWithLabel
          sx={{ color: getColor("FAILED") }}
          thickness={5}
          {...props}
          value={getPercentageValueOfStats(stats?.failedCount, stats?.totalCount)}
          size={65}
        />
        <Stack gap="15px">
          <Box sx={{ ...styles.statsNumber }}>{stats?.failedCount}</Box>
          <Typo16 weight="medium" color={baseStyles.color.blue.primary40} sx={typoCommonStyles}>
            {t('failedAnalyses')}
          </Typo16>
        </Stack>
      </Box>
      <Box sx={styles.statsContainer}>
        <CircularProgressWithLabel
          sx={{ color: getColor("CANCELED") }}
          thickness={5}
          {...props}
          value={getPercentageValueOfStats(stats?.canceledCount, stats?.totalCount)}
          size={65}
        />
        <Stack gap="15px">
          <Box sx={{ ...styles.statsNumber }}>{stats?.canceledCount}</Box>
          <Typo16 weight="medium" color={baseStyles.color.blue.primary40} sx={typoCommonStyles}>
            {t('canceledAnalyses')}
          </Typo16>
        </Stack>
      </Box>
    </Box>
  ) : (
    <Typo16 weight="medium">{t('noAccountManagerSelected')}</Typo16>
  );
};
