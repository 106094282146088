import styled from "@emotion/styled";
import { SxProps } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

export const styles: SxProps = {};

export const ArrowContainer = styled(`span`)`
  display: flex;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 16px;
`;

export const popoverStyles: SxProps = {
  pointerEvents: "none",
  "& .MuiPaper-root": {
    width: "fit-content",
    maxWidth: "259px",
    padding: "20px",
    marginTop: "12px",
    color: baseStyles.color.blue.primary60,
    fontSize: "12px",
    lineHeight: "140%",
    borderRadius: "10px",
    position: "relative",
    overflow: "visible",
    boxShadow: `0px 0px 20px 0px rgba(0, 0, 0, 0.08)`
  }
};
