import { Typography } from "@mui/material";
import { FC } from "react";
import { baseStyles, fontMontserrat } from "utils/constants/baseStyles";
import { TypoProps, getColor, getWeight } from "./utils";

export const Typo14Medium: FC<TypoProps> = ({
  children,
  color = baseStyles.color.blue.primary,
  sx = {},
  weight = "medium"
}) => {
  return (
    <Typography
      sx={{
        ...(fontMontserrat as any),
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: getWeight(weight),
        lineHeight: "140%",
        color: getColor(color),
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};
