import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const AltArrowRightIcon14: FC<Pick<SvgIconProps, "stroke" | "strokeOpacity">> = ({
  stroke = baseStyles.color.blue.primary,
  strokeOpacity = 1
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M5.25 11.0833L8.75 7L5.25 2.91667"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AltArrowRightIcon24: FC<Pick<SvgIconProps, "stroke" | "strokeWidth">> = ({
  stroke = baseStyles.color.blue.primary,
  strokeWidth = 1.5
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9 19L15 12L9 5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
