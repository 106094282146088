import { Box } from "@mui/material";
import logo from "assets/images/sidebar-logo.png";

export const LogoTextSmallSize = () => {
  return (
    <Box sx={{ width: "190px", height: "64px" }}>
      <img src={logo} alt="logo" width="100%" height="100%" />
    </Box>
  );
};
