import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const InfoCircleIcon18: FC<SvgIconProps> = ({
  fill = baseStyles.color.blue.primary,
  stroke = baseStyles.color.blue.primary,
  strokeOpacity = 0.6,
  strokeWidth = 1.5,
  fillOpacity = 0.6
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_1889_828)">
        <circle
          cx="9"
          cy="9"
          r="7.5"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth={strokeWidth}
        />
        <path
          d="M9 12.75V8.25"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <circle
          cx="0.75"
          cy="0.75"
          r="0.75"
          transform="matrix(1 0 0 -1 8.25 6.75)"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <defs>
        <clipPath id="clip0_1889_828">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
