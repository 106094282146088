import { FC } from "react";
import { SvgIconProps } from "types";
import { baseStyles } from "utils/constants/baseStyles";

export const HomeAngleIcon: FC<SvgIconProps> = ({ fill = baseStyles.color.white }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33537 8.37495C1.79491 9.50229 1.98463 10.8208 2.36407 13.4579L2.64284 15.3952C3.13025 18.7827 3.37396 20.4764 4.54903 21.4882C5.72409 22.5 7.44737 22.5 10.8939 22.5H13.1061C16.5526 22.5 18.2759 22.5 19.451 21.4882C20.626 20.4764 20.8697 18.7827 21.3572 15.3952L21.6359 13.4579C22.0154 10.8208 22.2051 9.50229 21.6646 8.37495C21.1242 7.2476 19.9738 6.56234 17.6731 5.19181L16.2882 4.36687C14.199 3.12229 13.1543 2.5 12 2.5C10.8457 2.5 9.80104 3.12229 7.71175 4.36687L6.32691 5.19181C4.02619 6.56234 2.87583 7.2476 2.33537 8.37495ZM8.2501 18.4998C8.2501 18.0856 8.58589 17.7498 9.0001 17.7498H15.0001C15.4143 17.7498 15.7501 18.0856 15.7501 18.4998C15.7501 18.914 15.4143 19.2498 15.0001 19.2498H9.0001C8.58589 19.2498 8.2501 18.914 8.2501 18.4998Z"
        fill={fill}
      />
    </svg>
  );
};
