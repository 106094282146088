import { FC } from "react";

export const SettingsFineTuningWidgetIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M2 7C2 4.51472 4.01472 2.5 6.5 2.5C8.98528 2.5 11 4.51472 11 7V10C11 10.3487 11 10.5231 10.9617 10.6662C10.8576 11.0544 10.5544 11.3576 10.1662 11.4617C10.0231 11.5 9.84874 11.5 9.5 11.5H6.5C4.01472 11.5 2 9.48528 2 7Z"
        fill="#0CD97B"
      />
      <path
        d="M13 15C13 14.6513 13 14.4769 13.0383 14.3338C13.1424 13.9456 13.4456 13.6424 13.8338 13.5383C13.9769 13.5 14.1513 13.5 14.5 13.5H17.5C19.9853 13.5 22 15.5147 22 18C22 20.4853 19.9853 22.5 17.5 22.5C15.0147 22.5 13 20.4853 13 18V15Z"
        fill="#0CD97B"
      />
      <path
        d="M2 18C2 15.5147 4.01472 13.5 6.5 13.5H9.2C9.83006 13.5 10.1451 13.5 10.3857 13.6226C10.5974 13.7305 10.7695 13.9026 10.8774 14.1143C11 14.3549 11 14.6699 11 15.3V18C11 20.4853 8.98528 22.5 6.5 22.5C4.01472 22.5 2 20.4853 2 18Z"
        fill="white"
      />
      <path
        d="M13 7C13 4.51472 15.0147 2.5 17.5 2.5C19.9853 2.5 22 4.51472 22 7C22 9.48528 19.9853 11.5 17.5 11.5H14.2857C14.1365 11.5 14.0618 11.5 13.999 11.4929C13.4775 11.4342 13.0658 11.0225 13.0071 10.501C13 10.4382 13 10.3635 13 10.2143V7Z"
        fill="white"
      />
    </svg>
  );
};
