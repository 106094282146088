import { Box, Grid } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import SupportTicketProvider, { SupportTicket } from "providers/SupportMessageProvider";

import MessagerieContent from "./MessagerieContent/MessagerieContent";
import MessagerieSidebar from "./MessagerieSidebar/MessagerieSidebar";
import Restricted from "components/Permission/Restricted";
import { getAllSupportTickets } from "services/SupportTickets/SupportTicketsService";

type IProps = {

}
const Messagerie: FC<IProps> = () => {
    // Get all support tickets
    const { setAllSupportTickets,  currentSupportTicket } = useContext(SupportTicket);
    let supportTicket: any;

    useEffect(() => {
        // console.log("Fetching all support tickets");
        getAllSupportTickets().then((data) => {
            console.log({ data });
            setAllSupportTickets(data);
            if (data.length > 0) {
                currentSupportTicket.set(data[0]);

                if (currentSupportTicket.get) {
                    supportTicket['id'] = currentSupportTicket.get.id;
                    supportTicket['subject'] = currentSupportTicket.get.subject;
                }
            }
            
            }).catch((error) => {
                console.error(error);
            })
        }, []);
    return (
        <>  
            <Restricted to="view_messagerie">
                <Grid container sx={{ height: '100%', flexWrap: 'nowrap'}}>
                    <Box sx={{ width: '290px', flexShrink: 0, height: '100%', minHeight: '100px' }}>
                        {/* Messenger sidebar */}
                        <MessagerieSidebar  />
                    </Box>
                    <Box sx={{ flexGrow: '1', overflow: 'auto' }}>
                        {/* Messenger content */}
                        <MessagerieContent
                            supportTicketId={supportTicket ? supportTicket['id']: ''}
                            supportTicketSubject={supportTicket ? supportTicket['subject'] : ''}
                            supportTicketDate="28 juillet 2023, 13:51"
                            />
                    </Box>
                </Grid>
            </Restricted>
        </>
    )
}

export default Messagerie;