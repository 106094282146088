import { AnalysisResultCard, AnalysisResultCardData } from "components/AnalysisResultCard";
import { FC, useEffect, useState } from "react";
import {
  cashflowCardStyles,
  cashflowTabContentStyles,
  contentContainerSx,
  labelStyles,
  valueStyles
} from "./styles";

import { AnalysisResultsTabContentProps } from "./types";
import { BanknoteIcon } from "assets/icons/BanknoteIcon";
import { BillListIcon } from "assets/icons/BillListIcon";
import { Box } from "@mui/material";
import { CalendarIcon } from "assets/icons/CalendarIcon";
import { CardReciveIcon } from "assets/icons/CardReciveIcon";
import { getAnalysisResultFormattedData } from "utils";
import { selectAnalysisState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export interface IncomeProps extends AnalysisResultsTabContentProps {}

export const Income: FC<IncomeProps> = ({ data }) => {
  const { t } = useTranslation();
  
  const { currentAnalysis } = useAppSelector(selectAnalysisState);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  
  useEffect(() => {
    if (currentAnalysis?.currency?.code) {
      setCurrentCurrency(currentAnalysis.currency.code);
    }
  }, [currentAnalysis]);
  
  const formattedData: AnalysisResultCardData[] = [
    {
      label: t('salaryEarner'),
      value: getAnalysisResultFormattedData("boolean", data?.salaryEarner),
      description: t('salaryEarnerDescription'),
      Icon: () => <BillListIcon />
    },
    {
      label: t('averageSalary'),
      value: getAnalysisResultFormattedData("money", data?.averageSalary, undefined, currentCurrency),
      description: t('averageSalaryDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('confidenceIntervalOnSalaryDetection'),
      value: getAnalysisResultFormattedData(
        "percentage",
        data?.confidenceIntervalOnSalaryDetection
      ),
      description: t('confidenceIntervalOnSalaryDetectionDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('salaryFrequency'),
      value: getAnalysisResultFormattedData("selfNumber", data?.salaryFrequency),
      description: t('salaryFrequencyDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('expectedSalaryDay'),
      value: getAnalysisResultFormattedData("selfNumber", data?.expectedSalaryDay),
      description: t('expectedSalaryDayDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('lastSalaryDate'),
      value: getAnalysisResultFormattedData("date", data?.lastSalaryDate),
      description: t('lastSalaryDateDescription'),
      Icon: () => <CalendarIcon />
    },
    {
      label: t('numberOfSalaryPayments'),
      value: getAnalysisResultFormattedData("self", data?.numberOfSalaryPayments),
      description: t('numberOfSalaryPaymentsDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('medianIncome'),
      value: getAnalysisResultFormattedData("money", data?.medianIncome, undefined, currentCurrency),
      description: t('medianIncomeDescription'),
      Icon: () => <CardReciveIcon />
    },
    {
      label: t('otherIncome'),
      value: getAnalysisResultFormattedData("selfNumber", data?.numberOtherIncomePayments),
      description: t('otherIncomeDescription'),
      Icon: () => <BanknoteIcon />
    },
    {
      label: t('averageOtherIncome'),
      value: getAnalysisResultFormattedData("money", data?.averageOtherIncome, true, currentCurrency),
      description: t('averageOtherIncomeDescription'),
      Icon: () => <BanknoteIcon />
    }
  ];

  return (
    <Box sx={{ ...cashflowTabContentStyles }}>
      {formattedData.map(({ label, value, description, Icon }, index) => (
        <AnalysisResultCard
          sx={cashflowCardStyles}
          contentContainerSx={contentContainerSx}
          labelSx={labelStyles}
          valueSx={valueStyles}
          key={`${index}-${label}`}
          label={label}
          value={value}
          description={description}
          Icon={Icon}
        />
      ))}
    </Box>
  );
};
