import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import {
  formControlContainerStyles,
  formControlStyles,
  inputSelectMenuItemStyles,
  inputSelectStyles
} from "./styles";
import {
  getAllSupportTickets,
  setSeenMessageSupportTicket
} from "services/SupportTickets/SupportTicketsService";
import { getBanks, getClientBanks } from "services/Bank/BankService";

import { BankContext } from "providers/BankProvider";
import { ListMessage } from "./ListMessage/ListMessage";
import { SupportTicket } from "providers/SupportMessageProvider";
import { WebsocketContext } from "providers/WebsocketProvider";
import { useTranslation } from "react-i18next";

type IProps = {};

const MessagerieSidebar: FC<IProps> = () => {
  const { t } = useTranslation();
  
  const [bankId, setBankId] = useState<number>();
  const {
    allSupportTickets,
    loadingAllSupportTickets,
    currentSupportTicket,
    setAllSupportTickets,
    reloadUnseenMessagesCount
  } = useContext(SupportTicket);
  const { banks } = useContext(BankContext);
  const { subscribe } = useContext(WebsocketContext);

  const handleChange = (event: SelectChangeEvent) => {
    setBankId(parseInt(event.target.value));
  };

  const loadSupportTickets = () => {
    getAllSupportTickets({ bankId: bankId })
      .then((data) => {
        let lastSupportTicketId = Number(currentSupportTicket.get?.id);
        console.log("lastSupportTicketId", lastSupportTicketId);
        setAllSupportTickets(data);

        if (!currentSupportTicket.get) {
          if (data.length > 0) {
            currentSupportTicket.set(data[0]);
          }
        }

        updateListSupportScroll();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateListSupportScroll = () => {
    setTimeout(() => {
      console.log("should scroll messagerie support");
      const messagerieListContainer = document.getElementById("messagerie-list-container");

      if (messagerieListContainer) {
        messagerieListContainer.scrollTop = messagerieListContainer.scrollHeight;
      }
    }, 0);
  };

  useEffect(() => {
    loadSupportTickets();
  }, [bankId]);

  useEffect(() => {
    console.log("current support ticket from use effect", currentSupportTicket.get);
    if (currentSupportTicket.get) {
      setSeenMessageSupportTicket(currentSupportTicket.get.id)
        .then((response) => {
          reloadUnseenMessagesCount();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentSupportTicket.get]);

  useEffect(() => {
    getClientBanks()
      .then((data) => {
        banks.set(data);
        console.log("banks", banks);
      })
      .catch((error) => {
        console.error(error);
      });

    subscribe("new_support_message", "MessagerieSidebar", (data: any) => {
      console.log("messagerie sidebar subscription");
      // loadSupportTickets();
    });
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <Grid container direction="column" sx={{ height: "100%", flexWrap: "nowrap" }}>
        <Grid item>
          {/* Select bar */}
          <Box sx={formControlContainerStyles}>
            <FormControl fullWidth sx={formControlStyles}>
              <Select
                sx={inputSelectStyles}
                id="bank-select-id"
                value={bankId?.toString()}
                onChange={handleChange}
                defaultValue={"-1"}
              >
                <MenuItem sx={inputSelectMenuItemStyles} value={"-1"}>
                  {t('allBanks')}
                </MenuItem>
                {banks.get()?.map((bank) => (
                  <MenuItem sx={inputSelectMenuItemStyles} key={bank.id} value={bank.id}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          id="messagerie-list-container"
          item
          sx={{
            flexGrow: "1",
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": { width: 0, height: 0 }
          }}
        >
          {/* User list */}
          <ListMessage
            loading={loadingAllSupportTickets.get}
            messageList={allSupportTickets}
            currentSupportTicketId={currentSupportTicket.get?.id}
            handleClick={(selectedSupportId: any) => {
              currentSupportTicket.setById(selectedSupportId);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessagerieSidebar;
