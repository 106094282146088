import { SxProps } from "@mui/material";
import { breakpointsBetween } from "utils/constants/baseStyles";

export const tabContentStyles: SxProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "30px"
};

export const cashflowTabContentStyles: SxProps = {
  ...tabContentStyles,
  [breakpointsBetween("laptop", "figma")]: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(211px, 1fr))",
    gap: "20px"
  }
};

export const cashflowCardStyles: SxProps = {
  [breakpointsBetween("laptop", "figma")]: {
    maxWidth: "unset",
    padding: "20px"
  }
};

export const contentContainerSx: SxProps = {
  alignItems: "flex-start",
  justifyContent: "end",
  gap: "5px"
};

export const labelStyles: SxProps = {
  fontSize: "14px"
};

export const valueStyles: SxProps = {
  letterSpacing: "0.5px",
  lineHeight: 1,
  fontSize: "14px"
};
