import { SxProps } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

export const listSupportContainer: SxProps = {
  borderBottom: "2px solid rgba(1, 29, 123, 0.10)",
  minHeight: "140px",
  padding: 2,
  color: baseStyles.color.blue.primary,
  backgroundColor: "#FFF",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#EBEBEB"
  }
};

export const listSupportContainerActive: SxProps = {
  backgroundColor: baseStyles.color.green.primary,
  "& *": { color: "#FFF" },
  "&:hover": {
    backgroundColor: baseStyles.color.green.primary
  }
};
