import * as pdfjsLib from "pdfjs-dist";

import { FirstCentralConsumerAccount } from "./analysis";

export const adaptAnalysisFile = async (data: File) => {
  // transform file into base64
  
  return new Promise<AnalysisFile>((resolve, reject) => {
    
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = async () => {
      let passwordRequired = false;
      
      if (reader.result) {
        const workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
        pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
        const loadingTask = await pdfjsLib.getDocument(reader.result);
        
        
        loadingTask.onPassword = (callback: any, reason: number) => {
          if (reason === 1) {
            // password required
            passwordRequired = true;
            // callback('3815');
          }
        };

        // const pdf = await loadingTask.promise;
        // console.log('pdf', pdf);
      }

      setTimeout(() => {
        resolve({
          name: data["name"],
          base64data: reader.result,
          size: data["size"],
          type: data["type"],
          passwordRequired: passwordRequired
        } as AnalysisFile);
      }, 400);
      
    }
    reader.onerror = () => reject({});
  });
};

export const adaptFirstCentralConsumerAccount = (data: any) => {
  return {
    "MatchingEngineID": data['MatchingEngineID'],
    "EnquiryID": data['EnquiryID'],
    "ConsumerID": data['ConsumerID'],
    "Reference": data['Reference'],
    "IDNo": data['IDNo'],
    "PassportNo": data['PassportNo'],
    "PencomIDNo": data['PencomIDNo'],
    "VoterID": data['VoterID'],
    "DriversLicenseNo": data['DriversLicenseNo'],
    "FirstName": data['FirstName'],
    "Surname": data['Surname'],
    "SecondName": data['SecondName'],
    "OtherNames": data['OtherNames'],
    "Address": data['Address'],
    "BirthDate": data['BirthDate'],
    "GenderInd": data['GenderInd'],
    "AccountNo": data['AccountNo'],
    "TelePhoneNumber": data['TelePhoneNumber'],
    "MatchingRate": data['MatchingRate']
  } as FirstCentralConsumerAccount;
};