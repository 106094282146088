import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { StatementFormProps } from "types/analysis/statement";
import { createSlice } from "@reduxjs/toolkit";

interface InitialStateProps {
    statements: StatementFormProps[]
}

export interface UpdateStatementParams {
    id: number,
    key: string,
    value: string | number
}

const initialState: InitialStateProps = {
    statements: []
}
export const statementsSlice = createSlice({
  name: "statements",
  initialState,
    reducers: {
        setStatements: (state, action: PayloadAction<StatementFormProps[]>) => {
            console.log('set statements to ', action.payload);
            state.statements = action.payload
        },
        addStatement: (state, action: PayloadAction<StatementFormProps>) => {
            state.statements.push(action.payload);
        },
        removeStatement: (state, action: PayloadAction<number>) => {
            state.statements = state.statements.filter(statement => statement.id !== action.payload)
        },
        updateStatement: (state, action: PayloadAction<UpdateStatementParams>) => {
            const index = state.statements.findIndex(statement => statement.id === action.payload.id);
            
            if (index === -1) {
                return;
            }
            
            let statement: any = state.statements[index];
            let key = action.payload.key;

            statement[key] = action.payload.value;
        }
    }
});

export const { setStatements, addStatement, removeStatement, updateStatement } = statementsSlice.actions;

export const selectStatementsState = (state: RootState) => state.statements;

export const statementsReducer = statementsSlice.reducer;
